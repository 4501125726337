import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, CardHeader, Avatar, CardActions, Typography, CircularProgress } from '@material-ui/core';
import { blue, orange, grey } from '@material-ui/core/colors';
import PreviewForm from '../View/PreviewForm';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CallToAction from '@material-ui/icons/CallToAction'
import { GetData, PostData } from '../../../api/service';
import AlertDiv from './AlertDiv';
import Loading from './Loading';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '60vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: blue[400]
    }
})

class PaymentGo extends Component {

    state = {
        msg: '',
        link: '',
        amount: '',
        isLoaded: false
    }

    componentDidMount() {
        // get the payment data now

        GetData(`/${this.props.email}/admissionform/haspaymentdone`)
        .then((resp) => {
            if(!resp){
                this.setState({
                    isLoaded: true
                })
            }else{
                this.props.history.replace('/paymentresponse');
            }
        })

        
    }

    onSubmit = (e) => {
        e.preventDefault();

        let d = {
            email: this.props.email
        }
        PostData(`/falsepayment`, d)
        .then((resp) => {
            // got to final print page
        })
    }





    render() {
        let { classes } = this.props;

        return (
            <div >

                <Card>
                    <CardHeader

                        title={`Final Submit`}
                        subheader={`Click "Go To Final Page" Button to continue`}
                    />

                    <CardContent>

                        {this.state.isLoaded ? (<form onSubmit={this.onSubmit}>

                            <AlertDiv
                                back={orange[100]}
                                border={orange[400]}
                            >
                                <Typography
                                    variant="body2"
                                >
                                    Please note the following points:
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography>
                                           After this step you cannot come back to edit your data.
                                        </Typography>
                                    </li>
                                </ul>
                            </AlertDiv>


                            
                            <Button
                                variant="contained"
                                size="sm"
                                color="primary"
                                type="submit"
                            >
                                <CallToAction /> &nbsp; Go to final Page
                        </Button>
                        </form>) : (
                                <Loading />
                        )}



                    </CardContent>


                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(PaymentGo)