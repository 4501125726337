import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import withAuth from '../auth_components/js/withAuth';
import LoggedNavBar from '../Components/LoggedNavBar';
import FormsLanding from '../Components/HostelAdmission/Forms/FormsLanding';
import ViewForm from '../Components/HostelAdmission/View/ViewForm';
import AdmissionLanding from '../Components/HostelAdmission/Admission/AdmissionLanding';
import HostelAdmissionPayment from '../Components/HostelAdmission/Admission/HostelAdmissionPayment';
import AckLanding from '../Components/HostelAdmission/Admission/AckLanding';



class HomePageUserHostel extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {

    //   if(this.props.user.user.role == 'RESTUDENT'){
    //     this.props.history.replace(`/rehome`)
    //   }

  }

  render() {
    //console.log(this.props);
    return (
      <div>
        <LoggedNavBar
          history={this.props.history}
          type={1}
        />
        <div className="main-body">

          <Switch>
            <Route
              exact
              path="/hostel-home"
              render={(props) => <FormsLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/hostel-admission"
              render={(props) => <FormsLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/hostel-final"
              render={(props) => <ViewForm
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />


            <Route
              exact
              path="/hostel-final-admission"
              render={(props) => <AdmissionLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/hostel-final-payment/:receipt_no"
              render={(props) => <HostelAdmissionPayment
                {...props}
                history={this.props.history}
                user={this.props.user.user}
                receipt_no={props.match.params.receipt_no}
              />}
            />


            <Route
              exact
              path="/hostel-admission-ack"
              render={(props) => <AckLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />


          </Switch>



        </div>
      </div>
    )
  }
}

export default withAuth(HomePageUserHostel);