import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class BestOfThree extends Component {
    state = {
        subject: [],
        total_marks: [],
        marks_secured: [],
        over_all: 0,
        obtained: 0,
        percentage: 0,
        best_subjects: [],
        subjects: [],
        isSubjectLoaded: false
    }

    __putNewData = (data) => {

        let subject = [];
        let total_marks = [];
        let marks_secured = [];

        data.map((el, index) => {
            subject.push(el.subject);
            total_marks.push(el.total_marks);
            marks_secured.push(el.marks_secured);
        })

        this.setState({
            subject,
            total_marks,
            marks_secured
        })


        this.calculate(data)

    }

    componentDidMount() {
        if (Array.isArray(this.props.data) && this.props.data.length > 0) {
            this.__putNewData(this.props.data);
           
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data !== this.props.data) {
            if (Array.isArray(this.props.data) &&  this.props.data.length > 0) {
                this.__putNewData(this.props.data)
                
            }
        }

    }



    onChange = (index, name, e) => {
        let { subject, total_marks, marks_secured } = this.state;




        if (name === 'subject') {
            subject[index] = e.target.value;
        }
        if (name === 'total_marks') {
            total_marks[index] = e.target.value;
        }

        if (name === 'marks_secured') {
            marks_secured[index] = e.target.value;
        }

        this.setState({
            subject,
            total_marks,
            marks_secured
        }, () => {
            let { subject, total_marks, marks_secured } = this.state;
            let best_subjects = [];
            for (let k = 0; k < this.props.rows; k++) {
                if (subject[k] !== '' && subject[k] !== undefined) {
                    let d = {
                        subject: subject[k],
                        total_marks: total_marks[k],
                        marks_secured: marks_secured[k]
                    }
                    best_subjects.push(d)
                }

            }
            this.calculate(best_subjects)
            this.props.onNewChange(best_subjects)

        })


    }

    onSubmit = () => {

        let d = {
            subject: this.state.subject,
            total_marks: this.state.total_marks,
            marks_secured: this.state.marks_secured,
        }

        let best_subjects = this.state.best_subjects;
        best_subjects.push(d);

        //calculate over all

        this.calculate(best_subjects);

        this.setState({
            best_subjects
        }, () => {
            this.props.onNewChange(best_subjects);
            if (this.props.type == 1) {
                this.props.sentTotalData({
                    over_all: this.state.over_all,
                    obtained: this.state.obtained,
                    percentage: this.state.percentage
                })
            }

            this.setState({
                subject: '',
                total_marks: '',
                marks_secured: '',
            })
        })
    }

    calculate = (best_subjects) => {
        let over_all = 0;
        let obtained = 0;
        let percentage = 0;

        best_subjects.map((el, index) => {
            let tot = parseInt(el.total_marks) || 0;
            let obt = parseInt(el.marks_secured) || 0;
            if (tot !== 0 && obt !== 0) {
                over_all = over_all + tot;
                obtained = obtained + obt;
            }

        })

        //calculate percentage

        if (obtained !== 0) {
            percentage = obtained / over_all * 100;
        }

        this.setState({
            over_all: over_all,
            obtained: obtained,
            percentage: percentage.toFixed(2)
        }, () => {
            if (this.props.type === 1) {

                this.props.sentTotalData({
                    over_all: this.state.over_all,
                    obtained: this.state.obtained,
                    percentage: this.state.percentage
                })
            }
        })
    }

    onDelete = (index) => {
        let best_subjects = this.state.best_subjects;
        best_subjects.splice(index, 1);

        this.calculate(best_subjects);

        this.setState({
            best_subjects
        })
    }
    render() {
        let { classes } = this.props;
        let i = [];
        if (this.state.isSubjectLoaded) {
            let subjects = this.state.subjects;
            if (Array.isArray(subjects) && subjects.length > 0) {
                i = subjects.map((el, index) =>

                    <option key={index} value={el}>{el}</option>

                )
            }
        }

        let trs = [];

        for (let j = 1; j <= this.props.rows; j++) {
            trs.push(
                <tr key={j}>
                    <td>
                        <input
                            className="form-control"
                            type="text"
                            name="subject"
                            value={this.state.subject[j - 1]}
                            onChange={this.onChange.bind(this, j - 1, 'subject')}
                            placeholder={`Subject ${j}`}
                        />

                    </td>
                    <td>
                        <input
                            className="form-control"
                            type="text"
                            name="total_marks"
                            value={this.state.total_marks[j - 1]}
                            onChange={this.onChange.bind(this, j - 1, 'total_marks')}
                            placeholder={`Total Marks ${j}`}
                        />
                    </td>
                    <td>
                        <input
                            className="form-control"
                            type="text"
                            name="marks_secured"
                            value={this.state.marks_secured[j - 1]}
                            onChange={this.onChange.bind(this, j - 1, 'marks_secured')}
                            placeholder={`Marks Secured ${j}`}
                        />
                    </td>

                </tr>
            )
        }
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td>Full Name of the Subject</td>
                            <td>Total Marks</td>
                            <td>Marks Secured</td>

                        </tr>
                    </thead>
                    <tbody>
                        {trs}
                    </tbody>
                    <tbody>
                        {this.state.best_subjects.map((el, index) =>

                            <tr key={index}>
                                <td>{el.subject}</td>
                                <td>{parseInt(el.total_marks)}</td>
                                <td>{parseInt(el.marks_secured)}</td>

                            </tr>

                        )}
                    </tbody>
                    <tbody>
                        <tr>
                            <td align="right"><strong>Total</strong></td>
                            <td><strong>{parseInt(this.state.over_all)}</strong></td>
                            <td><strong>{parseInt(this.state.obtained)}</strong></td>

                        </tr>
                        <tr>
                            <td colSpan="2" align="right"><strong>Percentage</strong></td>
                            <td><strong>{this.state.percentage} %</strong></td>
                        </tr>
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(BestOfThree)