import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import FormOne from './FormOne';

const styles = theme => ({

})

class ReadmissionForm extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <FormOne />
            </div>
        )
    }
}

export default withStyles(styles)(ReadmissionForm)