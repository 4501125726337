import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button, Grid } from '@material-ui/core';

import ReactToPrint from 'react-to-print';
import Loading from '../Forms/Loading';


import Barcode from 'react-barcode'
import { GetData } from '../../../api/service';
const styles = theme => ({

})

class AckReceipt extends Component {

    state = {
        data: this.props.data,
        isLoaded: true
    }

    componentDidMount() {

    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <h1>Admission Acknowledgement Receipt</h1>

                {this.state.isLoaded ? (
                    <Receipt
                        {...this.state.data}
                        ref={el => (this.componentRef = el)}
                    />
                ) : <Loading />}


                <br />
                <br />

                {this.state.isLoaded ? (

                    <div>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Acknowledgement Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />


                    </div>
                ) : null}
            </div>
        )
    }
}

export default withStyles(styles)(AckReceipt);

class Receipt extends Component {
    goodSemester = (sem) => {
        if (sem == 1) {
            return '1st';
        } else if (sem == 2) {
            return '2nd';
        } else if (sem == 3) {
            return '3rd';
        } else {
            return `${sem}th`;
        }
    }
    render() {
        return (
            <div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.jpg'}
                                    style={{ width: '100px' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    BH COLLEGE
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    P.O - Howly :: Dist - Barpeta (Assam) PIN - 781316
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    Hostel Acknowledgement Receipt ({this.props.admission_type.toUpperCase()}) <b>{this.props.session_name}</b>
                                </Typography>
                            </td>

                            <td width="20%" align="right">

                                <Typography
                                    variant="subtitle2"
                                >FORM NO<br />
                                   <b>{this.props.form_id}</b>


                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <Typography gutterBottom variant="body2">
                    <strong>{this.props.name}</strong>, you have been admitted into <strong>{this.props.selected_hostel} HOSTEL</strong>

                </Typography>

                <br />



                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="80%">
                                <table className="table">

                                    <tbody>
                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Class
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.course}-{this.props.stream}- {this.goodSemester(this.props.semester)} {this.props.course === 'HS' ? `Year` : `Semester`} {this.props.course === 'TDC' ? ' - ' + this.props.course_type : null}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Roll No
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.roll_no}
                                                </Typography>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Name
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.name}
                                                </Typography>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Form No
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.form_id}
                                                </Typography>
                                            </td>
                                        </tr>



                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Father's Name
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.father_name}
                                                </Typography>
                                            </td>
                                        </tr>





                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Date of Admission
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.good_admission_date}
                                                </Typography>
                                            </td>
                                        </tr>



                                        <tr>
                                            <td width="30%">
                                                <Typography
                                                    variant="body2"
                                                >
                                                    Fees Paid
            </Typography>
                                            </td>

                                            <td>
                                                <Typography
                                                    variant="body2"
                                                >
                                                    {this.props.payment_receipt.is_paid == 1 && `Paid`}  Rs. {this.props.payment_receipt.amount_paid} via {this.props.payment_receipt.mop}
                                                </Typography>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </td>

                        </tr>
                    </tbody>
                </table>

                <br />


                <Typography variant="body2">
                    <b>Declaration & Undertaking</b>: <br />
                    1. I declare that I have read all the Rules & Regulations of the Hostel and I shall abide by them and make payment as prescribed in the hostel prospectus.<br />
                    2. I further declare that, on admission to the hostel, I shall submit myself to the disciplinary jurisdiction of the in charge and other authorities who may be deputed by the in charge to exercise discipline under rules, procedure of the hostel.<br />
                    3. I shall not claim any refund of hostel fee paid deposited with the hostel, if I leave before the completion of the duration due to any reason whatsoever.<br />
                    4. I indemnify the hostel authorities against any casualties or risk to me.<br />
                    5. I agree that the Management’s decision in all matters concerning accommodation, discipline and conduct will be final and binding on me.<br />
                    6. I accept that if deemed fit as per rules the hostel authorities have the right to ask me to vacate the accommodation without assigning any reason.<br />
                    7. Before leaving the hostel I shall clear my dues in full and handover to the in charge the furniture and other items like mirror fittings intact.<br />
                    8. I shall not associate myself in any activity considered undesirable by the authorities. If I do so, my allotment of accommodation may be cancelled.
                </Typography>

                <br />


                <Typography variant="body2" style={{ textAlign: 'center' }}>
                    <strong>Note</strong> : Since this is a system generated Receipt, Signature is not required.
                </Typography>



            </div>
        )
    }

}

class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div
                style={{ height: '100%', width: '100%' }}
            >
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%', height: '100%' }}

                /> : <Loading />}
            </div>
        )
    }
}