import React, { Component } from 'react'
import { withStyles, Button, Typography, Grid, Divider, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { GetData } from '../../../api/service';
import ReactToPrint from 'react-to-print';
import './formprint.css'
import Barcode from 'react-barcode'
import Loading from '../Forms/Loading';
import AlertDiv from '../Forms/AlertDiv';
import { orange } from '@material-ui/core/colors';
import MyAdmissionLanding from '../Admission/MyAdmissionLanding';

import moment from 'moment';

const styles = theme => ({

})

class ViewForm extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            isLoaded: false
        }

        this.buttonRef = React.createRef();

    }


    getInitialData = () => {
        GetData(`/${this.props.user.email}/${this.props.user.course}/admissionform/getactivestudentsingle`)
            .then((resp) => {
                console.log(resp[0])
                if (Array.isArray(resp) && resp.length > 0 && resp[0]['is_complete'] == 1) {
                    this.setState({
                        data: resp[0],
                        isLoaded: true
                    })
                }
                else {
                  this.props.history.replace('/home')
                 }
            })
    }

    componentDidMount() {
      this.getInitialData();

    }


    render() {
        let { classes } = this.props;
        return (
            <div>

                {this.state.isLoaded && <MyAdmissionLanding
                    user={this.props.user}
                    data={this.state.data}
                    history={this.props.history}
                />}

                <div
                    style={{ textAlign: 'center' }}
                >
                    <AlertDiv
                        back={orange[100]}
                        border={orange[400]}
                    >
                        You can print this form or can save as pdf. Click on the button below
                    </AlertDiv>
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"

                            >
                                Print or Save
                        </Button>
                        }
                        ref={el => this.buttonRef = el}
                        content={() => this.componentRef}
                        pageStyle={{ margin: '10px' }}
                        copyStyles={true}
                    />

&nbsp;&nbsp;
                    <Button variant='contained' color='primary' size='small' onClick={() => this.props.history.push(`/paymentresponse`)}>Download Payment Receipt</Button>
                </div>


                <br />
                <br />

                {this.state.isLoaded ? <AdmissionForm
                    ref={el => (this.componentRef = el)}
                    {...this.state.data}
                /> : null}


            </div>
        )
    }
}

export default withStyles(styles)(ViewForm);

class AdmissionForm extends Component {

  calculateAge(dateOfBirth){
    let cuurentdDate = moment().startOf('year').format('YYYY-MM-DD')
    
  let diff = moment(cuurentdDate).diff(dateOfBirth, 'milliseconds');
  
  let duration = moment.duration(diff);
  
  let age = duration.asYears();
  return `${age.toFixed(2)} Years`;
  
  }


  goodSemester = (sem) => {
    if (sem == 1) {
      return "First";
    } else if (sem == 2) {
      return "Second";
    } else if (sem == 3) {
      return "Third";
    } else {
      return `${sem}th`;
    }
  };
  
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        return (
            <div className="admission-form">
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={'/images/logo.png'}
                                    style={{ width: '100px' }}
                                />
                            </td>

                            <td width="65%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                    Pragjyotish College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                   Santipur,Kamrup(M),Assam (India),Pin 781009
                                </Typography>

                                <Typography
                                    variant="body1"
                                >
                                    <b>Registration for Admission Form - {this.props.session_name}</b>
                                </Typography>
                            </td>

                            <td width="15%" align="right">
                                <Barcode
                                    value={this.props.form_id}
                                    height={40}
                                    width={1.3}
                                    margin={0}
                                    displayValue={false}
                                />
                                <Typography
                                    variant="subtitle2"
                                >
                                    Form No. : <b>{this.props.form_id}</b> <br />


                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>


                <br />



                <Grid container spacing={2}>

                    <Grid item lg={9} md={9} sm={9} xs={9}>
                    <table width="100%">
                <tbody>
                  <tr>
                    <td width="40%" colSpan={2}>
                      <Typography
                        style={{
                          fontWeight: "18px",
                          fontWeight: 500,
                        }}
                      >
                        Class to which admission is sought :
                      </Typography>
                    </td>
                   
                    <td colSpan={`2`} align="left">
                      <Typography variant="body2">
                        <b>{this.props.class_name}</b>
                      </Typography>
                    </td>
                  </tr>
                    
                  {this.props.course != "PG"  ? <><tr>
                    <td width="40%" colSpan={2}>
                      <Typography
                        style={{
                          fontWeight: "18px",
                          fontWeight: 500,
                        }}
                      >
                        Student's Unique ID : <br />
                        {this.props.course === "HSA" ||
                          this.props.course === "HSS" || this.props.course === "HSC" ? `ARN No : ` : null}
                      </Typography>
                    </td>

                    <td colSpan={`2`}>
                      <Typography variant="body2">
                        {this.props.unique_id} <br />
                        {this.props.arn_no}
                      </Typography>
                    </td>
                  </tr>

                  {!["HSA","HSS","HSC"].includes(this.props.course) && <tr>
                    <td width="40%" colSpan={2}>
                      <Typography
                        style={{
                          fontWeight: "18px",
                          fontWeight: 500,
                        }}
                      >
                        Samarth Registration No:
                      </Typography>
                    </td>

                    <td colSpan={`2`}>
                      <Typography variant="body2">
                        {this.props.samarth_no}
                        
                      </Typography>
                    </td>
                  </tr>}
                  </> : <>
                  <tr>
                    <td width="40%" colSpan={2}>
                      <Typography
                        style={{
                          fontWeight: "18px",
                          fontWeight: 500,
                        }}
                      >
                        Ranking Serial : 
                        
                      </Typography>
                    </td>

                    <td colSpan={`2`}>
                      <Typography variant="body2" style={{fontWeight: 700}}>
                      {this.props.ranking_serial}
                      </Typography>
                    </td>
                  </tr>

                  <tr>
                    <td width="40%" colSpan={2}>
                      <Typography
                        style={{
                          fontWeight: "18px",
                          fontWeight: 500,
                        }}
                      >
                        Ranking Score:
                      </Typography>
                    </td>

                    <td colSpan={`2`} >
                      <Typography variant="body2" style={{fontWeight: 700}}>
                        {this.props.ranking_score}
                        
                      </Typography>
                    </td>
                  </tr>
                  <tr>
                    <td width="40%" colSpan={2}>
                      <Typography
                        style={{
                          fontWeight: "18px",
                          fontWeight: 500,
                        }}
                      >
                        GUPGET Application No:
                      </Typography>
                    </td>

                    <td colSpan={`2`}>
                    <Typography variant="body2" style={{fontWeight: 700}}>
                        {this.props.arn_no}
                        
                      </Typography>
                    </td>
                  </tr>
                  </>}
                  

                  <tr>
                    <td width="40%" colSpan={`4`}>
                      <Typography
                        style={{
                          fontWeight: "18px",
                          fontWeight: 500,
                        }}
                      >
                        Marks obtained in last examination
                      </Typography>
                    </td>
                  </tr>
                  <tr vAlign="bottom">
                    <td colSpan="2">
                      <table width="100%" className="table">
                        <tbody>
                          <tr>
                            <td width="50%">
                              <Typography>Total Marks</Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {this.props.total_marks}
                              </Typography>
                            </td>
                          </tr>
                          <tr>
                            <td width="50%">
                              <Typography>Marks Obtained</Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {this.props.marks_obtained}
                              </Typography>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>

                    <td colSpan="2">
                      <table width="100%" className="table">
                        <tbody>
                          <tr>
                            <td width="50%">
                              <Typography>Percentage</Typography>
                            </td>
                            <td>
                              <Typography variant="body2">
                                {this.props.percentage}
                              </Typography>
                            </td>
                          </tr>
                          {this.props.geneder === 'FEMALE'?<tr>
                            <td width="30%">
                              <Typography>
                                Do you need hostel accomodation ?
                              </Typography>
                            </td>
                            <td width="70%">
                              <Typography variant="body2">
                                {this.props.has_hostel}
                              </Typography>
                            </td>
                          </tr>:null}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={4}>
                      {(this.props.course === "HSA" || this.props.course === "HSS" || this.props.course === "HSC") ? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="3">
                                <Typography
                                  style={{
                                    fontWeight: "18px",
                                    fontWeight: 500,
                                  }}
                                >
                                  Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <Typography variant="body2">
                                  Compulsory Subjects: {this.props.subjects_choosen?.find((el)=>el.type === 'major_1')?.subject?.label},{" "}
                                  {this.props.subjects_choosen?.find((el)=>el.type === 'major_2')?.subject?.label}
                                </Typography>
                              </td>

                              <td>
                                <Typography variant="body2">
                                  MIL: {this.props.subjects_choosen?.find((el)=>el.type === 'mil')?.subject?.label}
                                </Typography>
                              </td>
                           
                              <td >
                                
                                <Typography variant="body2">
                                    Elective Subjects :: 
                                    </Typography>
                                    <br />
                            <Typography variant="body2">
                                1 : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_1_1')?.subject?.label}<br />
                                2 : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_1_2')?.subject?.label}<br />
                                3 : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_2_1')?.subject?.label}<br />
                                4 : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_2_2')?.subject?.label}<br />
                            </Typography>
                                          
                                    
                              
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : null}

                      {(this.props.course === "BA" || this.props.course === "BSC")? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                >
                                  Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            {this.props.course_type === "HONOURS" ? <tr>
                              
                                <td colSpan={2}>
                                  

                                  <Typography variant="body2">
                                 <b> MAJOR (CORE I)</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'major_1')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                 <b> MINOR (CORE II)</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_1_1')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                 <b> MINOR (CORE III)</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_1_2')?.subject?.label}
                                  </Typography>
                                 
                                </td>

                                <td colSpan={2}>
                                  

                                  <Typography variant="body2">
                                 <b> MDC</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'mdc_1')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                 <b> VAC</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'vac')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                 <b>AEC</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'mil')?.subject?.label}
                                  </Typography>
                                 
                                </td>
                             
                            </tr>:null}
                          </tbody>
                        </table>
                      ) : null}

                      {this.props.course === "BCOM" ? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                >
                                 Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            
                              {this.props.course_type === "HONOURS" ? <tr>
                                <td colSpan={2}>
                                  
                                  <Typography variant="body2">
                                    
                                    <b>MAJOR </b> : {this.props.subjects_choosen?.find((el)=>el.type === 'major_1')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                    
                                    <b>CORE I</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_1_1')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                    
                                    <b>CORE II</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_1_2')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                    
                                    <b>CORE III</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'generic_1_3')?.subject?.label}
                                  </Typography>
                                 
                                </td>

                                <td colSpan={2}>
                                  

                                  <Typography variant="body2">
                                 <b> MDC</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'mdc_1')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                 <b> VAC</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'vac')?.subject?.label}
                                  </Typography>

                                  <Typography variant="body2">
                                 <b>AEC</b> : {this.props.subjects_choosen?.find((el)=>el.type === 'mil')?.subject?.label}
                                  </Typography>
                                 
                                </td>

                                </tr>
                              : null}

                              
                           
                          </tbody>
                        </table>
                      ) : null}

                        {this.props.course === "BBA" ? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                >
                                  Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                             
                                <td>
                                  <Typography
                                    variant="body2"
                                    style={{ fontWeight: 700 }}
                                    gutterBottom
                                  >
                                    Compulsory Subjects
                                  </Typography>

                                  <Typography variant="body2">
                                    Compulsory 1:{" "}
                                    {this.props.subjects_choosen?.find((el)=>el.type === 'major_1')?.subject?.label}
                                  </Typography>
                                  <Typography variant="body2">
                                    Compulsory 2:{" "}
                                    {this.props.subjects_choosen?.find((el)=>el.type === 'major_2')?.subject?.label}
                                  </Typography>
                                  <Typography variant="body2">
                                    Compulsory 3:{" "}
                                    {this.props.subjects_choosen?.find((el)=>el.type === 'major_3')?.subject?.label}
                                  </Typography>
                                </td>
                              

                                <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Ability Enhancement Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'mil')?.subject?.label}

                                </Typography>
                                <br />

                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Multi Disciplinary Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'generic_1')?.subject?.label}

                                </Typography>
                                <br />

                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Value Added Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'generic_2')?.subject?.label}

                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : null}

{(this.props.course === "BCA") ? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                >
                                  Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            <tr>

                              <td>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Core Subjects
                                </Typography>

                                <Typography variant="body2">
                                  Core 1:{" "}
                                  {this.props.subjects_choosen?.find((el) => el.type === 'major_1')?.subject?.label}
                                </Typography>
                                <Typography variant="body2">
                                Core 2:{" "}
                                  {this.props.subjects_choosen?.find((el) => el.type === 'major_2')?.subject?.label}
                                </Typography>
                                <Typography variant="body2">
                                Core 3:{" "}
                                  {this.props.subjects_choosen?.find((el) => el.type === 'major_3')?.subject?.label}
                                </Typography>

                              </td>


                              {/* <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Ability Enhancement Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'mil')?.subject?.label}

                                </Typography>
                              </td> */}

                              {/* <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Generic Elective
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'generic_1_1')?.subject?.label}

                                </Typography>
                              </td> */}
                            </tr>
                          </tbody>
                        </table>
                      ) : null}


{(this.props.course === "DIPLOMA" || this.props.course === "BAJMC") ? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                >
                                  Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            <tr>

                              <td>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Core Subjects
                                </Typography>

                                <Typography variant="body2">
                                  Core 1:{" "}
                                  {this.props.subjects_choosen?.find((el) => el.type === 'major_1')?.subject?.label}
                                </Typography>
                               

                              </td>


                             
                            </tr>
                          </tbody>
                        </table>
                      ) : null}

{(this.props.course === "BSCCS") ? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                >
                                  Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            <tr>

                              <td>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Core Subjects
                                </Typography>

                                <Typography variant="body2">
                                Core 1:{" "}
                                  {this.props.subjects_choosen?.find((el) => el.type === 'major_1')?.subject?.label}
                                </Typography>
                                <Typography variant="body2">
                                Core 2:{" "}
                                  {this.props.subjects_choosen?.find((el) => el.type === 'major_2')?.subject?.label}
                                </Typography>
                                <Typography variant="body2">
                                Core 3:{" "}
                                  {this.props.subjects_choosen?.find((el) => el.type === 'major_3')?.subject?.label}
                                </Typography>

                              </td>


                              <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Ability Enhancement Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'mil')?.subject?.label}

                                </Typography>


                                <br />

                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Value Added Course
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'generic_1_2')?.subject?.label}

                                </Typography>
                              </td>

                              <td style={{ verticalAlign: "top" }}>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  Multi Disciplinary Course 
                                </Typography>

                                <Typography variant="body2">
                                  {this.props.subjects_choosen?.find((el) => el.type === 'generic_1_1')?.subject?.label}

                                </Typography>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : null}

                    {this.props.course === "PG" ? (
                        <table width="100%" className="table">
                          <tbody>
                            <tr>
                              <td colSpan="4">
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                >
                                  Subjects opted for
                                </Typography>
                              </td>
                            </tr>
                            <tr>
                             
                                <td>
                                  

                                  <Typography variant="body2"
                                    style={{ fontWeight: 700 }}>
                                   
                                    {this.props.subjects_choosen?.find((el)=>el.type === 'major_1')?.subject?.label}
                                  </Typography>
                                  
                                </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : null}
                    </td>
                  </tr>
                </tbody>
              </table>

                    </Grid>

                    <Grid item lg={3} md={3} sm={3} xs={3} align="right">
                        <div className="passport-photo">
                            <ImageViewer
                                 data={
                                  this.props.documents_uploaded?.find(
                                    (el) => el.value === "PHOTO"
                                  )?.file
                                }
                            />
                        </div>


                    </Grid>

                </Grid>

                <table width="100%" className="table">
            <tbody>
              <tr>
                <td width={`5%`} colSpan={2}>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Name of the Applicant
                  </Typography>
                </td>
                <td width={`28%`} colSpan={2}>
                  <Typography variant="body2">{this.props.name}</Typography>
                </td>
                <td width={`5%`}>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Phone No
                  </Typography>
                </td>
                <td width={`28%`}>
                  <Typography variant="body2">{this.props.phone}</Typography>
                </td>
                <td width={`5%`}>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Email
                  </Typography>
                </td>
                <td width={`28%`} colSpan={2}>
                  <Typography variant="body2">{this.props.email}</Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Gender
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">{this.props.gender}</Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Date of Birth
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {moment(this.props.date_of_birth).format('DD/MM/YYYY')}
                  </Typography>
                </td>
                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Age as on {moment().startOf('year').format('DD/MM/YYYY')}
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">{this.calculateAge(this.props.date_of_birth)}</Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Caste
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">{this.props.caste}</Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Marital Status
                  </Typography>
                </td>
               
              </tr>

              <tr>
              <td>
                  <Typography variant="body2">{this.props.maratial_status}</Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Guardian's/Father Name
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {this.props.father_name}
                  </Typography>
                </td>
                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Father/Guardian's Phone
                  </Typography>
                </td>

                <td>
                  <Typography variant="body2">
                    {this.props.father_phone}
                  </Typography>
                </td>
                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Father/Guardian's Email
                  </Typography>
                </td>

                <td>
                  <Typography variant="body2">
                    {this.props.father_email}
                  </Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Father/Guardians Qualification
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {this.props.father_qualification}
                  </Typography>
                </td>

                
                
                
              </tr>

              <tr>

              <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Father/Guardian's Profession
                  </Typography>
                </td>

              <td>
                  <Typography variant="body2">
                    {this.props.father_profession}
                  </Typography>
                </td>
                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Mother Name
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {this.props.mother_name}
                  </Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Mother's Qualification
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {this.props.mother_qualification}
                  </Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Mother's Profession
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {this.props.mother_profession}
                  </Typography>
                </td>
                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Mother's Phone
                  </Typography>
                </td>
                
              </tr>

              <tr>
              <td>
                  <Typography variant="body2">
                    {this.props.mother_phone}
                  </Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Nationality
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">
                    {this.props.nationality}
                  </Typography>
                </td>
                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    Religion
                  </Typography>
                </td>
                <td>
                  <Typography variant="body2">{this.props.religion}</Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    <b>Differently abled ?</b>
                  </Typography>
                </td>
                <td width="30%">
                  <Typography variant="body2">
                    {this.props.is_pwd == 1 ? 'YES' :'NO'}
                  </Typography>
                </td>

                <td>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    BPL ?
                  </Typography>
                </td>
                <td width="30%">
                  <Typography variant="body2">
                    {this.props.is_bpl}
                  </Typography>
                </td>
              </tr>

              <tr>

              <td colSpan={2}>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                  Co-Curricular Activity
                  </Typography>
                </td>
                <td width="30%" colSpan={3}>
                  <Typography variant="body2">
                    {this.props.extra_curr}
                  </Typography>
                </td>

                <td colSpan={3}>
                  <Typography style={{ fontSize: "12px", fontWeight: 400 }}>
                    <b>Do you want to avail sports/cultural/NCC C Certificate quota (Minimum state level) ? *</b>
                  </Typography>
                </td>
                <td width="30%">
                  <Typography variant="body2">
                    {this.props.sports_quota == 1 ? 'YES' :'NO'}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>

                {/* <table width="100%" className="table">
                    <tbody>
                        <tr>
                            <td colSpan="6">
                                <Typography
                                    variant="h6"
                                    align="center"
                                >
                                    FOR OFFICE USE
                                </Typography>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    Date Of Admission
                                </Typography>
                            </td>

                            <td colSpan="4">
                                <Typography
                                    variant="body1"
                                >
                                    Class Admitted
                                </Typography>
                            </td>

                        </tr>

                        <tr>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    Registration Type
                                </Typography>
                            </td>

                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    New Student
                                </Typography>
                            </td>
                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Registered Student
                                </Typography>
                            </td>

                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Student Id
                                </Typography>
                            </td>
                            <td colSpan="1">
                                <Typography
                                    variant="body1"
                                >
                                    Roll No
                                </Typography>
                            </td>
                        </tr>



                        <tr>
                            <td colSpan="6" align="center">
                                <Typography
                                    variant="body1"
                                >
                                    Subjects Allotted
                                </Typography>
                            </td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                        </tr>



                    </tbody>
                </table> */}



<table width="100%" className="table">
            <tbody>
              <tr>
                <td width="25%" style={{ verticalAlign: "top" }}>
                  <Typography
                    style={{
                      fontWeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Bank Details
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    Account Holder: {this.props.account_holder}
                    <br />
                    Bank Name: {this.props.account_bank}
                    <br />
                    Branch: {this.props.account_branch}
                    <br />
                    A/C No: {this.props.account_no}
                    <br />
                    IFSC: {this.props.account_ifsc}
                    <br />
                    PAN Number: {this.props.pan_number}
                    <br />
                    AADHAR Number: {this.props.aadhar_number}
                  </Typography>
                </td>
                <td width="25%" style={{ verticalAlign: "top" }}>
                  <Typography
                    style={{
                      fontWeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Present Address
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    Village/Town: {this.props.present_address.pre_village}
                    <br />
                    P.O: {this.props.present_address.pre_po}
                    <br />
                    Dist: {this.props.present_address.pre_dist}, State:{" "}
                    {this.props.present_address.pre_state}
                    <br />
                    Pin: {this.props.present_address.pre_pin}, Contact:{" "}
                    {this.props.present_address.pre_contact}
                  </Typography>
                </td>

                <td width="25%" style={{ verticalAlign: "top" }}>
                  <Typography
                    style={{
                      fontWeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Permanent Address
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    Village/Town: {this.props.permanent_address.per_village}
                    <br />
                    P.O: {this.props.permanent_address.per_po}
                    <br />
                    Dist: {this.props.permanent_address.per_dist}, State:{" "}
                    {this.props.permanent_address.per_state}
                    <br />
                    Pin: {this.props.permanent_address.per_pin}, Contact:{" "}
                    {this.props.permanent_address.per_contact}
                  </Typography>
                </td>

                <td width="25%" style={{ verticalAlign: "top" }}>
                  <Typography
                    style={{
                      fontWeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Local Guardians' Details
                  </Typography>

                  <Typography
                    variant="body2"
                    style={{ fontSize: "12px", fontWeight: 400 }}
                  >
                    Name: {this.props.local_g}
                    <br />
                    Village/Town: {this.props.local_address.g_village}
                    <br />
                    P.O: {this.props.local_address.g_po}
                    <br />
                    Dist: {this.props.local_address.g_dist}, State:{" "}
                    {this.props.local_address.g_state}
                    <br />
                    Pin: {this.props.local_address.g_pin}, Contact:{" "}
                    {this.props.local_address.g_contact}
                    <br />
                    LAC: {this.props.permanent_address.per_lac}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>

          <table width="100%" className="table">
            <tbody>
              <tr>
                <td>
                  <Typography
                    style={{
                      fontWeight: "18px",
                      fontWeight: 500,
                    }}
                  >
                    Examination Passsed
                  </Typography>
                </td>
                <td align="center">
                  <Typography>Board / University</Typography>
                </td>
                <td align="center">
                  <Typography>Roll &amp; No.</Typography>
                </td>
                <td align="center">
                  <Typography>Year</Typography>
                </td>
                <td align="center">
                  <Typography>Institution</Typography>
                </td>
              </tr>
              {this.props.exam_passed.map((el, index) => (
                <tr key={index}>
                  <td align="center">{el.examination_passed}</td>
                  <td align="center">{el.board_name}</td>
                  <td align="center">{el.roll}</td>
                  <td align="center">{el.year}</td>
                  <td align="center">{el.institution}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {(this.props.course === "BSC") ? (
            <div>
              <table className="table" width="100%">
                <tbody>
                  <tr>
                    <td colSpan="6">
                      <Typography variant="body2">
                       <b> Best of three Science Subjects (Including English)</b>
                      </Typography>
                    </td>
                  </tr>
                </tbody>

                <tbody>
                  <tr>
                    <td>
                      <Typography>Subjects</Typography>
                    </td>
                    {this.props.best_subjects.map((el, index) => {
                      if (el.subject !== "") {
                        return (
                          <td key={index} align="right">
                            <Typography>{el.subject}</Typography>
                          </td>
                        );
                      }
                    })}
                    <td align="right">
                      <Typography>Total</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Total Marks</Typography>
                    </td>
                    {this.props.best_subjects.map((el, index) => {
                      total_marks = parseFloat(el.total_marks) + total_marks;
                      return (
                        <td key={index} align="right">
                          <Typography>{el.total_marks}</Typography>
                        </td>
                      );
                    })}
                    <td align="right">
                      <Typography>{total_marks}</Typography>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography>Obtained Marks</Typography>
                    </td>
                    {this.props.best_subjects.map((el, index) => {
                      marks_secured =
                        parseFloat(el.marks_secured) + marks_secured;
                      return (
                        <td key={index} align="right">
                          <Typography>{el.marks_secured}</Typography>
                        </td>
                      );
                    })}
                    <td align="right">
                      <Typography>{marks_secured}</Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : null}

          
            <table className="table" width="100%">
              <tbody>
                <tr>
                  <td colSpan={parseFloat(this.props.last_marks.length) + 2}>
                    <Typography variant="body2">
                      <b></b>
                    </Typography>
                  </td>
                </tr>
              </tbody>

              <tbody>
                <tr>
                  <td>
                    <Typography>Subjects</Typography>
                  </td>

                  {this.props.last_marks.map((el, index) => {
                    //console.log(this.props.last_marks)
                   
                      return (
                        <td key={index} align="center">
                          <Typography>{el.subject != "" ? el.subject : ''}</Typography>
                        </td>
                      );
                    
                  })}
                  <td align="center">
                    <Typography>Total</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Total Marks</Typography>
                  </td>
                  {this.props.last_marks.map((el, index) => {
                    total_marks = parseFloat(el.total_marks) + total_marks;
                   
                    return (
                      <td key={index} align="center">
                        <Typography>{el.subject != "" ? el.total_marks : ''}</Typography>
                      </td>
                    );
                  })}
                  <td align="center">
                    <Typography>{this.props.total_marks}</Typography>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Typography>Obtained Marks</Typography>
                  </td>
                  {this.props.last_marks.map((el, index) => {
                    marks_secured =
                      parseFloat(el.marks_secured) + marks_secured;
                    
                    return (
                      <td key={index} align="center">
                        <Typography>{el.subject !== "" ? el.marks_secured : ''}</Typography>
                      </td>
                    );
                  })}
                  <td align="center">
                    <Typography>{this.props.marks_obtained}</Typography>
                  </td>
                </tr>
                <tr>
                  <td colSpan={this.props.last_marks.length+1} align="right">
                    <Typography>Percentage</Typography>
                  </td>
                  <td align="center"><b>{this.props.percentage}</b></td>
                </tr>
              </tbody>
            </table>

            <table className="table" width="100%">
            <tbody>
             

              {/* <tr>
                <td width="20%">
                  <Typography>Activities Enrollment</Typography>
                </td>
                <td width="80%" colSpan={3}>
                  {Array.isArray(this.props.activities) &&
                  this.props.activities.length > 0 ? (
                    <FormGroup row>
                      {this.props.activities.map((el) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={el.status}
                              name={el.value}
                              color="primary"
                              readOnly={true}
                            />
                          }
                          label={el.label}
                        />
                      ))}
                    </FormGroup>
                  ) : (
                    "Not found"
                  )}
                </td>
              </tr> */}

              <tr>
                <td width="20%">
                  <Typography>Do you have Gap ?</Typography>
                </td>
                <td width="30%">
                  <Typography variant="body2">{this.props.has_gap}</Typography>
                </td>

                
              </tr>
            </tbody>
          </table>

          {this.props.has_gap === "YES" && (
            <table className="table" width="100%">
              <tbody>
                <tr>
                  <td width="20%">
                    <Typography>
                      Have you taken admission in any other college/institute?
                    </Typography>
                  </td>
                  <td width="30%">
                    <Typography variant="body2">
                      {this.props.gap_admission}
                    </Typography>
                  </td>

                  {this.props.gap_admission === "YES" && (
                    <td width="20%" colSpan={2}>
                      <Typography>
                        Institute: {this.props.gap_institute}
                        <br />
                        Course: {this.props.gap_course}
                        <br />
                        Enrollment/Reg No: {this.props.gap_roll_no}
                        <br />
                      </Typography>
                    </td>
                  )}
                </tr>

                <tr>
                  <td width="20%">
                    <Typography>Reason</Typography>
                  </td>
                  <td width="30%" colSpan={2}>
                    <Typography variant="body2">
                      {this.props.gap_reason}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          )}

        <Typography variant="h6">Documents Uploaded</Typography>
        <table className="table" width="100%">
            <tbody>
          <tr>
            <td>
            <Typography variant="body2">Document Name</Typography>
            </td>
          </tr>
          {this.props.documents_uploaded.map((el,index)=>
          <tr key={index}>
            <td>
            <Typography variant="body2">{el.label} </Typography>
            </td>
          </tr>
          )}
          </tbody>
        </table>

<table className="table" width="100%">
            <tbody>
              <tr>
                <td colSpan="2">
                  <Typography>Declaration from the Applicant</Typography>

                  <Typography variant="body2">
                  <Checkbox checked={true} />&nbsp; I , {this.props.name} hereby
                    declare that I shall abide by the rules and regulations of
                    the College and shall be ready to accept any punishment
                    which the authority deems fit for me if there is any
                    violation of the rules by me. I do hereby declare that the
                    statements made above are true to the best of my knowledge
                    and belief.
                  </Typography>
                  <br />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "bottom",
                    }}
                  >
                    <div>
                      <Typography variant="body2">
                        Date: {moment().format("DD/MM/YYYY")}
                      </Typography>
                    </div>
                    <div>
                      <div className="signature-photo">
                        <ImageViewer
                          data={
                            this.props.documents_uploaded?.find(
                              (el) => el.value === "SIGNATURE"
                            )?.file
                          }
                        />
                      </div>
                      <Typography variant="body2" style={{ float: "right" }}>
                        Full Signature of the Applicant
                      </Typography>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          

                <table width="100%" className="table">
                    <tbody>
                        <tr><td colSpan="6">&nbsp;</td></tr>

                        <tr>
                            <td colSpan="6">
                                <Typography
                                    variant="body1"
                                >
                                    <b>  Checked By </b>
                                </Typography>
                            </td>



                        </tr>

                        <tr>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Selected</b>
                                </Typography>
                            </td>

                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Rejected</b>
                                </Typography>
                            </td>
                            <td colSpan="2">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Admitted</b>
                                </Typography>
                            </td>

                        </tr>
                        <tr>
                            <td colSpan="3">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Admission In Charge</b>
                                </Typography>
                            </td>
                            <td colSpan="3">
                                <Typography
                                    variant="body1"
                                >
                                    <b>Principal</b>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div >
        )
    }
}


class ImageViewer extends Component {
    state = {
        file_id: '',
        data: '',
        isLoaded: false
    }

    __getFile = (file_id) => {
        GetData(`/${file_id}/getfilesingle`)
            .then((resp) => {
                if (resp.hasOwnProperty('mime') && resp.hasOwnProperty('data')) {
                    let file = '';
                    if (resp.mime === 'image/jpeg' || resp.mime === 'image/png' || resp.mime === 'image/jpg') {
                        file = `data:${resp.mime};base64,${resp.data}`;
                    }
                    this.setState({
                        data: file,
                        isLoaded: true
                    })
                }
            })
    }

    componentDidMount() {
        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            if (data[0].hasOwnProperty('file_id')) {
                this.__getFile(data[0].file_id)
            }
        }
    }

    render() {
        return (
            <div
                style={{ height: '100%', width: '100%' }}
            >
                {this.state.isLoaded ? <img
                    alt="sig-photo"
                    src={this.state.data}
                    style={{ width: '100%', height: '100%' }}

                /> : <Loading />}
            </div>
        )
    }
}