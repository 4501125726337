import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import withAuth from '../auth_components/js/withAuth';
import Navbar from '../Components/Navbar';
import ReAdmissionHome from '../Components/ReAdmission/ReAdmissionHome';


class ReadmissionHomePage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
            <Navbar
                history={this.props.history}
            />
        <div className="main-body">

          <Switch>
            <Route
              exact
              path="/readmission"
              render={(props) => <ReAdmissionHome
                {...props}
                history={this.props.history}
              />}
            />

            
          </Switch>



        </div>
      </div>
    )
  }
}

export default ReadmissionHomePage;