import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import withAuth from '../auth_components/js/withAuth';
import LoggedNavBar from '../Components/LoggedNavBar';
import FormsLanding from '../Components/ReRegistration/Forms/FormsLanding';
import ViewForm from '../Components/ReRegistration/View/ViewForm';
import PaymentResponse from '../Components/ReRegistration/View/PaymentResponse';
import AdmissionLanding from '../Components/ReRegistration/Admission/AdmissionLanding';
import AckLanding from '../Components/ReRegistration/Admission/AckLanding';
import AdmissionPayment from '../Components/ReRegistration/Admission/AdmissionPayment';
import NewPaymentPageRe from '../Components/ReRegistration/Forms/NewPaymentPageRe';
import ExamFeesRe from '../Components/ReRegistration/Forms/ExamFeesRe';


class HomePageReUser extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log(this.props.user.user.role)
    if (this.props.user.user.role == 'STUDENT') {
      this.props.history.replace(`/rehome`)
    }

  }

  render() {
    return (
      <div>
        <LoggedNavBar
          history={this.props.history}
          type={2}
        />
        <div className="main-body">

          <Switch>
            <Route
              exact
              path="/rehome"
              render={(props) => <FormsLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/refinal"
              render={(props) => <ViewForm
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/repaymentresponse"
              render={(props) => <PaymentResponse
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />


            <Route
              exact
              path="/re-admission"
              render={(props) => <AdmissionLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />
              <Route
              exact
              path="/re-admission-payment/:form_id"
              render={(props) => <NewPaymentPageRe
                {...props}
                history={this.props.history}
                user={this.props.user.user}
                form_id={props.match.params.form_id}
              />}
            />

          <Route
              exact
              path="/examfeesre/:form_id"
              render={(props) => <ExamFeesRe
                {...props}
                history={this.props.history}
                user={this.props.user.user}
                form_id={props.match.params.form_id}
              />}
            />




<Route
              exact
              path="/newpaymentpagere"
              render={(props) => <NewPaymentPageRe
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/re-admission/ack"
              render={(props) => <AckLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />


          </Switch>



        </div>
      </div>
    )
  }
}

export default withAuth(HomePageReUser);