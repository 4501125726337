import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, CardHeader, Avatar, CardActions } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import PreviewForm from '../View/PreviewForm';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '60vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: blue[400]
    }
})

class FormThree extends Component {
//final step



    askConf = () => {
            let d={
                step:3
            }
            
        confirmAlert({
            title: 'Final Step',
            message: 'This is the final step of registration. You can not modify anything after this step. Are you sure to submit ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.setData(d)
                },
                {
                    label: 'No'
                 }
            ]
        });


    }
    render() {
        let { classes } = this.props;
        return (
            <div >

                <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                    <CardHeader
                        
                        title={`Final Preview`}
                        subheader={`Please reverify the information`}
                    />

                    <CardContent>
                        <PreviewForm 
                            user={this.props.user}
                        />
                    </CardContent>

                    <CardActions style={{
                        justifyContent: 'flex-end'
                    }}>

                        <Button

                            type="button"
                            onClick={this.props.back}
                        >
                            Previous
                        </Button>
                        <Button
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={this.askConf}
                        >
                            Submit
                        </Button>
                    </CardActions>
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(FormThree)