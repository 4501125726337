import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileUpload from './FileUpload';

import allStates from '../../../helper/IndianStates.json'

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})

const occupations = [
    "Govt. Service",
    "Non-Govt. Service",
    "Farmer",
    "Others"
]

class FormOne extends Component {

    state = {
        name: this.props.user.name,

        class_name: '',
        course: '',
        stream: '',
        semester: '',
        course_type: '',
        step: 1,
        roll_no: '',
        admission_year: '',
        last_percentage: '',
        last_institution: '',
        expel_status: '',
        hostel_rules: '',
        hostel_boarder: '',
        hostel_damage: '',
        sports_status: '',
        sports_description: '',
        father_name: '',
        father_contact: '',
        admission_type: '',
        hostel_preference: '',
        caste: '',

        phone: this.props.user.phone,
        email: this.props.user.email,

        gender: '',
        marrital_status: '',

        nationality: 'Indian',


        differently_abled: '',
        data_type: '',
        pre_village: '', pre_po: '', pre_dist: '', pre_state: '', pre_pin: '', pre_contact: '',
        per_village: '', per_po: '', per_dist: '', per_state: '', per_pin: '', per_contact: '', per_lac: '',
        g_village: '', g_po: '', g_dist: '', g_state: '', g_pin: '', g_contact: '', g_name: ''


    }

    componentDidMount() {

        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
                ...data[0].present_address,
                ...data[0].local_address,
                admission_type: data[0].data_type

            })
        }

    }



    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0],
                    ...data[0].present_address,
                    ...data[0].local_address,
                    admission_type: data[0].data_type
                })
            }
        }

    }



    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })

        if (e.target.name === 'hostel_boarder' && e.target.value === 'NO') {
            this.setState({
                hostel_dues: 'NO'
            })
        }
    }

    onSubmit = (e) => {
        e.preventDefault();

        let present_address = {
            pre_village: this.state.pre_village,
            pre_po: this.state.pre_po,
            pre_dist: this.state.pre_dist,
            pre_state: this.state.pre_state,
            pre_pin: this.state.pre_pin,
            pre_contact: this.state.pre_contact,
        }
        // let permanent_address = {
        //     per_village: this.state.per_village,
        //     per_po: this.state.per_po,
        //     per_dist: this.state.per_dist,
        //     per_state: this.state.per_state,
        //     per_pin: this.state.per_pin,
        //     per_contact: this.state.per_contact,
        //     per_lac: this.state.per_lac,

        // }
        let local_address = {
            g_name: this.state.g_name,
            g_village: this.state.g_village,
            g_po: this.state.g_po,
            g_dist: this.state.g_dist,
            g_state: this.state.g_state,
            g_pin: this.state.g_pin,
            g_contact: this.state.g_contact,
        }
        this.setState({
            present_address,
            //permanent_address,
            local_address
        }, () => { this.props.setData(this.state) })



        // confirmAlert({
        //     title: 'Confirm to submit',
        //     message: 'Are you sure to do this.',
        //     buttons: [
        //         {
        //             label: 'Yes',
        //             onClick: () => this.props.setData(d)
        //         },
        //         {
        //             label: 'No'
        //         }
        //     ]
        // });

    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h5"
                    >
                        Hostel Application Form ({this.state.data_type})
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>
                    <Typography>
                        <span className="red-alert">Married student will not get admission in hostel.</span>
                    </Typography>
                    <br />









                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Personal Details
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Name of the Applicant <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        required
                                        readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Gender <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="gender"
                                        required
                                        value={this.state.gender}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Marital Status <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="marrital_status"
                                        required
                                        value={this.state.marrital_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Unmarried">Unmarried</option>
                                        <option value="Married">Married</option>
                                    </select>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                        <Typography
                                            variant="subtitle2"
                                            style={{ fontWeight: 700 }}

                                        >
                                            Caste <span className="red-alert">*</span>
                                        </Typography>
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="caste"
                                            required
                                            value={this.state.caste}
                                            onChange={this.onChange}
                                        >
                                            <option value="">Select</option>
                                            <option value="GENERAL">GENERAL</option>
                                            <option value="ST(H)">ST(H)</option>
                                            <option value="ST(P)">ST(P)</option>
                                            <option value="SC">SC</option>
                                            <option value="OBC">OBC/MOBC</option>

                                        </select>
                                    </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Nationality <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nationality"
                                        required
                                        value={this.state.nationality}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Father's Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="father_name"
                                        value={this.state.father_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Father's Contact No.
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="father_contact"
                                        value={this.state.father_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                            </Grid>

                        </CardContent>
                    </Card>
                    <br />


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Address for correspondense
                            </Typography>


                            <Grid container spacing={16}>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_village"
                                        value={this.state.pre_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.O<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_po"
                                        value={this.state.pre_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Dist<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_dist"
                                        value={this.state.pre_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State<span className="red-alert">*</span>
                                    </Typography>

                                    <select
                                        className="form-control"
                                        type="text"
                                        name="pre_state"
                                        required
                                        value={this.state.pre_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="pre_pin"
                                        value={this.state.pre_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_contact"
                                        value={this.state.pre_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <br />


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Local Guardian's Details
                            </Typography>


                            <Grid container spacing={16}>
                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Name
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"


                                        name="g_name"
                                        value={this.state.g_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="g_village"
                                        value={this.state.g_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        P.O
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="g_po"
                                        value={this.state.g_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Dist
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="g_dist"
                                        value={this.state.g_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State
                                    </Typography>

                                    <select
                                        className="form-control"
                                        type="text"
                                        name="g_state"

                                        value={this.state.g_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="g_pin"
                                        value={this.state.g_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="g_contact"
                                        value={this.state.g_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>



                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Admission Details
                            </Typography>


                            <Grid container spacing={16}>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Admission Type <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="admission_type"
                                        required
                                        value={this.state.admission_type}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="new">New Admission</option>
                                        <option value="old">Renewal Admission</option>
                                    </select>
                                </Grid>



                                {this.state.gender == 'FEMALE' && <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Hostel Preference <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="hostel_preference"
                                        required
                                        value={this.state.hostel_preference}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="New Building">New Building</option>
                                        <option value="Old Building">Old Building</option>
                                    </select>
                                </Grid>
                                }




                            </Grid>

                        </CardContent>
                    </Card>

                    <br />



                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>



                        <CardContent>


                            <Grid container spacing={16}>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: blue[700]
                                        }}

                                    >
                                        Other Details
                                    </Typography>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} md={6}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Class to which admitted <span className="red-alert">*</span>
                                    </Typography>
                                    {/* <input
                                        className="form-control"
                                        type="text"
                                        name="class_name"
                                        required

                                        value={this.state.class_name}
                                        onChange={this.onChange}
                                    /> */}

                                    <Grid container spacing={16}>

                                        <Grid item xs={3} sm={3} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700 }}
                                            >
                                                Course <span className="red-alert">*</span>
                                            </Typography>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="course"
                                                required
                                                value={this.state.course}
                                                onChange={this.onChange}

                                            >
                                                <option value="">Select</option>
                                                <option value="HS">HS - Higher Secondary</option>
                                                <option value="TDC">TDC - Under Graduate </option>
                                                <option value="BBA">BBA</option>
                                                <option value="PG">PG</option>

                                            </select>
                                        </Grid>

                                        {this.state.course !== 'BBA' && this.state.course !== 'PG' && <Grid item xs={3} sm={3} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Stream <span className="red-alert">*</span>
                                            </Typography>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="stream"
                                                required
                                                value={this.state.stream}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="ARTS">ARTS</option>
                                                <option value="SCIENCE">SCIENCE</option>
                                                <option value="COMMERCE">COMMERCE</option>
                                            </select>
                                        </Grid>}

                                        {this.state.course == 'PG' && <Grid item xs={3} sm={3} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Stream <span className="red-alert">*</span>
                                            </Typography>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="stream"
                                                required
                                                value={this.state.stream}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Select</option>
                                                <option value="EDUCATION">EDUCATION</option>
                                                <option value="ASSAMESE">ASSAMESE</option>
                                                <option value="COMMERCE">COMMERCE</option>
                                            </select>
                                        </Grid>}

                                        <Grid item xs={3} sm={3} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700 }}
                                            >
                                                Semester/Year <span className="red-alert">*</span>
                                            </Typography>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="semester"
                                                required
                                                value={this.state.semester}
                                                onChange={this.onChange}

                                            >
                                                <option value="">Select</option>
                                                {[1, 2, 3, 4, 5, 6].map((el, index) =>
                                                    <option key={el} value={el}>{el}</option>
                                                )}




                                            </select>
                                        </Grid>

                                        {this.state.course === 'TDC' ? (<Grid item xs={3} sm={3} lg={3} md={3}>
                                            <Typography
                                                variant="subtitle2"

                                            >
                                                Course Type <span className="red-alert">*</span>
                                            </Typography>
                                            <select
                                                className="form-control"
                                                type="text"
                                                name="course_type"
                                                required
                                                value={this.state.course_type}
                                                onChange={this.onChange}
                                            >
                                                <option value="">Couse type</option>
                                                <option value="HONOURS">HONOURS (HC)/Major</option>
                                                <option value="REGULAR">REGULAR (RC)/General</option>

                                            </select>
                                        </Grid>) : null}
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Roll No
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="roll_no"


                                        value={this.state.roll_no}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Admission Year <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="admission_year"
                                        required

                                        value={this.state.admission_year}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Percentage of marks in last exam<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="last_percentage"

                                        required
                                        value={this.state.last_percentage}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Name of the Institution last attended<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="last_institution"

                                        required
                                        value={this.state.last_institution}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Were you expelled from college or hostel previously ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="expel_status"
                                        required
                                        value={this.state.expel_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Will you abide by hostel rules  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="hostel_rules"
                                        required
                                        value={this.state.hostel_rules}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>


                                    </select>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Will you compensate any kind of damage to the hostel caused by you  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="hostel_damage"
                                        required
                                        value={this.state.hostel_damage}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>


                                    </select>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Were you a hostel boarder in previous year  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="hostel_boarder"
                                        required
                                        value={this.state.hostel_boarder}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>


                                    </select>

                                </Grid>

                                {this.state.hostel_boarder === 'YES' && <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Did you clear hostel dues within stipulated terms  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="hostel_dues"
                                        required
                                        value={this.state.hostel_dues}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>


                                    </select>

                                </Grid>}


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Do you have any proficiency in games and sports ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="sports_status"
                                        required
                                        value={this.state.sports_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>
                                    </select>

                                    {this.state.sports_status == 'YES' && <input
                                        required
                                        className="form-control"
                                        type="text"
                                        name="sports_description"
                                        placeholder="Specify your field"
                                        value={this.state.sports_description}
                                        onChange={this.onChange}
                                    />}
                                </Grid>



                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Persion with Disability (PWD)  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="differently_abled"
                                        required
                                        value={this.state.differently_abled}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>


                                    </select>

                                </Grid>



                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>

                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormOne)