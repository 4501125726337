import React, { Component } from 'react'
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core'
import { PostData } from '../../../api/service'

export default class UpdateUniqueId extends Component {

    state = {
        unique_id: ""
    }

    componentDidMount() {
        this.setState({
            unique_id: this.props.data.unique_id
        })
    }

    setUniqueId = (v) => {
        this.setState({
            unique_id: v
        })
    }


    updateUniqueId = () => {
        if(this.state.unique_id != ""){
            PostData(`/${this.props.data.form_id}/admissionform/updatemyuniqueid`, {
                unique_id: this.state.unique_id
            }).then((resp) => {
                alert(resp)
            }).catch((err) => {
                console.log("Error")
            })
        }
    }

    render() {
        return (
            <div> <Card>
                <CardContent>
                    <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                    >
                        Unique ID
                    </Typography>
                    <input
                        className='form-control'
                        value={this.state.unique_id}
                        onChange={(e) => this.setUniqueId(e.target.value)}
                    />
                </CardContent>
                <CardActions style={{justifyContent: 'flex-end'}}>
                    <Button color="primary" variant="contained" onClick={() => this.updateUniqueId()}>Update</Button>
                </CardActions>
            </Card>
            
            <br />
            <br />
            </div>
        )
    }
}
