import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, CardActions, Button, MenuItem, FormControl, Select, OutlinedInput, InputLabel } from '@material-ui/core';
import { blue, lightGreen, orange } from '@material-ui/core/colors';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AlertDiv from './AlertDiv';
import FileUpload from '../FileUpload/FileUpload';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    },
    formControl: {
        width: '100%'
    }
})


class FormTwo extends Component {
    state = {
        isLoaded:false,
        errors: [],
        documents_required:[],
        documents_uploaded:[]

    }


    componentDidMount() {
        //console.log(this.props)
        if (this.props.edit) {
            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                //set photo data
                let dr = [...this.props.documents_required];
                let du = [];

                if(Array.isArray(this.props.documents_uploaded) && this.props.documents_uploaded.length>0)
                 du = [...this.props.documents_uploaded];
                
                let documents_required = [];

                dr?.forEach((el)=>{
                    if( el.value !== 'CASTE' && el.value !== 'EWS' && el.value !=='PWD' && el.value !=='GAP' && el.value !=='SPORTS_CERTIFICATE' && el.value !== 'has_aadhar_card' && el.value !== 'PRC' && el.value !== 'BPL' && el.value !== 'UNIQUE_ID_PHOTO'){
                        documents_required.push(el);
                    }
                    else if(el.value === 'CASTE'){
                        if(data[0]['caste'] !=='GENERAL'){
                        documents_required.push(el);
                        }
                    }
                    else if(el.value === 'EWS'){
                        if(data[0]['is_ews'] =='1'){
                        documents_required.push(el);
                        }
                    }
                    else if(el.value === 'PWD'){
                        if(data[0]['is_pwd'] =='1'){
                        documents_required.push(el);
                        }
                    }
                    else if(el.value === 'GAP'){
                        if(data[0]['has_gap'] =='YES'){
                        documents_required.push(el);
                        }
                    }
                    else if(el.value === 'SPORTS_CERTIFICATE'){
                        if(data[0]['sports_quota'] =='1'){
                        documents_required.push(el);
                        }
                    }
                    else if(el.value === 'AADHAR_CARD'){
                        if(data[0]['has_aadhar_card'] =='YES'){
                        documents_required.push(el);
                        }
                    }else if(el.value === 'PRC'){
                        if(data[0]['has_prc'] =='YES'){
                        documents_required.push(el);
                        }
                    }
                    else if(el.value === 'BPL'){
                        if(data[0]['is_bpl'] =='YES'){
                        documents_required.push(el);
                        }
                    }
                    else if(el.value === 'UNIQUE_ID_PHOTO'){
                        if(data[0]['unique_id'] !='' && data[0]['unique_id'] != null ){
                        documents_required.push(el);
                        }
                    }
                })

                this.setState({
                    documents_required,
                    documents_uploaded:du,
                    isLoaded:true

                })
            }
        }
    }


    onChangePhoto = (name,value) => {
    //console.log("🚀 ~ file: FormTwo.js:437 ~ FormTwo ~ name:", name)
    //console.log("🚀 ~ file: FormTwo.js:437 ~ FormTwo ~ value:", value)

        let du = [...this.state.documents_uploaded];
        let dc = [...this.state.documents_required];
        
        let index = -1;
        index = du?.findIndex((el)=>el.value == name);

        if(Array.isArray(value) && value.length>0){

        
        if(index !=-1){
            du[index]['file'] = value;
        }
        else {
            let el  = dc?.find((el)=>el.value == name); 

            if(dc !== null && dc !==undefined)
        du.push({
            ...el,
            file:value
        })

        }
        
    }
    else {
        if(index !==-1)
        du.splice(index,1);
    }

    this.setState({
        documents_uploaded:du
    },()=>{
        //console.log(this.state)
    })

    }

    handleValidation = () => {
        let { documents_required, documents_uploaded } = this.state;
        let isValid = true;
        let errors = [];


        documents_required.map((el)=>{
            let index = documents_uploaded.findIndex((el1)=>el.value === el1.value);

            if(index === -1){
                isValid = false;
            errors[el.value] = `Upload ${el.label}`;
            }
            else {
                errors[el.value] = ``;
            }
        })

        this.setState({
            errors
        })

        //console.log(errors)

        return isValid;

    }



    onSubmit = (e) => {
        e.preventDefault();

        if (this.handleValidation()) {
            let d = {
                email: this.props.email,
                documents_required: this.state.documents_required,
                documents_uploaded: this.state.documents_uploaded,
                step:3
            }

            this.props.setData(d);

        }




    }


    render() {
        let { classes } = this.props;



        return (
            <div>
                {this.state.isLoaded?<form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h5"
                    >
                        Document Uploads
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked documents are mandatory
                    </Typography>



                    <br />

                    <AlertDiv
                        back={orange[100]}
                        border={orange[400]}
                    >
                        <Typography style={{fontSize:20,fontWeight:'bold',color:'red'}}>Please upload proper documents. Otherwise your application form will be rejected.</Typography>
                        <strong>Instructions !!</strong>

                        <ul>
                            <li><Typography>Upload a clean, scanned copy of your document</Typography></li>
                            <li><Typography>Only .jpg or .png extensions are allowed</Typography></li>
                            {/* <li><Typography>After Choosing the file, press "Upload" button.</Typography></li> */}
                            <li><Typography>After successful upload, the file will appear in the white box</Typography></li>
                        </ul>

                        <strong>Photograph</strong>
                        <ul>
                            <li><Typography>Photograph must be a recent passport size colour picture.</Typography></li>
                            <li><Typography>Dimensions 200 x 230 pixels (preferred)</Typography></li>
                        </ul>

                        <strong>Signature</strong>
                        <ul>
                            <li><Typography>Signature must be in a white paper with black ink.</Typography></li>
                            <li><Typography>Dimensions 100  x 60 pixels (preferred)</Typography></li>
                        </ul>


                        {/* <strong>Domicile Certificate</strong>
                        <Typography>
                            The students must furnish <b>any one</b> of the following supporting documents to prove their domicile of Assam - PRC/Voter Identity Card/NRC of Self or Parent/ADHAR Card of self or parent/BPL card of family/Birth Certificate issued by Registrar of Birth & Death/Passport of self or parent
                        </Typography> */}

                        <br />

                        

                    </AlertDiv>

                    <br />


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}
                                gutterBottom
                            >
                                Upload Following Documents 
                            </Typography>
                            <Grid container spacing={2}>

                                {Array.isArray(this.state.documents_required) && this.state.documents_required.length>0?this.state.documents_required?.map((el,index)=>
                                <Grid item xs={6} sm={6} lg={3} md={3} key={index}>
                                {this.state.errors[el.value] !== '' ? (

                                    <div>
                                        <Typography
                                            variant="caption"
                                            style={{ color: 'red' }}
                                        >
                                            {this.state.errors[el.value]}
                                        </Typography>
                                    </div>

                                ) : null}

                                <FileUpload
                                    title={
                                        <Typography
                                            variant="subtitle2"
                                            style={{ fontWeight: 700, fontSize: '12px' }}

                                        >
                                            Upload {el.label} (size below 500kb) <span className="red-alert">*</span>
                                        </Typography>
                                    }
                                    type={`ADMISSION_${el.value}`}
                                    apikey={this.props.user.api_key}
                                    onChange={this.onChangePhoto.bind(this, el.value)}
                                    data={this.state.documents_uploaded?.find((el1)=>el1.value === el.value)?.file || []}
                                    limit={500}
                                 
                                />

                            </Grid>
                                ):null}
                                
                              
                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>
                        <Button variant="contained" type="button" onClick={this.props.back}>
                Previous
              </Button>&nbsp;
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>

                        </CardActions>
                    </Card>
                    <br />
                   
                </form>:null}
            </div>
        )
    }
}

export default withStyles(styles)(FormTwo)