import React, { Component } from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';

const styles = theme => ({
    root: {
        backgroundColor: '#2f2f2f',
        color: 'white',
        paddingLeft: '10vw',
        paddingRight: '10vw',
        paddingTop: '5vh',
        paddingBottom: '5vh',
    }
})

class FooterDiv extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* <Grid container spacing={24}>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                         <Typography
                            color="inherit"
                            variant="h6"
                            gutterBottom
                        >
                            Contact Us
                        </Typography>

                        <Typography
                            color="inherit"
                            variant="body1"
                        >
                            For Technical Queries
                            call us at (10AM to 10PM - Working Days): <br />
                            
                            <a rel="noreferrer noopenner" target="_blank" href="telto:8638201520">86382 01520 </a><br />
                            <a rel="noreferrer noopenner" target="_blank" href="telto:7002197274">70021 97274</a><br />
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Typography
                            color="inherit"
                            variant="h6"
                            gutterBottom
                        >
                            Other Links
                        </Typography>
                        <Typography
                            color="inherit"
                            variant="body1"
                        >

                            <a href="/" rel="noreferrer noopenner" target="_blank">Terms and Conditions</a>
                        </Typography>
                    </Grid>

                </Grid> */}
                <div style={{textAlign: 'center', color: 'white'}}>
                    <br />
                    <Typography variant="caption" color="inherit">Designed & Developed by <a href={`https://corexx.in`}>https://corexx.in</a></Typography>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(FooterDiv)