import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})

const occupations = [
    "Govt. Service",
    "Non-Govt. Service",
    "Farmer",
    "Others"
]

class FormOne extends Component {

    state = {
        name: '',
        class_id: '',
        class_name: '',
        course: '',
        email1: '',
        stream: '',
        session: '',
        session_id: '',
        is_free_admission: 0,
        phone: '',
        email: '',
        father_name: '',
        mother_name: '',
        p_occupation: '',
        gender: '',
        date_of_birth: '',
        nationality: '',
        religion: '',
        caste: '',
        quota: '',
        state: '',
        blood_group: '',
        present_address: '',
        permanent_address: '',
        local_g: '',
        extra_curr: '',
        exam_passed: [],
        best_subjects: [],
        last_marks: [],
        total_marks: '',
        marks_obtained: '',
        percentage: '',
        certificate: [],
        marksheet: [],
    }

    componentDidMount() {
        
        let data = this.props.data;
        if(Array.isArray(data) && data.length > 0){
            this.setState({
                ...data[0]
            })
        }
        
            
        
    }

    toggleAddCh = () => {
        this.setState({
            add_ch: !this.state.add_ch
        }, () => {
            if (this.state.add_ch) {
                this.setState({
                    permanent_address: this.state.present_address
                })
            } else {
                this.setState({
                    permanent_address: ''
                })
            }
        })
    }

    componentDidUpdate(prevProps) {

        if(this.props !== prevProps){
            
            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0]
                })
            }
        }
        
    }


    onNewChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    sentTotalData = (data) => {
        this.setState({
            ...data
        })
    }

    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        let d = this.state;

        confirmAlert({
            title: 'Confirm to submit',
            message: 'Are you sure to do this.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.setData(d)
                },
                {
                    label: 'No'
                }
            ]
        });

    }

    sentTotalData = (data) => {
        this.setState({
            total_marks: data.over_all,
            marks_obtained: data.obtained,
            percentage: data.percentage
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>
                    <Card style={{ borderLeft: 'solid 5px #1715DA' }}>
                       
                        <CardHeader
                            title={`Application Form`}
                            subheader={`Personal Details`}
                        />

                        <CardContent>


                            <Grid container spacing={24}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Full Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        required
                                        
                                    />
                                </Grid>
                            



                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Phone <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="phone"
                                        
                                        required
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Email 
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="email1"
                                        
                                        
                                        value={this.state.email1}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Father's Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="father_name"
                                        value={this.state.father_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Mother's Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="mother_name"
                                        value={this.state.mother_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Parent's Occupation <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        required
                                        name="p_occupation"
                                        value={this.state.p_occupation}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select Occupation</option>
                                        {occupations.map((el,index) => 
                                                <option key={index} value={el}>{el}</option>
                                            )}
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Gender <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="gender"
                                        required
                                        value={this.state.gender}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Date of Birth <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="date"
                                        required
                                        name="date_of_birth"
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Nationality <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="nationality"
                                        value={this.state.nationality}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Religion <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="religion"
                                        value={this.state.religion}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Caste <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="caste"
                                        required
                                        value={this.state.caste}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="GENERAL">GENERAL</option>
                                        <option value="ST(H)">ST(H)</option>
                                        <option value="ST(P)">ST(P)</option>
                                        <option value="SC">SC</option>
                                        <option value="OBC">OBC</option>
                                        <option value="MOBC">MOBC</option>
                                    </select>
                                </Grid>

                                {/* <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Quota <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="quota"
                                        required
                                        value={this.state.quota}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select Quota</option>
                                        <option value="None">None</option>
                                        <option value="Sports">Sports</option>
                                        <option value="Cultural">Cultural</option>
                                        <option value="NCC">NCC</option>
                                        <option value="Scouts and Guides">Scouts and Guides</option>
                                        <option value="Other State">Other State</option>
                                        <option value="International">International</option>
                                        <option value="NSS">NSS</option>
                                    </select>
                                    
                                </Grid> */}

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        State <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="state"
                                        required
                                        value={this.state.state}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Blood Group 
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="blood_group"
                                        value={this.state.blood_group}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Present Address <span className="red-alert">*</span>
                                    </Typography>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        rows="4"
                                        required
                                        name="present_address"
                                        value={this.state.present_address}
                                        onChange={this.onChange}
                                    ></textarea>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Permanent Address <span className="red-alert">*</span>
                                        <span>
                                            <Typography
                                                variant="caption"
                                            >
                                                <input type="checkbox" checked={this.state.add_ch} onClick={this.toggleAddCh} /> same as Present address
                                            </Typography>
                                        </span>
                                    </Typography>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        rows="4"
                                        required
                                        name="permanent_address"
                                        value={this.state.permanent_address}
                                        onChange={this.onChange}
                                    ></textarea>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Local Guardians' Name and Contact Number 
                                    </Typography>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        rows="4"
                                        
                                        name="local_g"
                                        value={this.state.local_g}
                                        onChange={this.onChange}
                                    ></textarea>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"

                                    >
                                        Extra Curricular Activity 
                                    </Typography>
                                    <textarea
                                        className="form-control"
                                        type="text"
                                        rows="4"
                                        name="extra_curr"
                                        value={this.state.extra_curr}
                                        onChange={this.onChange}
                                    ></textarea>
                                </Grid>

                            </Grid>
                            

                           
                            

                        </CardContent>

                        <CardActions className={classes.cardActions}>
                            
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                        </Button>
                            
                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormOne)