import React, { useState, useEffect } from 'react'
import { makeStyles, IconButton } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { GetData } from '../../../api/service';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    image: {
        width: '100%'
    }
}))

export default function ShowFile({ file_id }) {
    const [baseData, setBaseData] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPdf, setIsPdf] = useState(false);

    useEffect(() => {
        
        
        if (file_id != '') {
            
            GetData(`/${file_id}/getfilesingle`)
                .then((resp) => {
                    if(resp){
                        setBaseData(resp);
                        setIsPdf(false)
                        setIsLoaded(true);
                    }
                })
        } 

    }, [file_id])

    let classes = useStyles();
    return (
        <React.Fragment>
            <div className={classes.root}>
                {isLoaded ? (
                    <div>
                        {isPdf ? (
                            <div>
                                <a download={`${file_id}.pdf`} href={baseData}>
                                    <IconButton>
                                        <PictureAsPdfIcon />
                                    </IconButton>
                                </a>
                            </div>
                        ) : (<img
                            src={`data:${baseData.mime};base64,${baseData.data}`}
                            className={classes.image}
                            alt="Uploaded Items"
                        />)}
                    </div>
                ) : null}
            </div>
        </React.Fragment>
    )
}