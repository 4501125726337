import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, CardHeader, Avatar, CardActions } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import PreviewForm from '../View/PreviewForm';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '60vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: blue[400]
    }
})

class FormThree extends Component {
//final step

    askConf = () => {

        confirmAlert({
            title: 'Final Step',
            message: 'This is the final step of registration. You can not modify anything after this step. Are you sure to submit ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.gotToPayment()
                },
                {
                    label: 'No'
                 }
            ]
        });


    }
    render() {
        let { classes } = this.props;
        return (
            <div >

                <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                    <CardHeader
                        
                        title={`Form Preview`}
                        subheader={`Please verify the information before final submit.`}
                    />

                    <CardContent>
                        <PreviewForm 
                            user={this.props.user}
                            data={this.props.data}
                            back={this.props.back}
                            askConf={this.askConf}
                        />
                    </CardContent>

                    
                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(FormThree)