import React, { Component } from "react";
import { withStyles, Stepper, Step, StepButton } from "@material-ui/core";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { PostData, GetData, PutData } from "../../../api/service";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import FormThree from "./FormThree";
import EducationalForm from "./EducationalForm";
import Notifications, { notify } from "react-notify-toast";
import PaymentGo from "./PaymentGo";
import ViewForm from "../View/ViewForm";
import FinalSubmitForm from "../View/FinalSubmitForm";
import Loader from "./Loader";
import MyAdmissionLanding from "../Admission/MyAdmissionLanding";
import EducationalFormBSC from "./EducationalFormBSC";
import EducationalFormBCOM from "./EducationalFormBCOM";
import EducationalFormBCA from "./EducationalFormBCA";
import EducationalFormBBA from "./EducationalFormBBA";
import EducationalFormHSA from "./EducationalFormHSA";
import EducationalFormHSS from "./EducationalFormHSS";
import EducationalFormHSC from "./EducationalFormHSC";
import EducationalFormPG from "./EducationalFormPG";
import { Modal, message } from "antd";
import EducationalFormBSCCS from "./EducationalFormBSCCS";
import ProspectusFees from "./ProspectusFees";
import AuthService from "../../../auth_components/js/AuthService";
import EducationalFormBA from "./EducationalFormBA";
import EducationalFormDiploma from "./EducationalFormDiploma";
import EducationalFormBAJMC from "./EducationalFormBAJMC";

const styles = (theme) => ({
  step: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
});

const steps = [
  {
    label: "Registration Fees",
  },
  {
    label: "Basic Details",
  },
  {
    label: "Educational Details",
  },
  {
    label: "Document Upload",
  },
  {
    label: "Preview Form",
  },
  {
    label: "Print",
  },
];

class FormsLanding extends Component {
  state = {
    active: 0,
    email: "",
    course: "",
    form_id: "",
    course_type: "",
    stream: "",
    data: [],
    is_submit: "",
    completed: [],
    edit: false,
    edit_id: "",
    isLoaded: false,
    isSubmitting: false,
    documents_required: [],
    payment_status: 0,
    prospectus_fees_paid: 0,
  };

  componentDidMount() {
    this.checkAdmission();
  }

  onLogout() {
    this.Auth = new AuthService();
    this.Auth.logout();
    this.props.history.replace('/');
}

  checkAdmission = () => {
    GetData(`/getactiveadmission`).then((resp) => {
      let temp = resp.filter(
        (el) =>
          el.course == this.props.user.course &&
          el.admission_type == 0 &&
          el.status == 1
      );

      if (temp.length == 0) {
        Modal.info({
          title: "Information",
          content: "No active admission found..",
          okText: "OK",
          onOk:()=>this.onLogout()
        });
      } else {
        this.setState(
          {
            documents_required: temp[0]["documents_required"],
          },
          () => {
            let data = {
              course: this.props.user.course,
              name: this.props.user.name,
              phone: this.props.user.phone,
              email: this.props.user.email,
              father_name: this.props.user.father_name,
              step: 0,
            };

            PostData(`/0/admissionform/addstudent`, data).then((resp) => {
              this.getInitialData();
            });
          }
        );
      }
    });
  };

  getInitialData = () => {
    GetData(
      `/${this.props.user.email}/${this.props.user.course}/admissionform/getactivestudentsingle`
    ).then((resp) => {
      //console.log(resp)
      if (Array.isArray(resp) && resp.length > 0) {
        this.setState({
          data: resp,
          course: resp[0].course,
          form_id: resp[0].form_id,
          course_type: resp[0].course_type,
          stream: resp[0].stream,
          email: resp[0].email,
          is_submit: resp[0].is_submit,
          is_verified: resp[0].is_verified,
          payment_status: resp[0].payment_status,
          prospectus_fees_paid: resp[0].prospectus_fees_paid,
          edit: true,
          edit_id: resp[0].id,
          active: resp[0].active_step,
          isLoaded: true,
        });
      } else {
        this.setState({
          isLoaded: true,
          active: 0,
        });
      }
    });
  };

  onSetData = (data) => {
    if (!this.state.edit) {
      this.setState({ isSubmitting: true });
      PostData(`/1/admissionform/addstudent`, data)
        .then((resp) => {
          this.setState({ isSubmitting: false });
          if (resp.status === true) {
            this.getInitialData();
          } else {
            confirmAlert({
              title: "Failed!!",
              message: resp.message,
              buttons: [
                {
                  label: "Reload",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          }
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
        });
    } else {
      this.setState({ isSubmitting: true });
      PutData(`/${this.state.edit_id}/1/admissionform/updatestudent`, data)
        .then((resp) => {
          this.setState({ isSubmitting: false });
          if (resp.status === true) {
            this.getInitialData();
          } else {
            confirmAlert({
              title: "Failed!!",
              message: resp.message,
              buttons: [
                {
                  label: "Reload",
                  onClick: () => window.location.reload(),
                },
              ],
            });
          }
        })
        .catch((err) => {
          this.setState({ isSubmitting: false });
        });
    }
  };

  onSetOneData = (data) => {
    this.setState({ isSubmitting: true });
    PutData(
      `/${this.state.edit_id}/${data.step}/admissionform/updatestudent`,
      data
    )
      .then((resp) => {
        this.setState({ isSubmitting: false });
        if (resp.status === true) {
          this.getInitialData();
        } else {
          confirmAlert({
            title: "Failed!!",
            message: resp.message,
            buttons: [
              {
                label: "Reload",
                onClick: () => window.location.reload(),
              },
            ],
          });
        }
      })
      .catch((err) => {
        this.setState({ isSubmitting: false });
      });
  };

  proceedToNext = () => {
    //get the next step
    let next_step = 0;
    if (this.state.active === 0) {
      next_step = 1;
    } else if (this.state.active === 1) {
      next_step = 2;
    } else if (this.state.active === 2) {
      next_step = 3;
    }

    this.setState({
      active: next_step,
    });
  };

  proceedToBack = () => {
    //get the next step
    let next_step = 0;
    if (this.state.active === 0) {
      next_step = 0;
    } else if (this.state.active === 1) {
      next_step = 0;
    } else if (this.state.active === 2) {
      next_step = 1;
    }
    //newly added
    else if (this.state.active === 3) {
      next_step = 2;
    }
    else if (this.state.active === 4) {
        next_step = 3;
      }
      else if (this.state.active === 5) {
        next_step = 4;
      }

    this.setState({
      active: next_step,
    });
  };

  gotToStep = (step) => {
    this.setState({
      active: step,
    });
  };

  gotToPayment = () => {
    GetData(`/${this.state.form_id}/admissionform/gotopayment`).then((resp) => {
      if (resp.status) {
        message.success('Your application form is successfully submitted.')
        this.setState({
          active: resp.step,
        });
      } else {
        confirmAlert({
          title: "Failed!!",
          message: resp.message,
          buttons: [
            {
              label: "Reload",
              onClick: () => window.location.reload(),
            },
          ],
        });
      }
    });
  };

  render() {
    let { classes } = this.props;
    let i = [];

    if (this.state.active === 0 && this.state.prospectus_fees_paid == 0) {
      i = (
        <ProspectusFees
          history={this.props.history}
          user={this.props.user}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
          form_id={this.state.form_id}
        />
      );
    } else if (this.state.active === 1) {
      i = (
        <FormOne
          history={this.props.history}
          user={this.props.user}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "BA") {
      i = (
        <EducationalFormBA
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "BSC") {
      i = (
        <EducationalFormBSC
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "BCOM") {
      i = (
        <EducationalFormBCOM
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "BCA") {
      i = (
        <EducationalFormBCA
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    }else if (this.state.active === 2 && this.state.course === "DIPLOMA") {
      i = (
        <EducationalFormDiploma
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "BAJMC") {
      i = (
        <EducationalFormBAJMC
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    }else if (this.state.active === 2 && this.state.course === "BSCCS") {
      i = (
        <EducationalFormBSCCS
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "BBA") {
      i = (
        <EducationalFormBBA
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "HSA") {
      i = (
        <EducationalFormHSA
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "HSS") {
      i = (
        <EducationalFormHSS
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "HSC") {
      i = (
        <EducationalFormHSC
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 2 && this.state.course === "PG") {
      i = (
        <EducationalFormPG
          history={this.props.history}
          user={this.props.user}
          form_id={this.state.form_id}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          next={this.proceedToNext}
          back={this.proceedToBack}
        />
      );
    } else if (this.state.active === 3) {
      i = (
        <FormTwo
          stream={this.state.stream}
          course={this.state.course}
          course_type={this.state.course_type}
          email={this.state.email}
          history={this.props.history}
          user={this.props.user}
          next={this.proceedToNext}
          back={this.proceedToBack}
          setData={this.onSetOneData.bind(this)}
          data={this.state.data}
          edit={this.state.edit}
          documents_required={this.state.documents_required}
          documents_uploaded={this.state.data[0].documents_uploaded}
        />
      );
    } else if (this.state.active === 4) {
      i = (
        <FormThree
          stream={this.state.stream}
          course={this.state.course}
          email={this.state.email}
          history={this.props.history}
          user={this.props.user}
          next={this.proceedToNext}
          back={this.proceedToBack}
          gotToPayment={this.gotToPayment}
          data={this.state.data}
        />
      );
    } 
    // else if (
    //   this.state.active === 5 &&
    //   this.state.is_verified == 1 &&
    //   this.state.payment_status == 0 && this.state.makePayment == 'YES'
    // ) {
    //   i = (
    //     <PaymentGo
    //       stream={this.state.stream}
    //       course={this.state.course}
    //       email={this.state.email}
    //       history={this.props.history}
    //       user={this.props.user}
    //       next={this.proceedToNext}
    //       back={this.proceedToBack}
    //       form_id={this.state.form_id}
    //     />
    //   );
    // } else if (
    //   this.state.active === 5 &&
    //   this.state.makePayment != 'YES' &&
    //   this.state.payment_status == 0
    // ) {
    //   i = (
    //     <MyAdmissionLanding
    //       user={this.props.user}
    //       data={this.state.data[0]}
    //       history={this.props.history}
    //     />
    //   );
    // } 
    else if (this.state.active === 5) {
      i = (
        <FinalSubmitForm
          stream={this.state.stream}
          course={this.state.course}
          email={this.state.email}
          history={this.props.history}
          user={this.props.user}
          next={this.proceedToNext}
          back={this.proceedToBack}
          form_id={this.state.form_id}
        />
      );
    }

    return (
      <div>
        <Notifications />

        {this.state.isSubmitting && <Loader />}

        <div className={classes.step}>
          <Stepper nonLinear activeStep={this.state.active}>
            {steps.map((el, index) => (
              <Step key={index}>
                <StepButton
                /* onClick={() => this.gotToStep(index)} */
                >
                  {el.label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>

        <br />

        {this.state.isLoaded ? i : null}
      </div>
    );
  }
}

export default withStyles(styles)(FormsLanding);
