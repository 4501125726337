import React, { Component } from 'react'
import { GetData } from '../../../api/service';
import AckReceipt from './AckReceipt';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import AdmissionPayment from './AdmissionPayment';

export default class AckLanding extends Component {

    state = {
        data: {},
        isLoaded: false
    }

    componentDidMount() {
        this.fetchMyAck();
    }

    fetchMyAck = () => {
        GetData(`/${this.props.user.apikey}/fetchmyack-re`)
            .then((resp) => {
                
                if (resp.status == true) {
                    this.setState({
                        data: resp.status_text,
                        isLoaded: true
                    })
                } else {
                    //this.props.history.push(`/re-admission`)
                }

            }).catch(() => {
                this.props.history.push(`/re-admission`)
            })
    }
    render() {
        return (
            <div>

                {!this.state.isLoaded && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '200px'
                    }}
                >
                    <CircularProgress />
                    <br />
                    <Typography variant="caption">
                        Fetching Receipt . . . Please wait
                    </Typography>
                </div>}

                {this.state.isLoaded && <AckReceipt
                    data={this.state.data}
                />}


                <br />

                <Divider />

                <br />

                {this.state.isLoaded && <AdmissionPayment
                history={this.props.history}
                user={this.props.user.user}
                form_id={this.state.data.form_id}
                />}
            </div>
        )
    }
}
