import React, { Component } from 'react'
import PrintReceiptByEmail from '../Components/PrintReceipt/PrintReceiptByEmail'

export default class PrintReceipt extends Component {

    state = {
        email: ""
    }

    componentDidMount(){
        this.setState({
            email: this.props.match.params.email
        })
    }

  render() {
    return (
      <div>


        {this.state.email != "" && <PrintReceiptByEmail
            email={this.state.email}
        />}


      </div>
    )
  }
}
