import React, { Component } from 'react'
import { withStyles, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { blue, purple } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
        padding: '20px'
    },
    card: {
        boxShadow: '1px 1px 3px rgba(0,0,0,0.1)',
        paddingLeft: '10px',
        borderRadius: '0px'
    },
    cardNumber: {
        fontSize: 40,
        fontWeight: '100',
        color: 'inherit'
    },
    gridOwn: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        gridColumnGap: '20px',
        gridRowGap: '20px',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        }
    },
    gridSmallLeft: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridColumnGap: '20px',
        gridRowGap: '20px',
    }

})

class Stats extends Component {
    state = {
        
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>

                <div className={classes.gridOwn}>

                    <div className={classes.gridSmallLeft}>

                        <DetailsCard
                            {...this.props}
                            background={blue[300]}
                            color='white'
                        />

                        <DetailsCard
                            {...this.props}
                            background={``}
                            color='lightgrey'
                        />

                        <DetailsCard
                            {...this.props}
                            background={purple[300]}
                            color='white'
                        />

                        <DetailsCard
                            {...this.props}
                            background={``}
                            color='lightgrey'
                        />

                    </div>

                    <div className={classes.gridSmallRight}>
                        <DetailsCard
                            {...this.props}
                            background={``}
                            color='lightgrey'
                        />
                    </div>


                </div>





            </div>
        )
    }
}

export default withStyles(styles)(Stats);

export const DetailsCard = (props) => {
    let { classes } = props;
    return (
        <div>
            <Card className={classes.card} style={{ backgroundColor: props.background, color: props.color }}>
                <CardContent>
                    <Typography

                        className={classes.cardNumber}
                    >
                        26
                    </Typography>
                    <Typography
                        variant="subheading"
                        color="inherit"
                        className={classes.cardNumberText}
                    >
                        Referrel Downlines
                    </Typography>
                </CardContent>
            </Card>
        </div>
    )
}