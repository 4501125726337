import React from 'react'
import { Table, TableHead, TableRow, TableCell, Typography, TableBody, TableFooter } from '@material-ui/core'
import { blue } from '@material-ui/core/colors'

export default function FeesStructure({ data }) {
    return (
        <div style={{
            backgroundColor: blue[50]
        }}>
            {data !== false &&
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Typography variant="body2">
                                    <b>{data.payment_head}</b>
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell>Particulars</TableCell>
                            <TableCell>Amount</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(data.payment_table) && data.payment_table.map((el, index) =>
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{el.sm_head}</TableCell>
                                <TableCell>{el.sm_amount}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell style={{textAlign: 'right'}} colSpan={2}>
                                <b>Total</b>
                            </TableCell>
                            <TableCell>
                                <b>{data.payment_amount}</b>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            }
        </div>
    )
}
