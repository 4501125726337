import React, { Component } from "react";
import {
  withStyles,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  InputLabel,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
  Paper,
} from "@material-ui/core";
import PreviousExamination from "./PreviousExamination";
import BestOfThree from "./BestOfThree";
import SubjectsCombo from "./SubjectsCombo";
import { blue, lightGreen, orange, green } from "@material-ui/core/colors";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AlertDiv from "./AlertDiv";
import { GetData } from "../../../api/service";
import Grade from "@material-ui/icons/Grade";
import Sel from "react-select";
import MultiSubject from "./MultiSubject";
import LastMarks from "./LastMarks";
import {Modal} from 'antd';

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});

class EducationalFormBSCCS extends Component {
  state = {
    course: "BSCCS",
    stream: "",
    course_type: "",
    semester: 1,
    email: this.props.user.email,
    exam_passed: [],
    best_subjects: [],
    last_marks: [],
    total_marks: "",
    marks_obtained: "",
    percentage: "",

    errors: [],
    major_1: null,
    major_2: null,
    major_3: null,
    mil: null,
    generic_1_1: null,
    generic_1_2: null,
    major_list: [],
    f_major_list: [],
    generic_list:[],
    mdc_list:[],
    mil_list:[],
    vac_list:[],
    isLoaded: false,
    isDisabled: false,

    has_gap: "",
    gap_admission: "",
    gap_course: "",
    gap_institute: "",
    gap_reason: "",
    gap_roll_no: "",
    unique_id: "",
    subjects_choosen:[]
  };

  componentDidMount() {
    let data = this.props.data;

    if (Array.isArray(data) && data.length > 0) {
      this.setState(
        {
          ...data[0],
          
        },
        () => {

          let lm = [];

          if (
            Array.isArray(data[0].last_marks) &&
            data[0].last_marks.length == 0
          ) {
           
              lm = [
                { subject: "", total_marks: "100", marks_secured: "",readOnly:false },
                { subject: "", total_marks: "100", marks_secured: "",readOnly:false },
                { subject: "", total_marks: "100", marks_secured: "",readOnly:false  },
                { subject: "", total_marks: "100", marks_secured: "",readOnly:false  },
                { subject: "", total_marks: "100", marks_secured: "",readOnly:false  },
              ];

            }

                let subjects_choosen = [];
                let major_1 = null,major_2 = null,major_3 = null,mil=null,generic_1_1 = null,generic_1_2 = null;
               

            if (
              Array.isArray(data[0].subjects_choosen) &&
              data[0].subjects_choosen.length > 0
            ) {
              subjects_choosen = data[0].subjects_choosen;
              
              major_1 = subjects_choosen.find((el)=>el.type === 'major_1')?.subject || null;
              major_2 = subjects_choosen.find((el)=>el.type === 'major_2')?.subject || null;
              major_3 = subjects_choosen.find((el)=>el.type === 'major_3')?.subject || null;
              mil = subjects_choosen.find((el)=>el.type === 'mil')?.subject || null;
              generic_1_1 = subjects_choosen.find((el)=>el.type === 'generic_1_1')?.subject || null;
              generic_1_2 = subjects_choosen.find((el)=>el.type === 'generic_1_2')?.subject || null;
             

            }
                this.setState(
                  {
                    last_marks: lm,
                    subjects_choosen,
                    major_1,
                    major_2,
                    major_3,
                    mil,
                    generic_1_1,
                    generic_1_2,
                  },
                  () => {
                    this.__getData();

                  }
                );
          
        }
      );
    }
  }
  __getData = () => {
   

    GetData(`/${this.state.course}/getadmissionsubject`).then((resp) => {
      //console.log("EducationalForm -> __getData -> resp", resp);
      //console.log(resp)
     
     
        let subjects = resp; // all subjects

        let major_1 = null,major_2 = null,mil=null,vac_list=[],mdc_list=[],mil_list=[], major_list = [],generic_1_2 = null;

         major_1 = subjects.find((el) => el.sub_code === "CS-C-FYUGP");
         major_list = subjects.filter((el) => el.sub_code === "PHY-C-FYUGP" || el.sub_code == "CHM-C-FYUGP" || el.sub_code == "MATH-C-FYUGP" || el.sub_code == "STS-C-FYUGP");
         mil_list = subjects.filter((el) => (el.sub_code === "ASM-AEC" || el.sub_code == 'BEN-AEC' || el.sub_code == 'HIN-AEC' || el.sub_code == 'BODO-AEC' || el.sub_code == 'SNS-AEC' || el.sub_code == 'ENG-F-AEC' || el.sub_code == 'ALTE-AEC'));
         mdc_list = subjects.filter((el) => el.sub_code === "MDH-MDC-FYUGP" || el.sub_code === "MDC-MDC-FYUGP");
         generic_1_2 = subjects.find((el) => el.sub_code === "ENV-AE" && el.is_FYUGP == 1);

        this.setState(
          {
            major_1,
            major_list,
            mil_list,
            mdc_list,
            f_major_list: major_list,
       
            generic_1_2,
            isLoaded: true,
          })
      
    });
  };


  onNewChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };


  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

  };

  

  onSubChange = (name, e) => {
    this.setState(
      {
        [name]: e,
      }, () => this.setSubjectFilter())
  };

  handleValidation = () => {
    let {
      major_1,
      major_2,
      major_3,
      mil,
      generic_1_1,
      generic_1_2,
      exam_passed,
      best_subjects,
      last_marks,
      has_gap,
      gap_admission,
      gap_course,
      gap_institute,
      gap_reason,
      gap_roll_no,
      errors,
    } = this.state;
    let isValid = true;
    let subjects_choosen = [];
    
        if (major_1 !== null && major_1 !== undefined) {
          if (Object.entries(major_1).length === 0) {
            isValid = false;

            errors["major_1"] = "Please Select Compulsory 1";
          } else {
            errors["major_1"] = "";

            subjects_choosen.push({
              type:'major_1',
              subject:major_1
            });
          }
        } else {
         
          isValid = false;
          errors["major_1"] = "Please Select Compulsory 1";
        }

        if (major_2 !== null && major_2 !== undefined) {
          if (Object.entries(major_2).length === 0) {
            isValid = false;

            errors["major_2"] = "Please Select Compulsory 1";
          } else {
            errors["major_2"] = "";

            subjects_choosen.push({
              type:'major_2',
              subject:major_2
            });
          }
        } else {
         
          isValid = false;
          errors["major_2"] = "Please Select Compulsory 1";
        }

        if (major_3 !== null && major_3 !== undefined) {
          if (Object.entries(major_3).length === 0) {
            isValid = false;

            errors["major_3"] = "Please Select Compulsory 1";
          } else {
            errors["major_3"] = "";

            subjects_choosen.push({
              type:'major_3',
              subject:major_3
            });
          }
        } else {
         
          isValid = false;
          errors["major_3"] = "Please Select Compulsory 1";
        }


        if (mil !== null && mil !== undefined) {
          if (Object.entries(mil).length === 0) {
            isValid = false;

            errors["mil"] = "Please Select AEC";
          } else {
            errors["mil"] = "";

            subjects_choosen.push({
              type:'mil',
              subject:mil
            });
          }
        } else {
         
          isValid = false;
          errors["mil"] = "Please Select AEC";
        }


        if (generic_1_1 !== null && generic_1_1 !== undefined) {
          if (Object.entries(generic_1_1).length === 0) {
            isValid = false;

            errors["generic_1_1"] = "Please Select";
          } else {
            errors["generic_1_1"] = "";

            subjects_choosen.push({
              type:'generic_1_1',
              subject:generic_1_1
            });
          }
        } else {
         
          isValid = false;
          errors["generic_1_1"] = "Please Select ";
        }


        if (generic_1_2 !== null && generic_1_2 !== undefined) {
          if (Object.entries(generic_1_2).length === 0) {
            isValid = false;

            errors["generic_1_2"] = "Please Select";
          } else {
            errors["generic_1_2"] = "";

            subjects_choosen.push({
              type:'generic_1_2',
              subject:generic_1_2
            });
          }
        } else {
         
          isValid = false;
          errors["generic_1_2"] = "Please Select";
        }

    if (Array.isArray(exam_passed) && exam_passed.length === 0) {
      isValid = false;
      errors["exam_passed"] = "Please fill exam passed.";
    } else {
      errors["exam_passed"] = "";
    }

    if (Array.isArray(last_marks) && last_marks.length < 5) {
      isValid = false;
      errors["last_marks"] = "Please fill last examination marks.";
    } else {
      errors["last_marks"] = "";
    }

    if (Array.isArray(best_subjects) && best_subjects.length < 4) {
      isValid = false;
      errors["best_subjects"] = "Please fill last examination marks.";
    } else {
      errors["best_subjects"] = "";
    }

    if (Array.isArray(subjects_choosen) && subjects_choosen.length === 0) {
      isValid = false;
      errors["subjects_choosen"] = "Please select subjects.";
    } else {
      errors["subjects_choosen"] = "";
    }

    
      if(has_gap === null || has_gap === ''){
        isValid = false;
        errors["has_gap"] = "Please select this field";
      } else {
        errors["has_gap"] = "";
      }

      if(has_gap === "YES"){
        if(gap_admission === null || gap_admission === ''){
          isValid = false;
          errors["gap_admission"] = "Please fill gap admission";
        } else {
          errors["gap_admission"] = "";
        }

        if(gap_admission === "YES"){

        if(gap_course === null || gap_course === ''){
          isValid = false;
          errors["gap_course"] = "Please fill gap course";
        } else {
          errors["gap_course"] = "";
        }

        if(gap_institute === null || gap_institute === ''){
          isValid = false;
          errors["gap_institute"] = "Please fill gap institute";
        } else {
          errors["gap_institute"] = "";
        }

        if(gap_reason === null || gap_reason === ''){
          isValid = false;
          errors["gap_reason"] = "Please fill gap reason";
        } else {
          errors["gap_reason"] = "";
        }

        if(gap_roll_no === null || gap_roll_no === ''){
          isValid = false;
          errors["gap_roll_no"] = "Please fill gap roll no";
        } else {
          errors["gap_roll_no"] = "";
        }

        }
        else {
            errors["gap_course"] = "";
            errors["gap_institute"] = "";
            errors["gap_reason"] = "";
            errors["gap_roll_no"] = "";
        }

      }
      else {
        errors["gap_admission"] = "";
        errors["gap_course"] = "";
        errors["gap_institute"] = "";
        errors["gap_reason"] = "";
        errors["gap_roll_no"] = "";
      }

   
    //console.log(errors);
    if(!isValid){
      Modal.error({
        title:'Error Message',
        content:<ul>
         { Object.values(errors)?.map((el,idx)=>el !=='' && <li key={idx}>{el}</li>)}
        </ul>
      })
    }

    this.setState({
      errors,
      subjects_choosen
    });

    return {isValid,subjects_choosen};
  };

  onSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state)
    let data = this.handleValidation();
    //console.log("🚀 ~ file: EducationalFormBA.js:625 ~ EducationalFormBA ~ data:", data)
    if (data.isValid) {
      let d = {
        email: this.state.email,
        course: this.state.course,
        course_type: this.state.course_type,
        unique_id:this.state.unique_id,
        subjects_choosen:data.subjects_choosen,
        total_marks: this.state.total_marks,
        marks_obtained: this.state.marks_obtained,
        percentage: this.state.percentage,
        exam_passed: this.state.exam_passed,
        best_subjects: this.state.best_subjects,
        last_marks: this.state.last_marks,
        has_gap: this.state.has_gap,
        gap_admission: this.state.gap_admission,
        gap_course: this.state.gap_course,
        gap_institute: this.state.gap_institute,
        gap_reason: this.state.gap_reason,
        gap_roll_no: this.state.gap_roll_no,
        step:2
      };
      //console.log(d)

      this.props.setData(d);
    }
  };

  sentTotalData = (data) => {
    //console.log(data)
    this.setState({
      last_marks:data.last_marks,
      best_subjects:data.best_subjects,
      total_marks: data.over_all,
      marks_obtained: data.obtained,
      percentage: data.percentage,
    });
  };

  setSubjectFilter = () => {
    let r2 = [];
    let major_list = this.state.major_list;
    let major_1 = this.state.major_1;
    let major_2 = this.state.major_2;
    let major_3 = this.state.major_3;


    let selectedSubject = [];
    // selected major 
    if (major_1 && major_1 != null) {
        selectedSubject.push(major_1.value)

    }

    if (major_2 && major_2 != null) {
        selectedSubject.push(major_2.value)

    }

    if (major_3 && major_3 != null) {
        selectedSubject.push(major_3.value)


    }


    // other conditions




    major_list.map(el => {
        if (!selectedSubject.includes(el.value)) {



            r2.push(el)
        }
    })




    this.setState({
        f_major_list: r2
    })







}


  showMePapers = (subject) => {
    //to show papers associated to the subject
    //console.log(subject)

    return null
    if (subject !== null && subject !== undefined) {
      if (Object.keys(subject).length > 0) {
        return (
          <div>
            <AlertDiv back={orange[100]} border={orange[400]}>
              <div style={{ fontSize: 13 }}>Available Papers</div>

              <ul>
                {Array.isArray(subject.papers) &&
                  subject.papers?.map((el, index) =>
                    el.semester == 1 ? (
                      <li style={{ fontSize: 12 }} key={index}>
                        {el.label}
                      </li>
                    ) : null
                  )}
              </ul>
            </AlertDiv>
          </div>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }

    return null;
  };
  render() {
    let { classes } = this.props;
    

    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h5">Educational Details</Typography>
          <Typography>
            All <span className="red-alert">*</span> marked fields are mandatory
          </Typography>

          <br />


          <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Unique ID (Generate from DHE Portal.Click the below link.){" "}
                    <a
                      style={{ color: "blue" }}
                      target="__blank"
                      href={`https://dheonlineadmission.amtron.in/sp/`}
                    >
                      https://dheonlineadmission.amtron.in/sp/
                    </a>{" "}
                    <span className="red-alert">*</span>
                  </Typography>

                  <input
                    className="form-control"
                    type="text"
                    required
                    name="unique_id"
                    value={this.state.unique_id}
                    onChange={this.onChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
                  <br />
          
          <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>
              
            <div>
                <AlertDiv back={orange[100]} border={orange[400]}>
                  <Typography variant="subtitle2">
                    <Grade style={{ fontSize: 10 }} /> Important points to be
                    noted before you choose the subjects:
                  </Typography>

                  <ul>
                    <li>
                      <Typography>
                        Fill up the subjects according to your preferance
                      </Typography>
                    </li>
                    <li>
                      <Typography>
                        Please go through the new subject guideline available in
                        our website for more information
                      </Typography>
                    </li>
                   
                    <li>
                      <Typography style={{ color: "red" }} variant="body2">
                        For any confusion please refer prospectus.
                      </Typography>
                    </li>
                    <li>
                      <Typography style={{ color: "red", fontWeight: 700 }} variant="body2">
                        Skill Enhancement Paper will be allotted at the time of Admission
                      </Typography>
                    </li>
                  </ul>
                </AlertDiv>
              </div>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Combination of subjects Sought for {this.state.course} -{" "}
                     1st Semester{" "}
                    <span className="red-alert">*</span>
                  </Typography>
                </Grid>

               
                  <Grid item xs={12} sm={12} lg={4} md={4}>
                    <Paper style={{ border: `solid 1px ${blue[700]}` }}>
                      <CardHeader title={`Core Subjects`} />
                      <Divider />
                      <div style={{ padding: "10px" }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["major_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["major_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                              Core - 1 {" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                            //   options={this.state.major_list}
                              value={this.state.major_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={false}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(this, "major_1")}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["major_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["major_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                            Core - 2 {" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                            options={this.state.f_major_list}
                              value={this.state.major_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={false}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(this, "major_2")}
                            />
                          </Grid>

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["major_3"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["major_3"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                            Core - 3 {" "}
                              <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                            options={this.state.f_major_list}
                              value={this.state.major_3}
                              isLoading={!this.state.isLoaded}
                              isClearable={false}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(this, "major_3")}
                            />
                          </Grid>


                        </Grid>
                      </div>
                    </Paper>
                  </Grid>


                  <Grid item xs={12} sm={12} lg={4} md={4}>
                  <Paper style={{ border: `solid 1px ${orange[700]}` }}>
                    <CardHeader
                      title={`Common Course`}
                    />
                    <Divider />
                    <div style={{ padding: "10px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} lg={12} md={12}>
                          {this.state.errors["mil"] !== "" ? (
                            <div>
                              <Typography
                                variant="caption"
                                style={{ color: "red" }}
                              >
                                {this.state.errors["mil"]}
                              </Typography>
                            </div>
                          ) : null}

                          <Typography variant="subtitle2">
                            AECC {" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <Sel
                            options={this.state.mil_list}
                            value={this.state.mil}
                            isLoading={!this.state.isLoaded}
                            isClearable={false}
                            isSearchable={true}
                            onChange={this.onSubChange.bind(this, "mil")}
                          />

                          <br />
                          {this.state.errors["generic_1_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_1_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                               MDC {" "}
                               <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.mdc_list}
                              value={this.state.generic_1_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_1_1"
                              )}
                            />

                            <br />

                            {this.state.errors["generic_1_2"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_1_2"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                               VAC {" "}
                               <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              //options={this.state.vac_list}
                              value={this.state.generic_1_2}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_1_2"
                              )}
                            />
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
               


                    {/* <Grid item xs={12} sm={12} lg={4} md={4}>
                  <Paper style={{ border: `solid 1px ${orange[700]}` }}>
                    <CardHeader
                      title={`Generic Elective`}
                    />
                    <Divider />
                    <div style={{ padding: "10px" }}>
                      <Grid container spacing={2}>

                      

                          <Grid item xs={12} sm={12} lg={12} md={12}>
                            {this.state.errors["generic_1_1"] !== "" ? (
                              <div>
                                <Typography
                                  variant="caption"
                                  style={{ color: "red" }}
                                >
                                  {this.state.errors["generic_1_1"]}
                                </Typography>
                              </div>
                            ) : null}

                            <Typography variant="subtitle2">
                               Generic Elective {" "}
                               <span className="red-alert">*</span>
                            </Typography>
                            <Sel
                              options={this.state.generic_list}
                              value={this.state.generic_1_1}
                              isLoading={!this.state.isLoaded}
                              isClearable={true}
                              isSearchable={true}
                              onChange={this.onSubChange.bind(
                                this,
                                "generic_1_1"
                              )}
                            />

                          </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid> */}
                
              </Grid>

              <br />
              <br />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    H.S/12th Examination Details{" "}
                    <span className="red-alert">*</span>
                  </Typography>

                  {this.state.errors["exam_passed"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["exam_passed"]}
                      </Typography>
                    </div>
                  ) : null}

                  <PreviousExamination
                    onNewChange={this.onNewChange.bind(this, "exam_passed")}
                    data={this.state.exam_passed}
                  />
                </Grid>

                
                <Grid item xs={12} sm={12} lg={12} md={12}>
                  {this.state.errors["last_marks"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["last_marks"]}
                      </Typography>
                    </div>
                  ) : null}
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Marks Obtained in last examination passed{" "}
                    <span className="red-alert">*</span>
                  </Typography>
                 
                    <LastMarks
                     
                      sentTotalData={this.sentTotalData.bind(this)}
                     
                      last_marks={this.state.last_marks}
                      
                    />
                  

                </Grid>
             

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Do you have any gap ? <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="has_gap"
                    required
                    value={this.state.has_gap}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </Grid>

                {this.state.has_gap === "YES" && (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                      >
                        Have you taken admission in any institute/college ?{" "}
                        <span className="red-alert">*</span>
                      </Typography>
                      <select
                        className="form-control"
                        type="text"
                        name="gap_admission"
                        required
                        value={this.state.gap_admission}
                        onChange={this.onChange}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </Grid>

                    {this.state.gap_admission === "YES" && (
                      <React.Fragment>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Name of the course{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_course"
                            value={this.state.gap_course}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Name of the Institute{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_institute"
                            value={this.state.gap_institute}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Enrollment No/Registration No{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_roll_no"
                            value={this.state.gap_roll_no}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                      >
                        Reason of Gap <span className="red-alert">*</span>
                      </Typography>
                      <textarea
                        className="form-control"
                        type="text"
                        name="gap_reason"
                        value={this.state.gap_reason}
                        onChange={this.onChange}
                        required
                      ></textarea>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <AlertDiv back={orange[100]} border={orange[400]}>
                        <strong>Warning !!</strong>

                        <Typography>
                          In case, if any false information detected about the
                          gap period, the application/admission will be
                          rejected.
                        </Typography>
                      </AlertDiv>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>

              

              
            </CardContent>

            <CardActions className={classes.cardActions}>
            <Button variant="contained" type="button" onClick={this.props.back}>
                Previous
              </Button>&nbsp;
              <Button
                variant="contained"
                className={classes.buttonSubmit}
                type="submit"
              >
                Save &amp; Proceed to next step
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(EducationalFormBSCCS);
