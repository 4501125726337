import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { withStyles, Card, CardHeader, CardContent, TextField, CardActionArea, Button, Typography, FormControl, Fade } from '@material-ui/core';
import Send from '@material-ui/icons/Send'
import {Select} from 'antd';
import { GetData } from '../../api/service';
import { confirmAlert } from 'react-confirm-alert';
const {Option} = Select;

const styles = theme => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white'

    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    resize: {
        fontSize: 18
    },
    resizeLable: {
        fontSize: 15,
        fontWeight: 400
    },
    forgotCard: {
        width: 400,
        padding: '20px',
        boxShadow: 'none'
    },
    loginText: {
        fontSize: 25,
        fontWeight: 200
    },
    inputText: {
        padding: '15px',
        paddingLeft: '25px',
        border: 'solid thin #383838',
        borderTopLeftRadius: '30px',
        borderTopRightRadius: '30px',
        borderBottomLeftRadius: '30px',
        borderBottomRightRadius: '30px',
        backgroundColor: '#F9F9F9',
        marginBottom: '20px',
        outline: 'none'
    },
})

class FormOne extends Component {
    constructor(props) {
        super(props);

        this.state = {
            course:'',
            email:'',
            phone: '',
            date_of_birth: '',
            type: 'NEW_ADMISSION',
            active_admission:[]

        }

    }

    componentDidMount(){
        GetData(`/getallowadmission`).then((resp)=>{
            this.setState({
                active_admission:resp
            })
        })
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
       
        if(this.state.email === '' || this.state.phone === '' || this.state.type === '' || this.state.course === ''){

            confirmAlert({
                title: 'Error',
                message: 'Please fill all the required fields.',
                buttons: [
                    {
                        label: 'Okay'
                    }
                ]
            });

        }
        else {
        this.props.setEmail(this.state.email,this.state.phone, this.state.date_of_birth, this.state.type,this.state.course);

        }

    }

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.container}>
                <Fade in={true} timeout={{ enter: 1500 }}>
                <form onSubmit={this.onSubmit}>
                    <Card className={classes.forgotCard}>
                        
                        <CardContent>

                            <div align="center">
                                <Typography
                                    className={classes.loginText}
                                    gutterBottom
                                >
                                    Forgot Password ?
                                </Typography>
                            </div>

                            <FormControl fullWidth required>
                            <label>
                                <Typography variant='h6'>Course *</Typography>
                            </label>
                            <Select
                            showSearch
                            allowClear
                            placeholder="Select Course"
                            optionFilterProp="children"
                            onChange={(value)=>this.setState({
                                course:!!value?value:''
                            })}
                            dropdownStyle={{
                                zIndex:1301
                            }}
                            value={this.state.course}
                            name="course"
                            style={{
                                width:'100%'
                            }}
                            required
                            >
                                <Option value="" disabled>
                                <em> Select </em>
                                </Option>
                            {this.state.active_admission.map((el,index)=>
                            <Option key={index} value={el.course}>{el.label}</Option>
                            )}
                            
                            </Select>
                            </FormControl>
                            <br />
                            <br />

                            {/* <FormControl fullWidth required>
                            <label>Select Type</label>
                  <select
                    required
                    className={classes.inputText}
                    name="type"
                    value={this.state.type}
                    onChange={this.handleChange}

                  >
                    <option value="">Select</option>
                    
                    <option value="RE_ADMISSION">Re Admission</option>
                    <option value="NEW_ADMISSION">New Admission</option>
                  </select>

                </FormControl> */}

                            <FormControl fullWidth required>
                            <label>Email Id</label>
                                <input
                                    required
                                    className={classes.inputText}
                                    name="email"
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    placeholder="Enter your Registered Email Id"
                                />

                            </FormControl>

                            <FormControl fullWidth required>
                            <label>Phone</label>
                                <input
                                    required
                                    className={classes.inputText}
                                    name="phone"
                                    value={this.state.phone}
                                    onChange={this.handleChange}
                                    placeholder="Enter your Registered phone"
                                />

                            </FormControl>

                            {/* <FormControl fullWidth required>
                                <label>Date of Birth</label>
                                <input
                                    type="date"
                                    required
                                    className={classes.inputText}
                                    name="date_of_birth"
                                    value={this.state.date_of_birth}
                                    onChange={this.handleChange}
                                    placeholder="Date of Birth"
                                />

                            </FormControl> */}

                            <Typography variant="subtitle2" style={{ color: 'red' }}>{this.props.error}</Typography>

                            <div align="center">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="large"
                                    className={classes.button}
                                >
                                    Next
                                        </Button>
                            </div>

                        </CardContent>

                        <br />

                        <div align="center">
                            <Typography
                                variant="caption"
                            >
                                Go to home ? <b><Link to="/">Click Here</Link></b>
                            </Typography>

                        </div>

                        
                    </Card>
                </form>
                </Fade>

                
            </div>
        )
    }
}


export default withStyles(styles)(FormOne)