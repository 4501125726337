import React from "react";
import queryString from "query-string";
import "./css/greivance.css";
import { Container, Typography, Button } from "@material-ui/core";
import AlertDiv from "../Components/Registration/Forms/AlertDiv";
import CallToAction from "@material-ui/icons/CallToAction";
import { blue, orange, grey, green, red } from "@material-ui/core/colors";
import { PostData } from "../api/service";
import { message } from "antd";

export default function CertificateFeesPayment(props) {
  const [certificateRequestId, setCertificateRequestId] = React.useState("");
  const [email, setEmail] = React.useState("");

  const [paymentData, setPaymentData] = React.useState(null);
  const [isPaymentLoaded, setIsPaymentLoaded] = React.useState(false);

  function loadData() {
    let search = props.history.location.search;

    let searchData = queryString.parse(search);

    if (searchData) {
      setCertificateRequestId(searchData.certificate_request_id);
      setEmail(searchData.email);
    }
  }

  React.useEffect(() => {
    loadData();
  }, []);

  function loadPaymentData() {
    // check has payment done first

    PostData(`/get-certificate-payment-string`, {
      certificate_request_id: certificateRequestId,
      email: email,
    }).then((resp) => {
      //

      if (resp.status == true) {
        if (resp.redirect == true) {
          // redirect to site
          window.location.href = `/paymentresponse-certificate?certificate_request_id=${resp.certificate_request_id}`
        } else {
          if (
            resp.data.hasOwnProperty("action") &&
            resp.data.hasOwnProperty("encRequest") &&
            resp.data.hasOwnProperty("access_code")
          ) {
            setPaymentData(resp.data);
            setIsPaymentLoaded(true);
          }
        }
      } else {
        // not found
        message.error(resp.status_text);
      }
    });
  }

  React.useEffect(() => {
    if (certificateRequestId != "" && email != "") {
      loadPaymentData();
    }
  }, [certificateRequestId, email]);

  return (
    <div>
      <Container>
        <br />
        <br />
        <div className="container">
          <div className="reg-form">
            <div className="top-bar"></div>
            <div className="form-contents">
              <h3
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <div style={{ marginLeft: 10, fontWeight: 700 }}>
                  Fees Payment for Certificate Request
                  <br />
                  <div style={{ fontSize: 16 }}>Pragjyotish College</div>
                </div>
              </h3>
              <hr />
              <AlertDiv back={orange[100]} border={orange[400]}>
                <Typography variant="body2">
                  You will now redirected to the payment page. Please note the
                  following points:
                </Typography>
                <ul>
                  <li>
                    <Typography>
                      Follow the payment procedure very carefully
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      After a successful payment, an online fees payment receipt
                      will be generated
                    </Typography>
                  </li>
                  <li>
                    <Typography>
                      You can print and save the receipt after the payment
                    </Typography>
                  </li>

                  <li>
                    <Typography>
                      Proceed the <b>"PROCEED TO PAY"</b> Button to continue
                    </Typography>
                  </li>
                </ul>
              </AlertDiv>

              {isPaymentLoaded && (
                <form method="post" action={paymentData.action}>
                  <input
                    type="hidden"
                    name="encRequest"
                    value={paymentData.encRequest}
                  />
                  <input
                    type="hidden"
                    name="access_code"
                    value={paymentData.access_code}
                  />
                  <Button
                    variant="contained"
                    size="sm"
                    color="primary"
                    type="submit"
                  >
                    <CallToAction /> &nbsp; Proceed To Pay &#8377;{" "}
                    {paymentData.amount}
                  </Button>
                </form>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
