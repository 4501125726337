import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, Divider, CardActions, Button, FormGroup, Checkbox, FormControlLabel } from '@material-ui/core';
import PreviousExamination from './PreviousExamination';
import BestOfThree from './BestOfThree';
import SubjectsCombo from './SubjectsCombo';
import { blue, orange } from '@material-ui/core/colors';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Grade from "@material-ui/icons/Grade";

import allStates from '../../../helper/IndianStates.json'
import AlertDiv from './AlertDiv';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    }
})


const occupations = [
    "Govt. Service",
    "Non-Govt. Service",
    "Farmer",
    "Others"
]

const activities = [
    {
        label: 'NCC',
        value: 'NCC',
        status: false
    },
    {
        label: 'NSS',
        value: 'NSS',
        status: false
    },
    {
        label: 'STUDENT WELFARE',
        value: 'STUDENT_WELFARE',
        status: false
    },
    {
        label: 'YOGA MEDITATION',
        value: 'YOGA_MEDITATION',
        status: false
    },
    {
        label: 'EXTENSION ACTIVITIES',
        value: 'EXTENSION_ACTIVITIES',
        status: false
    },
    {
        label: 'WOMEN CELL',
        value: 'WOMEN_CELL',
        status: false
    },
    {
        label: 'ECO CLUB',
        value: 'ECO_CLUB',
        status: false
    },
    {
        label: 'DISASTER MANAGEMENT',
        value: 'DISASTER_MANAGEMENT',
        status: false
    },
    {
        label: 'STUDY CIRCLE',
        value: 'STUDY_CIRCLE',
        status: false
    },
    {
        label: 'ANTI  RAGGING  CELL',
        value: 'ANTI_RAGGING_CELL',
        status: false
    },
    {
        label: 'RED RIBBON CLUB',
        value: 'RED_RIBBON_CLUB',
        status: false
    }
]

class FormOne extends Component {

    state = {
        name: this.props.user.name,
        admission_type: '0',
        course: this.props.user.course,
        stream: '',
        phone: this.props.user.phone,
        email: this.props.user.email,
        gender: '',
        maratial_status: '',
        date_of_birth: '',
        nationality: '',
        religion: '',
        samarth_no: '',
        caste: '',
        quota: '',
        state: '',
        is_bpl: '',
        has_prc: '',
        has_aadhar_card: '',
        aadhar_card_checked: '',
        blood_group: '',
        present_address: [],
        permanent_address: [],
        local_address: [],
        local_g: '',
        extra_curr: '',
        account_holder: '',
        account_bank: '',
        account_no: '',
        account_branch: '',
        account_ifsc: '',
        account_micr: '',
        father_name: '',
        father_email: '',
        mother_name: '',
        father_qualification: '',
        mother_qualification: '',
        father_profession: '',
        mother_profession: '',
        father_phone: '',
        mother_phone: '',
        annual_income: '',
        is_pwd: '',
        pan_number: '',
        aadhar_number: '',
        is_ews: '',
        sports_quota: '',
        has_hostel: '',
        pre_village: '', pre_po: '', pre_ps: '', pre_dist: '', pre_state: '', pre_pin: '', pre_contact: this.props.user.phone,
        per_village: '', per_po: '', per_ps: '', per_dist: '', per_state: '', per_pin: '', per_contact: this.props.user.phone, per_lac: '',
        g_village: '', g_po: '', g_ps: '', g_dist: '', g_state: '', g_pin: '', g_contact: '',
        activities: activities
    }

    componentDidMount() {

        let data = this.props.data;
        if (Array.isArray(data) && data.length > 0) {
            this.setState({
                ...data[0],
                ...data[0].present_address,
                ...data[0].permanent_address,
                ...data[0].local_address

            })
        }

    }

    handleChange = (event, label) => {
        let activities1 = this.state.activities;
        //console.log(event.target.name)
        let index = activities1.findIndex((el) => el.value == event.target.name);
        //console.log("🚀 ~ file: FormOne.js ~ line 180 ~ FormOne ~ index", index)
        if (index != -1) {
            activities1[index]['status'] = event.target.checked;

            this.setState({
                activities: activities1

            }, () => {
                //console.log(this.state.activities)
            })
        } else {

            // insert that activity
            activities1.push({
                label: label,
                value: event.target.name,
                status: true
            })
            this.setState({
                activities: activities1

            }, () => {
                //console.log(this.state.activities)
            })


        }
    };


    checkActivityValue = (val) => {

        let activities1 = this.state.activities;

        let index = activities1.findIndex((el) => el.value == val);

        if (index != -1) {
            return activities1[index]['status'];
        } else {
            return false;
        }
    }

    toggleAddCh = () => {

        this.setState({
            add_ch: !this.state.add_ch
        }, () => {
            if (this.state.add_ch) {

                this.setState({
                    per_village: this.state.pre_village,
                    per_po: this.state.pre_po,
                    per_ps: this.state.pre_ps,
                    per_dist: this.state.pre_dist,
                    per_state: this.state.pre_state,
                    per_pin: this.state.pre_pin,
                    per_contact: this.state.pre_contact,

                })
            } else {
                this.setState({
                    permanent_address: []
                })
            }
        })
    }

    componentDidUpdate(prevProps) {

        if (this.props !== prevProps) {

            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                this.setState({
                    ...data[0]
                })
            }
        }

    }


    onNewChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }


    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onSubmit = (e) => {
        e.preventDefault();
        let present_address = {
            pre_village: this.state.pre_village,
            pre_po: this.state.pre_po,
            pre_ps: this.state.pre_ps,
            pre_dist: this.state.pre_dist,
            pre_state: this.state.pre_state,
            pre_pin: this.state.pre_pin,
            pre_contact: this.state.pre_contact,
        }
        let permanent_address = {
            per_village: this.state.per_village,
            per_po: this.state.per_po,
            per_ps: this.state.per_ps,
            per_dist: this.state.per_dist,
            per_state: this.state.per_state,
            per_pin: this.state.per_pin,
            per_contact: this.state.per_contact,
            per_lac: this.state.per_lac,

        }
        let local_address = {
            g_village: this.state.g_village,
            g_po: this.state.g_po,
            g_ps: this.state.g_ps,
            g_dist: this.state.g_dist,
            g_state: this.state.g_state,
            g_pin: this.state.g_pin,
            g_contact: this.state.g_contact,
        }

        let count = 0;
        this.state.activities.forEach((el) => {
            if (el.status)
                count++;
        })
        //if (count >= 2 && this.state.course !=='' && this.state.email !=='') {

        this.setState({
            present_address,
            permanent_address,
            local_address,
            semester: 1,
            admission_type: 0,
            step: 1
        }, () => { this.props.setData(this.state) })

        //}
        // else {
        //     confirmAlert({
        //         title: 'Activity error',
        //         message: 'Please select at least two activities.',
        //         buttons: [
        //             {
        //                 label: 'OK',
        //                 onClick: () => { }
        //             }
        //         ]
        //     })
        // }


    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h5"
                    >
                        Application Form
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>

                    <br />

                    <AlertDiv back={orange[100]} border={orange[400]}>
                  <Typography variant="subtitle2">
                    <Grade style={{ fontSize: 10 }} /> You can now proceed for admission. You can complete the steps after the admission payment is done
                  </Typography>

                 <Typography>Your form id is: <strong>{this.state.form_id}</strong></Typography>
                </AlertDiv>
                    <br />


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Basic Details
                            </Typography>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Full Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="name"
                                        value={this.state.name}
                                        onChange={this.onChange}
                                        required
                                        readOnly
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Gender <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="gender"
                                        required
                                        value={this.state.gender}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="MALE">MALE</option>
                                        <option value="FEMALE">FEMALE</option>
                                        <option value="OTHER">OTHER</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Marital Status <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="maratial_status"
                                        required
                                        value={this.state.maratial_status}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="Unmarried">Unmarried</option>
                                        <option value="Married">Married</option>
                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Religion <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="religion"
                                        required
                                        value={this.state.religion}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="HINDUISM">HINDUISM</option>
                                        <option value="ISLAM">ISLAM</option>
                                        <option value="CHRISTIANITY">CHRISTIANITY</option>
                                        <option value="SIKHISM">SIKHISM</option>
                                        <option value="BUDDHISM">BUDDHISM</option>
                                        <option value="JAINISM">JAINISM</option>
                                        <option value="OTHER">OTHER</option>

                                    </select>
                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Date of Birth <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="date"
                                        required

                                        name="date_of_birth"
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Nationality <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="nationality"
                                        value={this.state.nationality}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Domicile State <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="state"
                                        required
                                        value={this.state.state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>

                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Caste <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="caste"
                                        required
                                        value={this.state.caste}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="GENERAL">GENERAL</option>
                                        <option value="ST(H)">ST(H)</option>
                                        <option value="ST(P)">ST(P)</option>
                                        <option value="SC">SC</option>
                                        <option value="OBC/MOBC">OBC/MOBC</option>


                                    </select>
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Mobile No (WhatsApp No.) <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="phone"
                                        readOnly
                                        required
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                    />
                                </Grid>



                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Email <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="email"
                                        readOnly
                                        required
                                        value={this.state.email}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={6} md={6}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Co-Curricular Activity
                                    </Typography>

                                    <input
                                        className="form-control"
                                        type="text"

                                        name="extra_curr"
                                        value={this.state.extra_curr}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Do you want to avail sports/cultural/NCC C Certificate quota (Minimum District level)  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="sports_quota"
                                        required
                                        value={this.state.sports_quota}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="0">NO</option>
                                        <option value="1">YES</option>


                                    </select>

                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Person with Disability (PWD)  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="is_pwd"
                                        required
                                        value={this.state.is_pwd}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="0">NO</option>
                                        <option value="1">YES</option>


                                    </select>

                                </Grid>

                                {/* <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Econonomically Weaker Section (EWS)  ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="is_ews"
                                        required
                                        value={this.state.is_ews}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="0">NO</option>
                                        <option value="1">YES</option>


                                    </select>

                                </Grid> */}

                                {(this.state.gender === 'FEMALE') && (this.props.user.course == "BA" || this.props.user.course == "BSC") ? <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Do You Need Hostel Accomodation <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="has_hostel"
                                        required
                                        value={this.state.has_hostel}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select One</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>

                                    </select>

                                </Grid> : null}

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Blood Group
                                    </Typography>

                                    <input
                                        className="form-control"
                                        type="text"

                                        name="blood_group"
                                        value={this.state.blood_group}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Are you a BPL Candidate ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="is_bpl"
                                        required
                                        value={this.state.is_bpl}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>


                                    </select>
                                </Grid>
                            </Grid>

                        </CardContent>
                    </Card>
                    <br />
                   
                        
                    {!["HSA","HSS","HSC","DIPLOMA","PG"].includes(this.props.user.course) && <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                SAMARTH Portal Details
                            </Typography>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        SAMARTH Registration No <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="samarth_no"
                                        value={this.state.samarth_no}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                               
                                


                               
                               



                               

                            </Grid>



                        </CardContent>
                    </Card>}

                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Parent's Details
                            </Typography>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Father's Full Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="father_name"
                                        value={this.state.father_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Father's Contact No. <span style={{ fontWeight: 400, color: "red" }}>(Must not be Changed)</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="father_phone"
                                        value={this.state.father_phone}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Email
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="father_email"
                                        value={this.state.father_email}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Qualification
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="father_qualification"
                                        value={this.state.father_qualification}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Profession
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="father_profession"
                                        value={this.state.father_profession}
                                        onChange={this.onChange}
                                    />
                                </Grid>



                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Mother's Full Name <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="mother_name"
                                        value={this.state.mother_name}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No.
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="mother_phone"
                                        value={this.state.mother_phone}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Qualification
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="mother_qualification"
                                        value={this.state.mother_qualification}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Profession
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="mother_profession"
                                        value={this.state.mother_profession}
                                        onChange={this.onChange}
                                    />
                                </Grid>



                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Parent's Annual Income. <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="annual_income"
                                        value={this.state.annual_income}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                            </Grid>



                        </CardContent>
                    </Card>

                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Present Address
                            </Typography>


                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_village"
                                        value={this.state.pre_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Post Office<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_po"
                                        value={this.state.pre_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Police Station <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_ps"
                                        value={this.state.pre_ps}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        District<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_dist"
                                        value={this.state.pre_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State<span className="red-alert">*</span>
                                    </Typography>

                                    <select
                                        className="form-control"
                                        type="text"
                                        name="pre_state"
                                        required
                                        value={this.state.pre_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="pre_pin"
                                        value={this.state.pre_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="pre_contact"
                                        value={this.state.pre_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Permanent Address &nbsp;
                                <span>
                                    <Typography
                                        variant="caption"
                                    >
                                        <input type="checkbox" checked={this.state.add_ch} onClick={this.toggleAddCh} /> same as Present address
                                    </Typography>
                                </span>
                            </Typography>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_village"
                                        value={this.state.per_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Post Office<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_po"
                                        value={this.state.per_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Police Station <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_ps"
                                        value={this.state.per_ps}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        District<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        required
                                        name="per_dist"
                                        value={this.state.per_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State<span className="red-alert">*</span>
                                    </Typography>


                                    <select
                                        className="form-control"
                                        type="text"
                                        name="per_state"
                                        required
                                        value={this.state.per_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="per_pin"
                                        value={this.state.per_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"
                                        required
                                        name="per_contact"
                                        value={this.state.per_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Legislative Assembly Constituency (বিধানসভা সমষ্টি)
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="per_lac"
                                        value={this.state.per_lac}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Do you have PRC ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="has_prc"
                                        required
                                        value={this.state.has_prc}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="YES">YES</option>
                                        <option value="NO">NO</option>
                                        


                                    </select>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>

                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}

                            >
                                Local Guardian's Details
                            </Typography>


                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={4} md={4}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Full Name
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"


                                        name="local_g"
                                        value={this.state.local_g}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Village / Town
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="g_village"
                                        value={this.state.g_village}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Post Office
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="g_po"
                                        value={this.state.g_po}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Police Station
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="g_ps"
                                        value={this.state.g_ps}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        District
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"

                                        name="g_dist"
                                        value={this.state.g_dist}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        State
                                    </Typography>

                                    <select
                                        className="form-control"
                                        type="text"
                                        name="g_state"

                                        value={this.state.g_state}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        {allStates.map((el, index) =>
                                            <option key={index} value={el.name}>{el.name}</option>
                                        )}

                                    </select>
                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PIN
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="g_pin"
                                        value={this.state.g_pin}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Contact No
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="number"

                                        name="g_contact"
                                        value={this.state.g_contact}
                                        onChange={this.onChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    <br />

                    {/* <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>

                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: blue[700]
                                        }}

                                    >
                                        Activities  Enrollment (Select activities in which you are interested.)
                                    </Typography>

                                </Grid>

                                <FormGroup row>
                                    {activities.map((el) =>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.checkActivityValue(el.value)}
                                                    onChange={(e) => this.handleChange(e,el.label)}
                                                    name={el.value}
                                                    color="primary"
                                                />
                                            }
                                            label={el.label}
                                        />
                                    )}

                                </FormGroup>
                            </Grid>

                        </CardContent>

                    </Card> */}

                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>



                        <CardContent>


                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={12} lg={12} md={12}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: blue[700]
                                        }}

                                    >
                                        Bank Details
                                    </Typography>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Name of the Bank <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="account_bank"
                                        required

                                        value={this.state.account_bank}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Name of the Account Holder <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="account_holder"
                                        required

                                        value={this.state.account_holder}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={3} md={3}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Bank Branch <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="account_branch"

                                        required
                                        value={this.state.account_branch}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Bank Account Number <span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="account_no"
                                        required

                                        value={this.state.account_no}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={12} lg={4} md={4}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        Bank IFSC Code<span className="red-alert">*</span>
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="account_ifsc"

                                        required
                                        value={this.state.account_ifsc}
                                        onChange={this.onChange}
                                    />
                                </Grid>

                                {/* <Grid item xs={12} sm={12} lg={2} md={2}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        MICR Code
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="account_micr"


                                        value={this.state.account_micr}
                                        onChange={this.onChange}
                                    />
                                </Grid> */}

                                <Grid item xs={12} sm={12} lg={4} md={4}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        PAN Card Number of the Candidate
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="pan_number"


                                        value={this.state.pan_number}
                                        onChange={this.onChange}
                                    />
                                </Grid>


                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}

                                    >
                                        Do You have AADHAR Card ? <span className="red-alert">*</span>
                                    </Typography>
                                    <select
                                        className="form-control"
                                        type="text"
                                        name="has_aadhar_card"
                                        required
                                        value={this.state.has_aadhar_card}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select</option>
                                        <option value="NO">NO</option>
                                        <option value="YES">YES</option>


                                    </select>
                                </Grid>



                                {this.state.has_aadhar_card == "YES" && <Grid item xs={12} sm={12} lg={2} md={2}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ fontWeight: 700 }}
                                    >
                                        AADHAR Number of the Candidate
                                    </Typography>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="aadhar_number"
                                        required

                                        value={this.state.aadhar_number}
                                        onChange={this.onChange}
                                    />
                                </Grid>}

                                {this.state.has_aadhar_card == "NO" && <Grid item xs={12} sm={12} lg={4} md={4}>

                                    <Typography
                                        variant="subtitle2"
                                        style={{ color: 'red' }}
                                    >
                                        <Checkbox onChange={(e) => this.setState({
                                            aadhar_card_checked: e.target.checked
                                        })} /> I will submit my aadhar card details within 1 month after admission is confiremed
                                    </Typography>

                                </Grid>}


                            </Grid>

                        </CardContent>

                        <CardActions className={classes.cardActions}>

                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Proceed to next step
                            </Button>

                        </CardActions>



                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormOne)