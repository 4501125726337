import React, { Component } from 'react'
import { withStyles, Grid, Card, CardHeader, Typography, Avatar, CardContent, TextField, Button, CardActions, CircularProgress, Divider } from '@material-ui/core';
import Person from '@material-ui/icons/Person'
import Done from '@material-ui/icons/Done'
import { red, lightGreen, blue } from '@material-ui/core/colors';
import { GetData, PostData } from '../../../api/service';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from '../Forms/Loader';
import { Select, Input } from 'antd';
import allStates from '../../../helper/IndianStates.json'


const { Option } = Select;

const styles = theme => ({
    avatar: {
        backgroundColor: 'red'
    },

    otpButton: {
        height: theme.spacing.unit * 7,
        backgroundColor: lightGreen[500],
        color: 'white',
        '&:hover': {
            color: lightGreen[500],
            backgroundColor: 'white'
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    submitButton: {
        backgroundColor: 'red',
        color: 'white'
    },
    otpDiv: {
        height: theme.spacing.unit * 7,
        display: 'flex',
        alignItems: 'center',
        color: blue[300]
    },
    iconOtp: {
        fontSize: 15,
        marginRight: '10px'
    }
})

class UsernameRegistration extends Component {

    state = {
        course: '',
        role: 'NEWSTUDENT',
        name: '',
        f_name: '',
        m_name: '',
        l_name: '',
        state: '',
        father_name: '',
        email: '',
        phone: '',
        date_of_birth: '',
        original_otp: '',
        otp: '123',
        is_opt_sent: false,
        message: '',
        is_otp_resent: false,
        password: '',
        confirm_password: '',
        errors: [],
        isSubmitting: false,
        active_admission: []
    }

    componentDidMount() {
        GetData(`/getactiveadmission`).then((resp) => {
            this.setState({
                active_admission: resp
            })
        })
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            let n = '';
            let m = '';
            if (this.state.m_name !== '') {
                m = ` ${this.state.m_name}`;
            }
            n = `${this.state.f_name}${m} ${this.state.l_name}`;

            this.setState({
                name: n
            })
        })
    }

    onSubmit = (e) => {
        e.preventDefault();

        let errors = this.state.errors;


        if (this.state.name === '' || this.state.email === '' || this.state.phone === '' || this.state.otp === '' || this.state.password === '' || this.state.course === '' || this.state.state == "") {

            confirmAlert({
                title: 'Error',
                message: 'Please fill all the required fields.',
                buttons: [
                    {
                        label: 'Okay'
                    }
                ]
            });
        }


        else if (this.state.password === this.state.confirm_password) {

            this.setState({
                isSubmitting: true
            })

            let data = {
                course: this.state.course,
                role: this.state.role,
                name: this.state.name,
                email: this.state.email,
                phone: this.state.phone,
                father_name: this.state.father_name,
                otp: this.state.otp,
                date_of_birth: this.state.date_of_birth,
                password: this.state.password,
                first_name: this.state.f_name,
                middle_name: this.state.m_name,
                last_name: this.state.l_name,
                state: this.state.state,
            }

            PostData(`/admissiontemp/signup`, data)
                .then((resp) => {
                    this.setState({
                        isSubmitting: false
                    })
                    if (resp.status) {

                        let login_data = {
                            course: this.state.course,
                            email: this.state.email,
                            password: this.state.password
                        }

                        confirmAlert({
                            title: 'Successfully Registered',
                            message: 'You can now login and fill up the form',
                            buttons: [
                                {
                                    label: 'Okay',
                                    onClick: () => this.props.history.push('/', login_data)
                                }
                            ]
                        });
                    } else {

                        confirmAlert({
                            title: 'Failed',
                            message: resp.msg,
                            buttons: [
                                {
                                    label: 'Okay',
                                    onClick: () => this.setState({
                                        otp: '',
                                        is_opt_sent: false
                                    })
                                }
                            ]
                        });
                    }
                }).catch(() => {
                    this.setState({
                        isSubmitting: false
                    })
                })

        } else {

            errors['password'] = 'Passwords do not match';
            this.setState({
                errors
            })
        }




    }

    inputVerification = (type, value) => {
        if (type === 'phone') {
            let patt = /^([6-9][0-9]{9})$/;
            return patt.test(value);
        }

        if (type === 'email') {
            let patt = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return patt.test(value);
        }
    }

    sendOtp = () => {
        let phone = this.state.phone;
        let email = this.state.email;
        let errors = this.state.errors;

        let flag = 0;

        if (this.inputVerification('phone', phone)) {
            flag = flag + 1;
            errors['phone'] = '';
        } else {
            errors['phone'] = 'Phone number not valid'
        }


        if (this.inputVerification('email', email)) {
            flag = flag + 1;
            errors['email'] = '';
        } else {
            errors['email'] = 'Email not valid'
        }
        console.log(flag);
        if (flag === 2) {
            let data = {
                email: email,
                phone: phone
            }

            this.setState({
                isSubmitting: true
            })
            PostData(`/admissionotp`, data)
                .then((resp) => {
                    this.setState({
                        isSubmitting: false
                    })
                    this.setState({
                        is_opt_sent: resp.status,
                        message: resp.status,
                        otp: resp.otp
                    })
                }).catch((err) => {
                    this.setState({
                        isSubmitting: false
                    })
                })
        }


        this.setState({
            errors
        })




    }

    resend_otp = () => {
        this.setState({
            is_otp_resent: true
        }, () => {
            let d = {
                phone: this.state.phone,
                email: this.state.phone
            }

            PostData(`/resendotp`, d)
                .then((resp) => {
                    this.setState({
                        message: resp.status
                    })
                })
        })
    }

    render() {
        let { classes } = this.props;
        return (
            <div>
                {this.state.isSubmitting && <Loader />}
                <form onSubmit={this.onSubmit}>
                    <Card raised>

                        <CardHeader

                            title={
                                <Typography
                                    variant="h6"
                                    style={{ fontWeight: 700 }}
                                >
                                    Register as a New User
                                </Typography>
                            }
                            subheader={`For new students only. If you have already registered here then no need to register again. Use your user credentials to login into our system.`}

                        />
                        <Divider />

                        <CardContent>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={4} lg={4}>


                                    <label>
                                        <Typography variant='body2' gutterBottom>Program <span style={{ color: "red" }}>*</span></Typography>
                                    </label>
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Select Course"
                                        optionFilterProp="children"
                                        onChange={(value) => this.setState({
                                            course: !!value ? value : ''
                                        })}
                                        dropdownStyle={{
                                            zIndex: 1301
                                        }}
                                        value={this.state.course}
                                        name="course"
                                        style={{
                                            width: '100%'
                                        }}
                                        required
                                    >
                                        <Option value="" disabled>
                                            <em> Select </em>
                                        </Option>
                                        {this.state.active_admission.map((el, index) =>
                                            <Option key={index} value={el.course}>{el.label}</Option>
                                        )}

                                    </Select>

                                </Grid>

                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>First Name <span style={{ color: "red" }}>*</span></Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        placeholder="First Name"
                                        name="f_name"
                                        value={this.state.f_name}
                                        onChange={this.onChange}
                                    />

                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>Middle Name</Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        placeholder="Middle Name"
                                        name="m_name"
                                        value={this.state.m_name}
                                        onChange={this.onChange}
                                    />

                                </Grid>
                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>Last Name</Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        placeholder="Last Name"
                                        name="l_name"
                                        value={this.state.l_name}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} md={12} lg={6} sm={6}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>Your Email Id <span style={{ color: "red" }}>* (This email id should not be changed till the completion of your course.)</span></Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="email"
                                        placeholder="Your Email Id"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.onChange}

                                        disabled={this.state.is_opt_sent}
                                    />
                                    <div className="red-alert">{this.state.errors['email']}</div>

                                </Grid>

                                <Grid item xs={12} sm={12} lg={2} md={2}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>State <span style={{ color: "red" }}>*</span></Typography>
                                    </label>

                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Select State"
                                        optionFilterProp="children"
                                        onChange={(value) => this.setState({
                                            state: !!value ? value : ''
                                        })}
                                        dropdownStyle={{
                                            zIndex: 1301
                                        }}
                                        value={this.state.state}
                                        name="state"
                                        style={{
                                            width: '100%'
                                        }}
                                        required
                                    >
                                        <Option value="" disabled>
                                            <em> Select </em>
                                        </Option>
                                        {allStates.map((el, index) =>
                                            <Option key={index} value={el.name}>{el.name}</Option>
                                        )}

                                    </Select>


                                </Grid>

                                {/* <Grid item xs={12} md={12} lg={3} sm={3}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        type="date"
                                        label="Date of Birth"
                                        name="date_of_birth"
                                        value={this.state.date_of_birth}
                                        onChange={this.onChange}
                                        error={Boolean(this.state.errors['date_of_birth'])}
                                        disabled={this.state.is_opt_sent}
                                    />

                                </Grid> */}

                                <Grid item xs={12} md={8} lg={8} sm={12}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>Your Phone Number (WhatsApp number) <span style={{ color: "red" }}>* (This phone number should not be changed till the completion of your course.)</span></Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="number"
                                        placeholder="Your Phone Number"
                                        name="phone"
                                        value={this.state.phone}
                                        onChange={this.onChange}
                                        error={Boolean(this.state.errors['phone'])}
                                        helperText={`Valid 10 digit number`}
                                        disabled={this.state.is_opt_sent}
                                    />
                                    <div className="red-alert">{this.state.errors['phone']}</div>
                                </Grid>

                                <Grid item xs={12} md={4} lg={4} sm={12}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>Father's Name</Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        placeholder="Father's Name"
                                        name="father_name"
                                        value={this.state.father_name}
                                        onChange={this.onChange}
                                    />

                                </Grid>
                                {/* <Grid item xs={12} md={4} lg={4} sm={12}>

                                    {!this.state.is_opt_sent ? (
                                        <span>
                                            {this.state.isLoading ? (
                                                <div style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
                                                    <CircularProgress
                                                        size={24}
                                                    /> &nbsp; <Typography>Generating...Please wait</Typography>
                                                </div>
                                            ) : (<Button
                                                variant="contained"
                                                className={classes.otpButton}
                                                type="button"
                                                onClick={this.sendOtp}
                                            >
                                                Generate OTP
                                    </Button>)}

                                        </span>
                                    ) :'OTP Genearted'
                                        // <div className={classes.otpDiv}>
                                        //     <Typography
                                        //         color="inherit"
                                        //     >
                                        //         <Done className={classes.iconOtp} /> {this.state.message} {!this.state.is_otp_resent ? <Button onClick={this.resend_otp}>Resent OTP</Button> : null}
                                        //     </Typography>
                                        // </div>
                                    }

                                </Grid> */}

                                {/* <Grid item xs={12} md={8} lg={8} sm={12}>

                                    <TextField
                                        variant="outlined"
                                        className={classes.textField}
                                        required
                                        disabled="true"
                                        type="text"
                                        label="Enter OTP"
                                        name="otp"
                                        value={this.state.otp}
                                        onChange={this.onChange}
                                        helperText={`6 digit OTP`}
                                    />

                                </Grid> */}

                                <Grid item xs={12} md={6} lg={6} sm={12}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>Password <span style={{ color: "red" }}>*</span></Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="password"
                                        placeholder="New Password"
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.onChange}
                                    />

                                </Grid>

                                <Grid item xs={12} md={6} lg={6} sm={12}>
                                    <label>
                                        <Typography variant='body2' gutterBottom>Confirm Password <span style={{ color: "red" }}>*</span></Typography>
                                    </label>
                                    <Input
                                        variant="outlined"
                                        className={classes.textField}
                                        fullWidth
                                        required
                                        type="password"
                                        placeholder="Confirm Password"
                                        name="confirm_password"
                                        error={Boolean(this.state.errors['password'])}
                                        value={this.state.confirm_password}
                                        onChange={this.onChange}
                                        helperText={this.state.errors['password']}
                                    />
                                    <div className="red-alert">{this.state.errors['password']}</div>
                                </Grid>

                            </Grid>

                        </CardContent>

                        <Divider />

                        <CardActions className={classes.cardActions}>
                            <Button
                                variant="contained"
                                type="submit"
                                className={classes.submitButton}
                            >

                                Submit

                            </Button>

                        </CardActions>

                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(UsernameRegistration)
