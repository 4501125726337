import React, { Component } from 'react'
import { withStyles, Typography } from '@material-ui/core';
import AlertDiv from '../Registration/Forms/AlertDiv';
import { orange } from '@material-ui/core/colors';
import ReadmissionForm from './ReadmissionForm';

const styles = theme => ({

})

class ReAdmissionHome extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Typography
                    variant="h5"
                    gutterBottom
                >
                    Re-Admission Portal of B P Chaliha College
                </Typography>

                <AlertDiv
                    back={orange[100]}
                    border={orange[400]}
                >
                    <Typography
                        variant="body2"
                    >
                        Please read this instructions very carefully before continue
                    </Typography>

                    <ul>
                        <li>
                            <Typography>
                                You must be a student of this college to fill up this form
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Ensure that the information that you have given here are correct. Some informations cannot be changed afterwards
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Your form is not complete until you pay the admission fees to the cash counter at college.
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Choose the subjects that you have taken in the appropriate fields mentioned in the forms.
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                For any queries or issues, please contact our office for support
                            </Typography>
                        </li>
                    </ul>

                </AlertDiv>

                <br />

                <ReadmissionForm
                    history={this.props.history}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ReAdmissionHome)