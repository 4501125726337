import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import RegistrationLanding from '../Components/Registration/RegistrationLanding';


class RegistrationContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
        />
        <div className="main-body">

          <RegistrationLanding
                    history={this.props.history}
          />
        </div>
      </div>
    )
  }
}

export default RegistrationContainer;