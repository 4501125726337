import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button, Grid } from '@material-ui/core';
import ReactToPrint from 'react-to-print';

import { PostData } from '../../api/service';
import Loading from '../Registration/Forms/Loading';

const styles = theme => ({

})

class PrintReceiptByEmail extends Component {

    state = {
        data: [],
        isLoaded: false
    }

    componentRef = []

    componentDidMount() {
        PostData(`/getpaymentreceiptadmissionbyemail`, {
            email: this.props.email
        })
            .then((resp) => {
            
            
                
                if (Array.isArray(resp) && resp.length > 0) {
                    this.setState({
                        data: resp,
                        isLoaded: true
                    })
                }else{
                    window.location.href="/"
                }
            })
    }


    render() {
        let { classes } = this.props;
        return (
            <div style={{padding: 15}}>
                
                <h1>Payment Response</h1>





                {this.state.isLoaded ? (
                   <div>
                    {this.state.data.map((el,index) =>  <div key={index} style={{marginBottom: 20}}>
                        <ReactToPrint
                            trigger={() =>
                                <Button
                                    variant="outlined"
                                    color="primary"

                                >
                                    Print Receipt
                        </Button>
                            }
                            ref={el => this.buttonRef = el}
                            content={() => this.componentRef[index]}
                            pageStyle={{ margin: '10px' }}
                            copyStyles={true}
                        />
                        <br />
                        <br />
                        
                        <Receipt
                        {...el}
                        ref={el1 => (this.componentRef[index] = el1)}
                    /></div>)}
                    
                   </div>
                ) : <Loading />}


             


            </div>
        )
    }
}

export default withStyles(styles)(PrintReceiptByEmail);

class Receipt extends Component {


    getGoodSemester = (course, semester) => {
        if(["HSA","HSS","HSC"].includes(course)){
            if(semester == 1){
                return `1st Year`
            }
            if(semester == 2){
                return `2nd Year`
            }
        }else{
            if(semester == 1){
                return `1st Semester`
            }
            if(semester == 2){
                return `2nd Semester`
            }
            if(semester == 3){
                return `3rd Semester`
            }
            if(semester >= 4){
                return `${semester}th Semester`
            }
        }
    }

    showSubjects = (subjects) => {
        let i = [];
        if (Array.isArray(subjects) && subjects.length > 0) {
            i = subjects.map((el, index) =>
                el
            )
        }

        return i.join(',');
    }
    render() {
        let total_marks = 0;
        let marks_secured = 0;
        let {classes} = this.props;
        return (
            <div >
                <style type="text/css">
                    {"@media print{@page {size: landscape, max-height:100%; max-width:100%}}"}
                </style>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="20%">
                                <img
                                    alt="logo"
                                    src={`/icon512.png`}
                                    style={{ width: '50%' }}
                                />
                            </td>

                            <td width="60%" align="center">
                                <Typography
                                    variant="h5"
                                >
                                     Pragjyotish College
                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Santipur,Guwahati-781009

                                </Typography>
                                <Typography
                                    variant="body2"
                                >
                                    Assam (India)
                                </Typography>
                                <Typography
                                    variant="body1"
                                >
                                    {this.props.receipt_type}
                                </Typography>
                            </td>

                            <td width="20%" align="center">
                                <Typography
                                    variant="subtitle2"
                                >
                                    Receipt No. : <b>{this.props.type === 1 ? 'S' : null}{this.props.receipt_no}</b> <br />

                                    Date : <b>{this.props.date}</b>
                                </Typography>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <Divider />
                <br />



                {this.props.is_other == 0 || this.props.is_other == null ? (<Grid container spacing={2}>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    {/* <td width="30%">
                                        <Typography

                                        >
                                            Form No : <b>{this.props.form_id}</b>
                                        </Typography>
                                    </td> */}


                                    <td width="30%">
                                        <Typography
                                            variant=""
                                        >
                                            Class:  <b>{this.props.course} {this.props.stream} {this.props.course_type}</b>
                                        </Typography>
                                    </td>

                                    <td width="20%">
                                        <Typography
                                            variant=""
                                        >
                                            Semester/Year: 
                                            <b>
                                                {this.getGoodSemester(this.props.course, this.props.payment_semester)}
                                            </b>
                                        </Typography>
                                    </td>

                                    <td width="20%">
                                        {this.props.registration_no != null && <Typography
                                            variant=""
                                        >
                                            Samarth Roll No:  <b>{this.props.registration_no}</b>
                                        </Typography>}

                                        {this.props.roll_no != null && <>
                                        <br />
                                            <Typography
                                            variant=""
                                        >
                                            Class Roll No:  <b>{this.props.roll_no}</b>
                                        </Typography>
                                        </>}
                                    </td>

                                </tr>

                                <tr>
                                    <td width="30%">
                                        <Typography

                                        >
                                            Student's Name : <b>{this.props.name}</b>
                                        </Typography>
                                    </td>

                                    <td width="30%">
                                        <Typography

                                        >
                                            Father's Name : <b>{this.props.father_name}</b>
                                        </Typography>
                                    </td>

                                    <td width="70%" colSpan="2">
                                        <Typography
                                            variant=""
                                        >
                                            Subjects:  <b>{this.showSubjects(this.props.subjects)}</b>
                                        </Typography>
                                    </td>
                                </tr>



                            </tbody>
                        </table>

                    </Grid>



                </Grid>): null}



                {this.props.is_other == 1 ? (<Grid container spacing={2}>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                        <table width="100%">
                            <tbody>
                                

                                <tr>
                                    <td width="50%">
                                        <Typography

                                        >
                                            Name : <b>{this.props.name}</b>
                                        </Typography>
                                    </td>

                                    <td width="50%">
                                        <Typography
                                            variant=""
                                        >
                                            Remarks:  <b>{this.props.remarks}</b>
                                        </Typography>
                                    </td>
                                </tr>



                            </tbody>
                        </table>

                    </Grid>



                </Grid>) : null}

                <Divider />
                <br />



                <table width="100%" className="table">
                    <thead>
                        <tr>
                            <td width="10%">
                                <Typography>
                                    #
                                </Typography>
                            </td>

                            <td width="60%">
                                <Typography>
                                    Fees Head
                                </Typography>
                            </td>
                            <td width="30%" align="right">
                                <Typography>
                                    Fees Amount
                                </Typography>
                            </td>
                        </tr>
                    </thead>

                    {Array.isArray(this.props.fees) && this.props.fees.length > 0 ? (
                        <tbody>



                            {Array.isArray(this.props.fees) && this.props.fees.map((el, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td>
                                            <Typography

                                            >
                                                {el.sm_head}
                                            </Typography>
                                        </td>
                                        <td align="right">
                                            <Typography

                                            >
                                                {el.sm_amount}
                                            </Typography>
                                        </td>
                                    </tr>
                                )
                            })}

                            <tr>
                                <td colSpan="2" align="right">
                                    <Typography>
                                        Amount Paid
                                    </Typography>
                                </td>
                                <td align="right">
                                    <Typography

                                    >
                                        {this.props.amount_paid}
                                    </Typography>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="2" align="right">
                                    <Typography>
                                        Mode of Payment
                                    </Typography>
                                </td>
                                <td align="right">
                                    <Typography

                                    >
                                        {this.props.mop}
                                    </Typography>
                                </td>
                            </tr>
                        </tbody>

                    ) : null}

                </table>

            

                <br />

                <div align="right">

                    <Typography
                        variant="body2"
                    >
                        Collecting Officer
                    </Typography>

                </div>








            </div>
        )
    }

}