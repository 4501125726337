import React, { Component } from 'react'
import AlertDiv from './AlertDiv';
import { grey } from '@material-ui/core/colors';
import { Typography, CircularProgress } from '@material-ui/core';

export default class Loading extends Component {
  render() {
    return (
      <div>
            <AlertDiv
                back={grey[100]}
                border={grey[400]}
            >
                <Typography

                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <CircularProgress
                        size={15}
                    /> &nbsp;&nbsp;Loading . . . . Please Wait
                    </Typography>
            </AlertDiv>
      </div>
    )
  }
}
