import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import { withStyles, Card, CardHeader, Typography, CardContent, CardActions, Button } from '@material-ui/core';
import { blue, red } from '@material-ui/core/colors';

const styles = theme => ({
    cardHeaderDiv: {
        backgroundColor: '#1715DA',
        color: 'white'
    },
    button: {
        backgroundColor: red[500],
        color: 'white',
        border: `solid thin ${red[500]}`,
        '&:hover': {
            color: red[500],
            backgroundColor: 'white'
        }
    }
})

class InstructionsDiv extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Card>
                    <CardHeader
                        className={classes.cardHeaderDiv}
                        title={
                            <Typography
                                variant="h6"
                                color="inherit"
                            >
                                Applicant /Students' Sign Up
                            </Typography>
                        }

                    />

                    <CardContent>
                        <Typography
                            variant="body1"
                        >
                            Please Click on the Guidelines for Use before proceeding.

                            <br /><br />Before you can proceed to fill-up the admission form you need to Register yourself on the website. In order to Register you need to have your Mobile Phone with you for OTP (One time password) verification.

                            

                            <br /><br />Users already registered can log on to the system by providing their Registered Email Id (User-ID) and Password in the respective fields.

                            <br /><br />First time users can proceed to register themselves by clicking on the 'Register' button.
                    </Typography>
                    </CardContent>

                    <CardActions>
                        <Link to="/registration">
                            <Button
                                variant="contained"
                                size="small"
                                color="secondary"
                            >
                                New Applicant Registration
                        </Button>
                        </Link>

                        <Typography>
                            OR
                        </Typography>

                        <Link to="/login">
                            <Button
                                variant="contained"
                                size="small"
                                color="primary"
                            >
                                Login
                        </Button>
                        </Link>
                    </CardActions>

                </Card>

            </div>
        )
    }
}

export default withStyles(styles)(InstructionsDiv)