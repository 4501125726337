import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  withStyles,
  Typography,
  Divider,
  Button,
  Container,
} from "@material-ui/core";
import { blue, orange, grey, green, red } from "@material-ui/core/colors";
import ReactToPrint from "react-to-print";

import { GetData, PostData } from "../api/service";
import Loading from "../Components/Registration/Forms/Loading";
import queryString from "query-string";
import AlertDiv from "../Components/Registration/Forms/AlertDiv";
const styles = (theme) => ({});

class PaymentResponseCertificate extends Component {
  state = {
    data: [],
    isLoaded: false,
    status: "",
    message: "",
  };

  componentDidMount() {
    let search = this.props.history.location.search;

    let searchData = queryString.parse(search);

    if (searchData.status) {
      this.setState({
        status: searchData.status,
        message: searchData.message,
      });
    }

   
      PostData(`/getpaymentreceiptcertificate`, {
        certificate_request_id: searchData.certificate_request_id,
      }).then((resp) => {
        if (Array.isArray(resp) && resp.length > 0) {
          this.setState({
            data: resp[0],
            isLoaded: true,
          });
        } else {
          //this.props.history.replace('/home')
        }
      });
    
  }

  render() {
    let { classes } = this.props;
    return (
      <div>
        <Container>
          <br />
          <br />
          <h1>Payment Response</h1>

          {this.state.status != "" && (
            <div>
              <AlertDiv back={orange[100]} border={orange[400]}>
                <Typography variant="body2">
                  {this.state.status} : {this.state.message}
                </Typography>
              </AlertDiv>
            </div>
          )}

          {this.state.isLoaded && (
            <Receipt
              {...this.state.data}
              ref={(el) => (this.componentRef = el)}
            />
          )}

          <br />
          <br />

          {this.state.isLoaded ? (
            <div>
              <ReactToPrint
                trigger={() => (
                  <Button variant="outlined" color="primary">
                    Print Receipt
                  </Button>
                )}
                ref={(el) => (this.buttonRef = el)}
                content={() => this.componentRef}
                pageStyle={{ margin: "10px" }}
                copyStyles={true}
              />
            </div>
          ) : null}
        </Container>
      </div>
    );
  }
}

export default withStyles(styles)(PaymentResponseCertificate);

class Receipt extends Component {
  goodSemester = (course, semester) => {
    if (course == "HSA" || course == "HSS" || course == "HSC") {
      if (semester == 1) {
        return "1st Year";
      }
      if (semester == 2) {
        return "2nd Year";
      }
    } else {
      if (semester == 1) {
        return "1st Semester";
      }
      if (semester == 2) {
        return "2nd Semester";
      }
      if (semester == 3) {
        return "3rd Semester";
      }
      if (semester == 4) {
        return "4th Semester";
      }
      if (semester == 5) {
        return "5th Semester";
      }
      if (semester == 6) {
        return "6th Semester";
      }
    }
  };

  render() {
    return (
      <div>
        <table width="100%">
          <tbody>
            <tr>
              <td width="20%">
                <img
                  alt="logo"
                  src={"/images/logo.png"}
                  style={{ width: "50%" }}
                />
              </td>

              <td width="60%" align="center">
                <Typography variant="h5">Pragjyotish College</Typography>
                <Typography variant="body2">
                  Santipur,Kamrup(M),Assam (India),Pin 781009
                </Typography>
                <Typography variant="body2">Assam</Typography>
                <Typography variant="body1">Payment Receipt</Typography>
              </td>

              <td width="20%" align="center"></td>
            </tr>
          </tbody>
        </table>

        <br />
        <br />

        <table className="table">
          <tbody>
            <tr>
              <td width="30%">
                <Typography variant="body2">Name</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>{this.props.name}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2" >Father's Name</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>
                  {this.props.father_name}
                </Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Class</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>
                  {this.props.course}{" "}
                  {this.goodSemester(this.props.course, this.props.semester)}
                </Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Certificate ID</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>{this.props.id}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Certificate Type</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>{this.props.type_name}</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Transaction Status</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>Success</Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Tracking ID</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>
                  {this.props.tracking_id}
                </Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Bank Ref No</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>
                  {this.props.bank_ref_no}
                </Typography>
              </td>
            </tr>

            {/* <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Bank Ref No.
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                                    {this.props.transaction != null && this.props.transaction.bank_ref_no}
                                    {this.props.transaction == null && this.props.transaction_id}
                                </Typography>
                            </td>
                        </tr> */}

            <tr>
              <td width="30%">
                <Typography variant="body2">Date of Payment</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>
                  {this.props.amount_paid_on}
                </Typography>
              </td>
            </tr>

            <tr>
              <td width="30%">
                <Typography variant="body2">Amount</Typography>
              </td>

              <td>
                <Typography variant="body2" style={{fontWeight: 500}}>
                  {this.props.amount_paid}
                </Typography>
              </td>
            </tr>

            {/* <tr>
                            <td width="30%">
                                <Typography
                                    variant="body2"
                                >
                                    Mode of Payment
                                </Typography>
                            </td>

                            <td>
                                <Typography
                                    variant="body2"
                                >
                       
                                    {this.props.mop}
                                </Typography>
                            </td>
                        </tr> */}
          </tbody>
        </table>
      </div>
    );
  }
}
