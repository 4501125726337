import React, { Component } from 'react'
import AlertDiv from '../Forms/AlertDiv'
import { green } from '@material-ui/core/colors'
import { GetData, PostData } from '../../../api/service'
import { Button, Table, TableHead, TableRow, TableCell, TableFooter, TableBody, Chip } from '@material-ui/core'
import PaymentReceipt from './PaymentReceipt'


export default class AdmissionPayment extends Component {

    state = {
        data: {},
        isLoaded: false,
        isAlreadyPaid: false
    }

    fetchReceipt = () => {
        GetData(`/${this.props.form_id}/fetchmyackreceipt`)
            .then((resp) => {
                console.log("AdmissionPayment -> fetchReceipt -> resp", resp)

                if (resp != false) {

                    this.loadScript();

                    this.setState({
                        data: resp,
                        isLoaded: true,
                        isAlreadyPaid: resp.is_paid == 0 ? false : true
                    })
                }
                else {
                    this.props.history.replace('/rehome')
                }

            }).catch((err) => {
                alert(`Error`)
            })
    }


    loadScript = () => {
        const script = document.createElement("script");

        script.src = "https://pgi.billdesk.com/payments-checkout-widget/src/app.bundle.js";
        script.async = true;

        document.body.appendChild(script);
    }

    componentDidMount() {
        this.fetchReceipt();

    }


    makePayment = () => {
        PostData(`/getadmissionpaymentstring`, {
            receipt_no: this.state.data.receipt_no,
            form_id: this.state.data.form_id,
            totalAmount: this.state.data.amount_paid,

        })
            .then((resp) => {
                window.bdPayment.initialize({
                    msg: resp.msg,
                    options: {
                        enableChildWindowPosting: true,
                        enablePaymentRetry: true,
                        retry_attempt_count: 2,
                    },
                    callbackUrl: resp.link
                });
            })
            .catch((err) => {
                alert(`Error`)
            })
    }


    render() {
        return (
            <div>
                {this.state.isLoaded &&

                    <React.Fragment>
                        {!this.state.isAlreadyPaid &&
                            <AlertDiv
                                border={green[300]}
                                back={green[50]}
                            >
                                Please make the payment of <b>Rs. {this.state.data.amount_paid}</b> by clicking on the PAY NOW button below
                        </AlertDiv>}

                        {!this.state.isAlreadyPaid && <Table size="small">
                            <TableHead>

                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Particulars</TableCell>
                                    <TableCell>Amount</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(this.state.data.fees) && this.state.data.fees.map((el, index) =>
                                    <TableRow key={index}>
                                        <TableCell>{index + 1}</TableCell>
                                        <TableCell>{el.sm_head}</TableCell>
                                        <TableCell>{el.sm_amount}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TableCell style={{ textAlign: 'right' }} colSpan={2}>
                                        <b>Total</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>{this.state.data.amount_paid}</b>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>}

                        <Chip
                            size="small"
                            style={{ borderRadius: 0 }}
                            color="secondary"
                            label={this.state.isAlreadyPaid ? `Already Paid` : `Un-paid`}
                        /> &nbsp;

{/* {this.state.isAlreadyPaid && <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => this.props.history.push(`/re-admission/ack`)}
                        >
                            Download Receipt
                        </Button>} */}



                        <br />
                        <br />

                        {this.state.isLoaded && !this.state.isAlreadyPaid && <Button
                            onClick={this.makePayment}
                            variant="contained"
                            color="primary"
                        >
                            Pay Now
                        </Button>}




                        {this.state.isLoaded && this.state.isAlreadyPaid && <div>
                            <PaymentReceipt
                                data={this.state.data}
                            /></div>}




                    </React.Fragment>}
            </div>
        )
    }
}
