import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withStyles, Typography, Divider, Button, Grid } from '@material-ui/core';
import { GetData, PostData } from '../../../api/service';
import ReactToPrint from 'react-to-print';
import Loading from '../Forms/Loading';

import queryString from 'query-string';
import AlertDiv from '../Forms/AlertDiv';
import { orange } from '@material-ui/core/colors';


const styles = theme => ({

})

class PaymentResponse extends Component {

    state = {
        data: [],
        is_complete: null,
        isLoaded: null,
        status: '',
        message: ''
    }

    componentDidMount() {

        let search = this.props.location.search;
        if (search != '') {
            let parsed = queryString.parse(search)
            this.setState({
                status: parsed.status,
                message: parsed.message
            })
        }


        let data = {
            email: this.props.user.email,
            course: this.props.user.course
        }
        PostData(`/getpaymentreceipt`, data)
            .then((resp) => {

                if (Array.isArray(resp) && resp.length > 0) {
                    let is_complete = null;
                    resp.map((el) => {
                        is_complete = el.is_complete
                    })

                    this.setState({
                        data: resp,
                        isLoaded: true,
                        is_complete
                    })
                }
                //    else {
                //     this.props.history.replace('/home')
                //    }

            })
    }


    render() {
        let { classes } = this.props;
        return (
            <div>
                <h1>Payment Response</h1>


                {this.state.status != '' && <AlertDiv
                    back={orange[100]}
                    border={orange[400]}
                >
                    <Typography>Payment Status: <strong>{this.state.status}</strong></Typography>
                    <Typography>Message: <strong>{this.state.message}</strong></Typography>
                   
                </AlertDiv>}




                <br />


                {this.state.isLoaded == true && (
                    <>

                        {Array.isArray(this.state.data) && this.state.data.map((el, index) => <div key={index} style={{ marginBottom: 10 }}>

                            <Receipt
                                {...el}

                            />
                        </div>)}


                        <br />

                        {this.state.is_complete == 1 && <Link to="/final">
                            <Button
                                variant="outlined"
                                color="primary"
                            >
                                Print Application Form
                            </Button>
                        </Link>}


                        {this.state.is_complete == 0 && <Link to="/home">
                            <Button
                                variant="outlined"
                                color="primary"
                            >
                                Fill up the form now
                            </Button>
                        </Link>}


                    </>)}
            </div>
        )
    }
}

export default withStyles(styles)(PaymentResponse);

class Receipt extends Component {


    goodSemester = (sem) => {
        if (sem == 1) {
            return "1st";
        } else if (sem == 2) {
            return "2nd";
        } else if (sem == 3) {
            return "3rd";
        } else {
            return `${sem}th`;
        }
    };

    render() {
        return (
            <div>
                <div style={{ textAlign: 'center' }}>
                    <ReactToPrint
                        trigger={() =>
                            <Button
                                variant="outlined"
                                color="primary"

                            >
                                Print Receipt
                            </Button>
                        }
                        ref={el => this.buttonRef = el}
                        content={() => this.componentRef}
                        pageStyle={{ margin: '10px' }}
                        copyStyles={true}

                    />&nbsp;


                </div>
                <br />

                <div style={{ backgroundColor: 'white' }} ref={el => (this.componentRef = el)}>

                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="20%">
                                    <img
                                        alt="logo"
                                        src={'/images/logo.png'}
                                        style={{ width: '50%' }}
                                    />
                                </td>

                                <td width="60%" align="center">
                                    <Typography
                                        variant="h5"
                                    >
                                        PRAGJYOTISH COLLEGE
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                    >
                                        Santipur,Kamrup(M),Assam (India),Pin 781009
                                    </Typography>

                                    <Typography
                                        variant="body1"
                                    >
                                        Payment Receipt, <b>{this.props.session_name}</b>
                                    </Typography>
                                </td>

                                <td width="20%" align="right">

                                    <Typography
                                        variant="subtitle2"
                                    >
                                        Receipt No. : <b>{this.props.type == 3 && 'P-'}{this.props.receipt_no}</b> <br />
                                        Date : <b>{this.props.date}</b>


                                    </Typography>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br />


                    <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography variant="body2">
                                Name: {this.props.name}
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography
                                variant="body2"
                            >
                                Class:  <b>{this.props.class_name ? this.props.class_name : `${this.props.course} ${this.goodSemester(this.props.semester)} ${["HSA","HSS","HSC"].includes(this.props.course) ? `Year` : `Semester`}`}</b>
                            </Typography>
                        </Grid>

                        {this.props.roll_no && <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography variant="body2">
                                Roll No: <b>{this.props.roll_no}</b>
                            </Typography>
                        </Grid>}

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography
                                variant="body2"
                            >
                                Form No:  <b>{this.props.form_id}</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Typography
                                variant="body2"
                            >
                                Bak Ref:  <b>{this.props.transaction_id ? this.props.transaction_id : this.props.transaction?.bank_ref_no}</b>
                            </Typography>
                        </Grid>

                        {/* <Grid item xs={6} sm={6} md={6} lg={6}>
                    <Typography
                        variant="body2"
                    >
                        Shift:  <strong>{this.props.shift}</strong>
                    </Typography>
                </Grid> */}




                    </Grid>

                    <br />



                    <table width="100%">
                        <tbody>
                            <tr>
                                <td width="80%">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <td>#</td>
                                                <td>Particulars</td>
                                                <td>Amount (Rs.)</td>
                                            </tr>
                                        </thead>

                                        <tbody>

                                            {Array.isArray(this.props.fees) && this.props.fees.map((el, index) =>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{el.sm_head}</td>
                                                    <td style={{ textAlign: 'right' }}>{el.sm_amount}</td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td colSpan={3}>&nbsp;</td>
                                            </tr>

                                            <tr>
                                                <td colSpan={3}>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>&nbsp;</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>&nbsp;</td>
                                            </tr>



                                            <tr>
                                                <td style={{ textAlign: 'right' }} colSpan={2}>
                                                    <b>Total</b>
                                                </td>
                                                <td style={{ textAlign: 'right' }}>{this.props.amount_paid}</td>
                                            </tr>
                                        </tbody>


                                    </table>
                                </td>

                            </tr>
                        </tbody>
                    </table>

                    <br />





                    <Typography variant="body2" style={{ textAlign: 'center' }}>
                        <strong>Note</strong> : Since this is a system generated Receipt, Signature is not required.
                    </Typography>



                </div>
            </div>
        )
    }

}