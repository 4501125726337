import React, { Component } from 'react';
import Notifications, { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography, IconButton, CircularProgress } from '@material-ui/core';
import { GetData, PostData } from '../../api/service';
import { Select} from 'antd';
import { confirmAlert } from 'react-confirm-alert';
const {Option} = Select;

const styles = theme => ({
  container: {
    display: 'flex',
    width: "100%",
    flexDirection: 'column',
    
    justifyContent: 'center',
    alignItems: 'flex-end',
    boxShadow: '2px 3px 10px rgba(0,0,0,0.5)'
  },
  loginDiv: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    padding: '20px',
    backgroundColor: 'white',
    borderRadius: 10
  },
  content: {
    position: 'relative'
  },
  button: {
    backgroundColor: '#2196F3'
  },
  inputText: {
    padding: '15px',
    paddingLeft: '25px',
    border: 'solid thin #383838',
    // borderTopLeftRadius: '30px',
    // borderTopRightRadius: '30px',
    // borderBottomLeftRadius: '30px',
    // borderBottomRightRadius: '30px',
    backgroundColor: '#F9F9F9',
    marginBottom: '20px',
    outline: 'none'
  },
  loginText: {
    fontSize: 25,
    fontWeight: 200
  }
});

class NewLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      course: "",
      username: "",
      password: "",
      type: "NEW_ADMISSION",
      isLoading: false,
      active_admission:[]
    };
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

  }

  componentDidMount() {

    GetData(`/getallowadmission`).then((resp)=>{
      this.setState({
          active_admission:resp
      })
  })

    let data = this.props.history.location.state;
    if (data !== undefined && data != null && data.course !==undefined && data !==null && data.email && data.password) {
      this.setState({
        course: data.course,
        username: data.email,
        password: data.password
      })
    }
  }

  componentWillMount() {

  }
  onSubmit(e) {
    e.preventDefault();

    if (this.state.username != '' && this.state.password != '' && this.state.type != '' && this.state.course !=='') {
      this.setState({
        isLoading: true
      })

      let d = {
        course: this.state.course,
        email: this.state.username,
        password: this.state.password
      }


      if (this.state.type == "NEW_ADMISSION" || this.state.type == "HS_ADMISSION") {
        PostData(`/admissiontemp/login`, d)
          .then((res) => {
            if (res.user.status !== 0) {
              if (res.hasOwnProperty('token')) {
                //console.log(res)
                this.setToken(res.token);
                this.setUser(res);
                this.setApikey(res.user.api_key);
                if (this.state.type == "HOSTEL_ADMISSION") {

                  this.props.history.replace('/hostel-admission')

                } else {
                  if (res.user.role === "STUDENT") {
                    this.props.history.replace('/home')
                  } else if (res.user.role === "NEWSTUDENT") {
                    this.props.history.replace('/home')
                  } else if (res.user.role === "RESTUDENT") {
                    this.props.history.replace('/rehome')
                  }
                }


              } else {
                throw "error";
              }
            } else {
              throw "notactive";
            }
          })
          .catch(err => {

            notify.show('Wrong Username or password', "error", 3000);
            this.setState({
              isLoading: false
            })


          });
      }


      if (this.state.type == "RE_ADMISSION") {
        PostData(`/generaluser/login`, d)
          .then((res) => {
            if (res.user.status !== 0) {
              if (res.hasOwnProperty('token')) {
                //console.log(res)
                this.setToken(res.token);
                this.setUser(res);
                this.setApikey(res.user.api_key);

                this.props.history.replace('/rehome')



              } else {
                throw "error";
              }
            } else {
              throw "notactive";
            }
          })
          .catch(err => {

            notify.show('Wrong Username or password', "error", 3000);
            this.setState({
              isLoading: false
            })


          });
      }


    }

    else {
      confirmAlert({
        title: 'Error',
        message: 'Please fill all the required fields.',
        buttons: [
            {
                label: 'Okay'
            }
        ]
    });
    }

  }

  setUser(user) {
    const val = JSON.stringify(user);
    localStorage.setItem('user', val);
  }
  setApikey(key) {
    const val = JSON.stringify(key);
    localStorage.setItem('apikey', val);
  }
  setToken(idToken) {
    // Saves user token to localStorage
    localStorage.setItem('id_token', idToken)
  }
  handleChange(e) {
    this.setState(
      {
        [e.target.name]: e.target.value
      }
    )
  }
  render() {
    const { classes } = this.props;
    return (
      <section className={classes.container} >
        <Notifications />

        <div>

          <section className={classes.loginDiv}>

            <div align="center">
              <Typography
                className={classes.loginText}
                gutterBottom
              >
                Existing User Login
              </Typography>
            </div>

            <br />

            <div>
              <form onSubmit={this.onSubmit}>
              <FormControl fullWidth required>
              <label>
                                <Typography variant='h6'>Course *</Typography>
                            </label>
                            <Select
                            showSearch
                            allowClear
                            placeholder="Select Course"
                            optionFilterProp="children"
                            onChange={(value)=>this.setState({
                                course:!!value?value:'',
                            })}
                            dropdownStyle={{
                                zIndex:1301
                            }}
                            value={this.state.course}
                            name="course"
                            style={{
                                width:'100%'
                            }}
                            virtual={false}
                            required
                            >
                                <Option value="" disabled>
                                <em> Select </em>
                                </Option>
                            {this.state.active_admission.map((el)=>
                            <Option value={el.course}>{el.label}</Option>
                            )}
                            
                            </Select>

                            </FormControl>

                {/* <FormControl fullWidth required>

                  <select
                    required
                    className={classes.inputText}
                    name="type"
                    value={this.state.type}
                    onChange={this.handleChange}

                  >
                    <option value="">Select Type</option>


                    <option value="NEW_ADMISSION">New Admission</option>
                    <option value="RE_ADMISSION">Re Admission</option>
                    <option value="HS_ADMISSION">HS 2nd Year Admission</option>
                  </select>

                </FormControl> */}
                <br />
                <br />

                <FormControl fullWidth required>

                  <input
                    required
                    className={classes.inputText}
                    name="username"
                    value={this.state.username}
                    onChange={this.handleChange}
                    placeholder="Enter your Registered Email Id"
                  />

                </FormControl>



                <FormControl fullWidth required>
                  <input
                    required
                    className={classes.inputText}
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="Enter your password here"
                  />
                </FormControl>

                <br />

                <div style={{ textAlign: 'right' }}>
                  <Link to="/forgotpassword">
                    <Typography
                      variant="caption"
                    >
                      Forgot password ?
                    </Typography>
                  </Link>
                </div>

                <br />

                <div align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="large"
                    className={classes.button}
                  >
                    {this.state.isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Login'}
                  </Button>
                </div>
              </form>
            </div>
            <br />
            <br />

          </section>

        </div>
      </section>
    );
  }
}

export default withStyles(styles)(NewLogin);

export const IconLogin = (props) => {
  let { classes } = props;
  return (
    <div className={classes.iconlogin}>

    </div>
  )
}