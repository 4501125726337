import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import withAuth from '../auth_components/js/withAuth';
import LoggedNavBar from '../Components/LoggedNavBar';
import FormsLanding from '../Components/Registration/Forms/FormsLanding';
import ViewForm from '../Components/Registration/View/ViewForm';
import PaymentResponse from '../Components/Registration/View/PaymentResponse';
import AdmissionLanding from '../Components/Registration/Admission/AdmissionLanding';
import AckLanding from '../Components/Registration/Admission/AckLanding';
import NewPaymentPage from '../Components/Registration/Forms/NewPaymentPage';
import PaymentResponseAdmission from '../Components/Registration/View/PaymentResponseAdmission';


class HomePageUser extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){


      if(this.props.user.user.role == 'STUDENT'){
        this.props.history.replace(`/home`)
      }

  }

  render() {
    //console.log(this.props);
    return (
      <div>
        <LoggedNavBar
          history={this.props.history}
          type={1}
        />
        <div className="main-body">

          <Switch>
            <Route
              exact
              path="/home"
              render={(props) => <FormsLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/final"
              render={(props) => <ViewForm
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />

            <Route
              exact
              path="/admission"
              render={(props) => <AdmissionLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />


            <Route
              exact
              path="/admission-payment/:form_id"
              render={(props) => <NewPaymentPage
                {...props}
                history={this.props.history}
                user={this.props.user.user}
                form_id={props.match.params.form_id}
              />}
            />



            <Route
              exact
              path="/admission/ack"
              render={(props) => <AckLanding
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />
            
            <Route
              exact
              path="/paymentresponse"
              render={(props) => <PaymentResponse
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />


<Route
              exact
              path="/paymentresponse-admission"
              render={(props) => <PaymentResponseAdmission
                {...props}
                history={this.props.history}
                user={this.props.user.user}
              />}
            />
          </Switch>



        </div>
      </div>
    )
  }
}

export default withAuth(HomePageUser);