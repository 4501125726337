import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import { GetData, PostData } from '../api/service';
import { Grid, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import InstructionsDiv from '../Components/Homepage/InstructionsDiv';
import Login from '../auth_components/js/Login';
import NewLogin from '../Components/Homepage/NewLogin';
import BannerDiv from '../Components/BannerDiv';
import FooterDiv from '../Components/Homepage/FooterDiv';
import { lightBlue, blue } from '@material-ui/core/colors';
import ShowMdFiles from '../Components/Essentials/ShowMdFiles';
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PrintReceipt from '../Components/PrintReceipt/PrintReceipt';

class HomePage extends Component {
  constructor(props) {
    super(props);
  }



  render() {
    return (
      <div>
        <Navbar
          history={this.props.history}
        />
        {/* <BannerDiv /> */}

{/*        
        <div className="main-body">
          <div style={{
            color: 'blue',
            textAlign: 'center',
            marginBottom: '10px'
          }}>

<a style={{ fontSize: 14, backgroundColor: blue[600], padding: '3px', color: 'white' }} target="__blank" href={`/pdfs/Provisinal_Roll_No.pdf`}>Provisional Roll No</a> &nbsp;&nbsp;


            <a style={{ fontSize: 14, backgroundColor: blue[600], padding: '3px', color: 'white' }} href={`/filelist`}>Merit List for BA & BSC</a> &nbsp;&nbsp;
            
            
            
            <a style={{ fontSize: 14, backgroundColor: blue[600], padding: '3px', color: 'white' }} target="__blank" href={`/pdfs/Notice_for_Unique_ID.pdf`}>Notice Regarding Unique ID generation</a> &nbsp;&nbsp;
          </div>

          <marquee>
            <div style={{
              display: 'flex'
            }}>

<Typography style={{ color: 'red' }}>
                Re-admission for the session 2020-21 is going on
                
            </Typography>

            &nbsp;
            &nbsp;

         

              <Typography style={{ color: 'red' }}>
                All applicants must generate Unique ID from DHE Portal. visit <a target="__blank" href={`https://directorateofhighereducation.assam.gov.in/`}>https://directorateofhighereducation.assam.gov.in/</a>
              </Typography>



            
            </div>
          </marquee>

          <ExpansionPanel style={{
            backgroundColor: blue[600],
            color: 'white',
            cursor: 'pointer'
          }}>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography color="inherit" variant="h6">How to Accept an Admission Request (for new students only) </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography color="inherit">
                Follow these steps for accepting Admission Request.
          </Typography><br />
              <ul>
                <li>Login to this Admission Panel with your registered Email ID and password. If you have forgotten the password use forgot password link to reset the password and login.</li>
                <li>After successful login, you will see the status of your form in the top of the page along with your final printable form.</li>
                <li>If your name is in the published merit list of the college, you will see a Successfully Verified message, along with a Admission Panel button.</li>
                <li>Click on the Admission Panel button to view the request you have received.</li>
                <li>Once the college issue a request for admission, you will receive a SMS notifiying your offer for admission.</li>
                <li>If there is no request, It will be shown as "No request Found yet". If you receive the SMS, refresh the page to check whether the request has arrived or not.</li>
                <li>If a request is sent to you, you will see a Congratulations message along with the next guideline to accept the request.</li>
                <li>Select the request from the drop down menu, fill up whether your want to accept it or not and submit the request after agreeing to the undertaking at the bottom of the page</li>
                <li>Once you submit your request, you are not allowed to change it afterwards. There will be a cerification from the college authority and after which you will get your acknowledgement receipt in the same portal i.e. Admission Portal</li>
                <li>After the request is accepted from the college you can print out the Acknowledgement Receipt.</li>
                <li>For any queries, you can call the office. Phone number is at the bottom of the page.</li>
              </ul>
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <br />

          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} lg={12} md={12}>
              <Typography
                variant="h5"
                style={
                  {
                    fontWeight: 400
                  }
                }

              >
                Follow these guidelines before continue
              </Typography>

              <a target="__blank" style={{ color: 'blue' }} href={`/pdfs/njc_guardian_declaration.pdf`}>Click here</a> to download Guardian's Declaration Form

<br />
              <Typography
                variant="h5"
                style={
                  {
                    fontWeight: 400
                  }
                }

              >
                General Guidelines and Information
              </Typography>
              <Typography>
                  Read these instructions very carefully before continue. You can check any lastes modifications or notifications in the college website itself. We are requesting you to visit our website often.
                  &nbsp; <a target="_blank" style={{color: 'blue'}} href={`http://bhcollege.ac.in`}>http://bhcollege.ac.in</a>
              </Typography>

              <ShowMdFiles
                fileName={`gettingStarted`}
              />

            </Grid>

            <Grid item xs={12} sm={12} lg={6} md={6} style={{ textAlign: 'center' }}>
              <div
                
              >
                <img
                  alt="official Notice"
                  src={require('./../assets/notice.png')}
                  style={{ width: '18%' }}
                />

                <Typography
                  variant='body2'
                >
                  Official Notice
                </Typography>
              </div>

            </Grid>

            <Grid item xs={12} sm={12} lg={6} md={6} style={{ textAlign: 'center' }}>
              <div
                
              >
                <img
                  alt="General Guideline"
                  src={require('./../assets/guidelines.png')}
                  style={{ width: '20%' }}
                />

                <Typography
                  variant='body2'
                >
                  General Guidelines
                </Typography>
              </div>

            </Grid> 


             <Grid item xs={12} sm={12} lg={6} md={6}>

              <InstructionsDiv />

            </Grid>

            <Grid item xs={12} sm={12} lg={6} md={6}>

              

            </Grid>
          </Grid>



        </div> */}

        <div
          style={{
            
            minHeight: '300px',
            backgroundImage: '/images/bread-new-1.png'
          }}
        >
          <div className="main-bg">
          <div className="cont">
            
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={12} lg={6} md={6}>
              <Typography
                  variant="h4"
                  style={{ color: "white", fontWeight: 500 }}
                  gutterBottom
                >
                  Registration for New Students
              </Typography>

                <Typography
                  variant="body1"
                  style={{ color: 'white' }}
                >
                  If you have already registered for this purpose, there is no need to register again. For students taking admission in 1st semester or 1st year must register before continue. Existing students can login to this portal and fill up the admission form.
              </Typography>

              <br />


              <Typography
                  variant="body1"
                  style={{ color: 'white' }}
                >
                  Read the following guidelines before continue.
              </Typography>
              <br />
              <Link to="/registration">
                  <Button color='primary' variant="contained"
                    // style={{
                    //   backgroundColor: 'white',
                    //   color: 'green',
                    //   borderRadius: 0
                    // }}
                  >
                    Fresh Students (New-Registration)
              </Button>
                </Link>

              <br />
              <br />
              <PrintReceipt />
              <br />
              <br />
              <br />
                {/* <Typography
                  variant="h4"
                  style={{ }}
                  gutterBottom
                >
                  Readmission Student Registration 
              </Typography>

                <Typography
                  variant="body1"
                  style={{ }}
                >
                  For new users it is must that you read all the above mentioned insturctions before continue. Keep all the necessary details, files, photographs in handy, so that you can
                  upload in necessary fields. You must remember your user credentials generated after the first signup for future references.
                  You can login using your username and password used during the previous admission. Note: the username and password is same as of the student's portal. You can use forgot password option to recover the password if required.
              </Typography>

                <br />

                <Link to="/reregistration">
                  <Button size="small" variant="contained"
                    style={{
                      backgroundColor: 'white',
                      color: 'green',
                      borderRadius: 0
                    }}
                  >
                    New User (Re-admission) HS 2nd Year
              </Button>
                </Link> */}
              </Grid>

              <Grid item xs={12} sm={12} lg={6} md={6}>


                <NewLogin
                  history={this.props.history}
                />
                <br />
                
              </Grid>
            </Grid>
            </div>
          </div>

        </div>

        <div className="cont1">
          <div style={{marginBottom: 10, textAlign: "center"}}>
            <a target="_blank" href={`/pdfs/prag_subject_guideline.pdf`} style={{fontSize: 16, fontWeight: 700}}><img src={`/images/PDF_file_icon.svg.png`} style={{width: 20}} /> &nbsp; Subject Guideline</a>

            &nbsp;&nbsp;
            &nbsp;&nbsp;
            <a target="_blank" href={`/pdfs/FYUGP-Science_v1.pdf`} style={{fontSize: 16, fontWeight: 700}}><img src={`/images/PDF_file_icon.svg.png`} style={{width: 20}} /> &nbsp; Subject Guideline (for Science Stream)</a>
          </div>
        <ShowMdFiles
                fileName={`gettingStarted`}
              />
        </div>

       
        {/* <div className="main-body">

          <Grid container spacing={24}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <img
                src={`/images/admission_notice_re.png`}
                style={{ width: '100%' }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <ShowMdFiles
                fileName={`contactUs`}
              />
            </Grid>
          </Grid>
        </div> */}

        {/* <div align="center" style={{backgroundColor: lightBlue[50], padding: '10vw'}}>
            <Typography
              variant="h4"
            >
              For Re-admission
            </Typography>
            <Typography
              variant="subheading"
            >
              Please follow the link below
            </Typography>


          <br />
            <br />

            <Button
              size="large"
              href="/reregistration"
              variant="outlined"
              color="primary"
            >
              Re-Admission 
            </Button>

          <br />
            <br />
        </div> */}

        <FooterDiv />
      </div>
    )
  }
}

export default HomePage;