import React, { Component } from 'react';
import Navbar from '../Components/Navbar';
import './css/homepage.css';
import Welcome from '../Components/Homepage/Welcome';
import { GetData, PostData } from '../api/service';
import { Grid, Typography, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core';
import InstructionsDiv from '../Components/Homepage/InstructionsDiv';
import Login from '../auth_components/js/Login';
import NewLogin from '../Components/Homepage/NewLogin';
import BannerDiv from '../Components/BannerDiv';
import FooterDiv from '../Components/Homepage/FooterDiv';
import { lightBlue, blue } from '@material-ui/core/colors';
import ShowMdFiles from '../Components/Essentials/ShowMdFiles';
import { Link } from 'react-router-dom'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

class FileListPage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        files: [
            {
                name: "Principal's Notice",
                link: "/List/selection-list/admission_notice_1.pdf"
            },
            {
                name: "1 Selection List- English (Hons)",
                link: "/List/selection-list/1 Selection List- English (Hons).pdf"
            },
            {
                name: "2 Selection List- Assamese (Hons)",
                link: "/List/selection-list/2 Selection List- Assamese (Hons).pdf"
            },
            {
                name: "3 Selection List- Political Science (Hons)",
                link: "/List/selection-list/3 Selection List- Political Science (Hons).pdf"
            },
            {
                name: "4 Selection List- Economics (Hons)",
                link: "/List/selection-list/4 Selection List- Economics (Hons).pdf"
            },
            {
                name: "5 Selection List- Education (Hons)",
                link: "/List/selection-list/5 Selection List- Education (Hons).pdf"
            },
            {
                name: "6 Selection List- History (Hons)",
                link: "/List/selection-list/6 Selection List- History (Hons).pdf"
            },
            {
                name: "7 Selection List- Philosophy (Hons)s",
                link: "/List/selection-list/7 Selection List- Philosophy (Hons).pdf"
            },
            {
                name: "8 Selection List- Arabic (Hons)",
                link: "/List/selection-list/8 Selection List- Arabic (Hons).pdf"
            },
            {
                name: "9 Selection List- Physics (Hons)",
                link: "/List/selection-list/9 Selection List- Physics (Hons).pdf"
            },
            {
                name: "10 Selection List- Chemistry (Hons)",
                link: "/List/selection-list/10 Selection List- Chemistry (Hons).pdf"
            },
            {
                name: "11 Selection List- Zoology (Hons)",
                link: "/List/selection-list/11 Selection List- Zoology (Hons).pdf"
            },
            {
                name: "12 Selection List- Botany (Hons)",
                link: "/List/selection-list/12 Selection List- Botany (Hons).pdf"
            },
            {
                name: "13 Selection List- Mathematics (Hons)",
                link: "/List/selection-list/13 Selection List- Mathematics (Hons).pdf"
            },
            {
                name: "17 2nd Selection List- Physics (Hons)",
                link: "/List/selection-list/17 2nd Selection List- Physics (Hons).pdf"
            },
            {
                name: "18 2nd Selection List- Chemistry (Hons)",
                link: "/List/selection-list/18 2nd Selection List- Chemistry (Hons).pdf"
            },
            {
                name: "19 2nd Selection List- Botany (Hons)",
                link: "/List/selection-list/19 2nd Selection List- Botany (Hons).pdf"
            },
            {
                name: "20 2nd Selection List- Zoology (Hons)",
                link: "/List/selection-list/20 2nd Selection List- Zoology (Hons).pdf"
            },
            {
                name: "21 2nd Selection List- Mathematics (Hons)",
                link: "/List/selection-list/21 2nd Selection List- Mathematics (Hons).pdf"
            },
            {
                name: "22 2nd Selection List- English Hons)",
                link: "/List/selection-list/22 2nd Selection List- English Hons).pdf"
            },
            {
                name: "23 2nd Selection List- Assamese (Hons)",
                link: "/List/selection-list/23 2nd Selection List- Assamese (Hons).pdf"
            },
            {
                name: "24 2nd Selection List- Political Science (Hons)",
                link: "/List/selection-list/24 2nd Selection List- Political Science (Hons).pdf"
            },
            {
                name: "25 2nd Selection List- Economics (Hons)",
                link: "/List/selection-list/25 2nd Selection List- Economics (Hons).pdf"
            },
            {
                name: "26 2nd Selection List- Education (Hons)",
                link: "/List/selection-list/26 2nd Selection List- Education (Hons).pdf"
            },
            {
                name: "27 2nd Selection List- History (Hons)",
                link: "/List/selection-list/27 2nd Selection List- History (Hons).pdf"
            },
            {
                name: "28 2nd Selection List- Philosophy (Hons)",
                link: "/List/selection-list/28 2nd Selection List- Philosophy (Hons).pdf"
            },
           
            {
                name: "29 Waiting List- Assamese (Hons)",
                link: "/List/selection-list/29 Waiting List- Assamese (Hons).pdf"
            },
            {
                name: "30 Waiting List- Political Science (Hons)",
                link: "/List/selection-list/30 Waiting List- Political Science (Hons).pdf"
            },
            {
                name: "31 Waiting List- Education (Hons)",
                link: "/List/selection-list/31 Waiting List- Education (Hons).pdf"
            },
            {
                name: "32 Waiting List- Philosophy (Hons)",
                link: "/List/selection-list/32 Waiting List- Philosophy (Hons).pdf"
            },
            {
                name: "33 Waiting List- Arabic (Hons)",
                link: "/List/selection-list/33 Waiting List- Arabic (Hons).pdf"
            },
            {
                name: "34 Waiting List- Physics (Hons)",
                link: "/List/selection-list/34 Waiting List- Physics (Hons).pdf"
            },
            {
                name: "35 Waiting List- Chemistry(Hons)",
                link: "/List/selection-list/35 Waiting List- Chemistry(Hons).pdf"
            },
            {
                name: "36 Waiting List- Zoology (Hons)",
                link: "/List/selection-list/36 Waiting List- Zoology (Hons).pdf"
            },
            {
                name: "37 Waiting List- Botany (Hons)",
                link: "/List/selection-list/37 Waiting List- Botany (Hons).pdf"
            },
            {
                name: "38 Waiting List- Mathematics (Hons)",
                link: "/List/selection-list/38 Waiting List- Mathematics (Hons).pdf"
            },
            {
                name: "ADMISSION_NOTICE-DR_1",
                link: "/List/selection-list/ADMISSION_NOTICE-DR_1.pdf"
            },
            {
                name: "41 Selection List- BA 2020 (Regular)",
                link: "/List/selection-list/41 Selection List- BA 2020 (Regular).pdf"
            },
            {
                name: "42 Selection List- BSc 2020 (Regular)",
                link: "/List/selection-list/42 Selection List- BSc 2020 (Regular).pdf"
            },
            {
                name: "ADMISSION_NOTICE_HS_1st_Year__Arts__",
                link: "/List/selection-list/ADMISSION_NOTICE_HS_1st_Year__Arts__.pdf"
            },
            {
                name: "HS_1st_Year_Merit_List__Arts__05",
                link: "/List/selection-list/HS_1st_Year_Merit_List__Arts__05.pdf"
            }
        ]
    }

    componentDidMount() {

    }


    render() {
        return (
            <div>
                <Navbar
                    history={this.props.history}
                />
                <BannerDiv />





                <div className="main-body">

                    <Typography variant="h5" gutterBottom>
                        Important File List
          </Typography>

                    <table className="table table-sm table-border">
                        <thead>
                            <tr>
                                <td>#</td>
                                <td>File</td>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(this.state.files) && this.state.files.map((el, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    
                                    <td>  <a style={{color: 'blue', display: 'flex', alignItems: 'center'}} target="__blank" href={el.link}><PictureAsPdfIcon /> &nbsp; {el.name}</a></td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>



                <FooterDiv />
            </div>
        )
    }
}

export default FileListPage;