import React, { Component } from 'react'
import { withStyles, Card, CardHeader, Avatar, CardContent, Grid, Typography, CardActions, Button, MenuItem, FormControl, Select, OutlinedInput, InputLabel } from '@material-ui/core';
import { blue, lightGreen, orange } from '@material-ui/core/colors';
import FileUpload from './FileUpload';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import AlertDiv from './AlertDiv';

const styles = theme => ({
    buttonSubmit: {
        color: 'white',
        backgroundColor: blue[400],
        '&:hover': {
            color: blue[400],
            backgroundColor: 'white',
            border: `solid thin ${blue[400]}`
        }
    },
    cardActions: {
        justifyContent: 'flex-end'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: '#1715DA'
    },
    formControl: {
        width: '100%'
    }
})


class FormTwo extends Component {
    state = {
        step: 2,
        differently_abled: '',
        hostel_dues: '',
        sports_status: '',
        signature: [],

        guardians_signature: [],
        pwd_certificate: [],
        guardians_declaration: [],
        sports_certificate: [],
        dues_clearance: [],

        errors: [],

    }


    componentDidMount() {
        //console.log(this.props)
        if (this.props.edit) {
            let data = this.props.data;
            if (Array.isArray(data) && data.length > 0) {
                //set photo data
                this.setState({
                    hostel_dues: data[0].hostel_dues,
                    differently_abled: data[0].differently_abled,
                    sports_status: data[0].sports_status,
                    signature: data[0].signature,
                    guardians_signature: data[0].guardians_signature,
                    guardians_declaration: data[0].guardians_declaration,
                    sports_certificate: data[0].sports_certificate,
                    dues_clearance: data[0].dues_clearance,
                    pwd_cerificate: data[0].pwd_cerificate

                })
            }
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })


    }



    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleValidation = () => {
        let { signature, guardians_signature, guardians_declaration, sports_certificate, dues_clearance, errors, pwd_certificate, differently_abled, hostel_dues } = this.state;
        let isValid = true;

        if (Array.isArray(signature) && signature.length === 0) {
            isValid = false;
            errors['signature'] = 'Please fill up this field'
        } else {
            errors['signature'] = '';
        }


        if (Array.isArray(guardians_signature) && guardians_signature.length === 0) {
            isValid = false;
            errors['guardians_signature'] = 'Please fill up this field'
        } else {
            errors['guardians_signature'] = '';
        }



        if (differently_abled === 'YES') {
            if (Array.isArray(pwd_certificate) && pwd_certificate.length === 0) {
                isValid = false;
                errors['pwd_certificate'] = 'Please fill up this field'
            } else {
                errors['pwd_certificate'] = '';
            }
        }

        if (hostel_dues === 'YES') {
            if (Array.isArray(dues_clearance) && dues_clearance.length === 0) {
                isValid = false;
                errors['dues_clearance'] = 'Please fill up this field'
            } else {
                errors['dues_clearance'] = '';
            }
        }



        if (Array.isArray(guardians_declaration) && guardians_declaration.length === 0) {
            isValid = false;
            errors['guardians_declaration'] = 'Please fill up this field'
        } else {
            errors['guardians_declaration'] = '';
        }


        this.setState({
            errors
        })

        return isValid;

    }



    onSubmit = (e) => {
        e.preventDefault();

        if (this.handleValidation()) {
            let d = {
                email: this.props.email,
                step: this.state.step,
                signature: this.state.signature,

                guardians_signature: this.state.guardians_signature,
                sports_certificate: this.state.sports_certificate,
                guardians_declaration: this.state.guardians_declaration,
                dues_clearance: this.state.dues_clearance,

                pwd_certificate: this.state.pwd_certificate,
            }

            this.props.setData(d);

        }

    }


    render() {
        let { classes } = this.props;



        return (
            <div>
                <form onSubmit={this.onSubmit}>

                    <Typography
                        variant="h5"
                    >
                        Document Uploads
                    </Typography>
                    <Typography>
                        All <span className="red-alert">*</span> marked fields are mandatory
                    </Typography>

                    <br />

                    <AlertDiv
                        back={orange[100]}
                        border={orange[400]}
                    >
                        <strong>Instructions !!</strong>

                        <ul>
                            <li><Typography>Upload a clean, scanned copy of your document</Typography></li>
                            <li><Typography>Only .jpg or .png extensions are allowed</Typography></li>
                            <li><Typography>After Choosing the file, press "Upload" button.</Typography></li>
                            <li><Typography>After successful upload, the file will appear in the white box</Typography></li>
                        </ul>


                        <strong>Signature</strong>
                        <ul>
                            <li><Typography>Signature must be in a white paper with black ink.</Typography></li>
                            <li><Typography>Dimensions 100  x 60 pixels (preferred)</Typography></li>
                        </ul>



                    </AlertDiv>

                    <br />


                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}
                                gutterBottom
                            >
                                Personal Documents
                            </Typography>
                            <Grid container spacing={24}>



                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['signature'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['signature']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Your Signature (size below 100kb)  <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`HOSTEL_SIGNATURE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'signature')}
                                        data={this.state.signature}
                                        limit={200}
                                        width={100}
                                        height={60}
                                    />


                                </Grid>



                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['guardians_signature'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['guardians_signature']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Guardians Signature (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`HOSTEL_GUARDIANS_SIGNATURE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'guardians_signature')}
                                        data={this.state.guardians_signature}
                                        limit={500}

                                    />


                                </Grid>

                                {this.state.hostel_dues === 'YES' && <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['dues_clearance'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['dues_clearance']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload hostel dues clearance document (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`HOSTEL_DUES`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'dues_clearance')}
                                        data={this.state.dues_clearance}
                                        limit={500}

                                    />


                                </Grid>}


                                {this.state.differently_abled === 'YES' && <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['pwd_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['pwd_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload PWD Certificate (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`HOSTEL_PWD_CERTIFICATE`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'pwd_certificate')}
                                        data={this.state.pwd_certificate}
                                        limit={500}

                                    />
                                </Grid>}


                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['sports_certificate'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['sports_certificate']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Sports Certificate (size below 500kb) (check the guideline for more details)
                                            </Typography>
                                        }
                                        type={`HOSTEL_SPORTS`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'sports_certificate')}
                                        data={this.state.sports_certificate}
                                        limit={500}

                                    />
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                    <br />

                    <Card raised style={{ borderLeft: 'solid 5px #1715DA' }}>
                        <CardContent>
                            <Typography
                                variant="h5"
                                style={{
                                    color: blue[700]
                                }}
                                gutterBottom

                            >
                                Declaration Documents
                            </Typography>
                            <Grid container spacing={24}>
                                <Grid item xs={6} sm={6} lg={3} md={3}>
                                    {this.state.errors['guardians_declaration'] !== '' ? (

                                        <div>
                                            <Typography
                                                variant="caption"
                                                style={{ color: 'red' }}
                                            >
                                                {this.state.errors['guardians_declaration']}
                                            </Typography>
                                        </div>

                                    ) : null}

                                    <FileUpload
                                        title={
                                            <Typography
                                                variant="subtitle2"
                                                style={{ fontWeight: 700, fontSize: '12px' }}
                                            >
                                                Upload Guardian's Declaration (size below 500kb) <span className="red-alert">*</span>
                                            </Typography>
                                        }
                                        type={`HOSTEL_GUARDIANS_DECLARATION`}
                                        apikey={this.props.user.api_key}
                                        onChange={this.onChangePhoto.bind(this, 'guardians_declaration')}
                                        data={this.state.guardians_declaration}
                                        limit={500}

                                    />


                                </Grid>
                            </Grid>


                        </CardContent>

                        <CardActions className={classes.cardActions}>
                            <Button
                                type="button"
                                onClick={this.props.back}
                            >
                                Previous
                        </Button>
                            <Button
                                variant="contained"
                                className={classes.buttonSubmit}
                                type="submit"
                            >
                                Save &amp; Final Submit
                        </Button>

                        </CardActions>
                    </Card>
                </form>
            </div>
        )
    }
}

export default withStyles(styles)(FormTwo)