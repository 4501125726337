import React from 'react'
import { CircularProgress } from '@material-ui/core'

export default function Loader() {
    return (
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100vh',
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1005
            }}
        >
            <CircularProgress style={{color: 'white'}} />
        </div>
    )
}
