import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, CardHeader, Avatar, CardActions, Typography, CircularProgress, Grid } from '@material-ui/core';
import { blue, orange, grey,green, red } from '@material-ui/core/colors';
import PreviewForm from '../View/PreviewForm';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CallToAction from '@material-ui/icons/CallToAction'
import { GetData, PostData } from '../../../api/service';
import AlertDiv from './AlertDiv';
import Loading from './Loading';


const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '60vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: blue[400]
    }
})

class NewPaymentPageRe extends Component {

    state = {
        msg: '',
        link: '',
        amount: '',
        isLoaded: false,
        isPaymentLoaded: false,
        errors: [],
        payment_receipt: [],
        data:[],
        fees_group_id: '',
        is_free_admission: '',
        amount_paid: '',
        error: ''
    }


    onChange = (e) => {

        this.setState({
          [e.target.name]: e.target.value,
        })
      }

    getInitialData = () => {
        GetData(`/${this.props.user.email}/admissionform/getactivestudentsingle`)
            .then((resp) => {
            
                
                if (Array.isArray(resp) && resp.length > 0) {
                    
                    this.setState({
                        data: resp[0],
                    })
                } 

            })
    }

    componentDidMount() {
       
        // get the payment data now
        //console.log(this.props)
        GetData(`/${this.props.user.email}/admissionform/haspaymentdone`)
        .then((resp) => {
            console.log("🚀 ~ file: NewPaymentPageRe.js ~ line 74 ~ NewPaymentPageRe ~ .then ~ resp", resp)
            if(!resp){
                
                
                GetData(`/${this.props.user.email}/getadmissionpaymentdatare`)
                    .then((resp) => {
                        console.log(resp);
                        if (resp.hasOwnProperty('action') && resp.hasOwnProperty('encRequest') && resp.hasOwnProperty('access_code')) {
                            this.setState({
                                // msg: resp.msg,
                                // link: resp.link,
                                // amount: resp.amount,
                                error: "",
                                isLoaded: true,
                                action: resp.action,
                                encRequest: resp.encRequest,
                                access_code: resp.access_code,
                                amount: resp.amount,
                                amount_paid: resp.amount
                            },()=>{
                                this.getInitialData();
                            })
                        }


                        if(resp == false){
                            this.setState({
                                error: "Order already exists for this payment. Kindly contact the Office"
                            })
                        }


                    }).catch((err) => {
                        console.log(err)
                    })
            }else{
                this.props.history.replace('/repaymentresponse');
            }
        })


    }



    onChangePhoto = (name, value) => {
        this.setState({
            [name]: value
        })
    }

    handleValidation = () => {
        let { payment_receipt } = this.state;
        let isValid = true;

        let errors = [];

        if (Array.isArray(payment_receipt) && payment_receipt.length === 0) {
            isValid = false;
            errors['payment_receipt'] = 'Please fill up this field'
        } else {
            errors['payment_receipt'] = '';
        }


        this.setState({
            errors
        })

        return isValid;


    }


    onSubmit = () => {
        if (this.handleValidation()) {
            let d = {
                payment_receipt: this.state.payment_receipt,
                email: this.props.email,
            }


            confirmAlert({
                title: 'Final Submission',
                message: 'After clicking the Final Submission button, you cannot change your form details.',
                buttons: [
                    {
                        label: 'Final Submit',
                        onClick: () => this.finalSubmit(d)
                    },
                    {
                        label: 'Previous page'
                    }
                ]
            })
        }
    }


    finalSubmit = (d) => {
        PostData(`/admissionform/paymentdoing`, d)
            .then((resp) => {
                if (resp == true) {
                    alert(`Successfully Submitted`);
                } else {
                    alert(`Error in submitting`)
                }
                window.location.reload()
            }).catch((err) => {
                alert(`Error`)
            })
    }


    // paymentButtonClick = () => {
    //     GetData(`/${this.props.email}/admissionform/haspaymentdone`)
    //     .then((resp) => {
    //         if(!resp){
    //             GetData(`/${this.props.email}/getemaildata`)
    //                 .then((resp) => {
    //                     //console.log(resp);
    //                     if (resp.hasOwnProperty('action') && resp.hasOwnProperty('encRequest') && resp.hasOwnProperty('access_code')) {
    //                         this.setState({
    //                             // msg: resp.msg,
    //                             // link: resp.link,
    //                             // amount: resp.amount,
    //                             isLoaded: true,
    //                             action: resp.action,
    //                             encRequest: resp.encRequest,
    //                             access_code: resp.access_code,
    //                             amount: resp.amount
    //                         })
    //                     }

    //                 })
    //         }else{
    //             this.props.history.replace('/paymentresponse');
    //         }
    //     })
    // }



    render() {
        let { classes } = this.props;

        return (
            <div >

                {this.state.error != "" && <AlertDiv
                    border={red[300]}
                    back={red[50]}
                >
                {this.state.error}
                  
                </AlertDiv>}


                {this.state.isLoaded && <React.Fragment>
                <Card>
                <CardHeader

                        title={`Fees Details`}
                        subheader={`Declaration`}
                        />
                    <CardContent>
                    {/* {this.state.data.course === 'TDC' && this.state.data.course_type === 'HONOURS' && (this.state.data.stream === 'ARTS' || this.state.data.stream === 'SCIENCE') && <React.Fragment>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ color: 'red' }}
                                >
                                    Honours : {this.state.data.final_major.label}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ color: 'red' }}
                                >
                                    Generic : {this.state.data.final_generic.label}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    style={{ color: 'red' }}
                                >
                                    The above subjects are alloted to you as per your preferences. If you want to take admission with the above subjects then proceed further.
                                </Typography>
                                </React.Fragment>}
                                <br /> */}
                    <Grid container spacing={2}>
                  

                  

                  {this.state.is_free_admission !=1?(<Grid item xs={12} sm={12} lg={12} md={12}>
                    <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                      Amount to be paid <span className="red-alert">*</span>
                    </Typography>
                    <input
                      className="form-control"
                      type="text"
                      name="amount_paid"
                      required
                      value={this.state.amount_paid}
                      onChange={this.onChange}
                      readOnly
                    />
                  </Grid>):(<Grid item xs={12} sm={12} lg={12} md={12}>
                      <br />
                      <AlertDiv
                    border={green[300]}
                    back={green[50]}
                >
                 <strong>Please come to the college for free admission with necessary documents as per Re-schedule Date of Admission available in Pandu College website.</strong>
                  
                </AlertDiv></Grid>)}
                </Grid>
                    </CardContent>
                </Card>

                 {(this.state.is_free_admission ==0 || this.state.data.verify_free==1) && <Card>
                    <CardHeader

                        title={`Payment Page`}
                        subheader={`Click "PROCEED TO PAY" Button to continue`}
                    />

                     <CardContent>


                     <AlertDiv
                                back={orange[100]}
                                border={orange[400]}
                            >
                                <Typography
                                    variant="body2"
                                >
                                    You will now redirected to the payment page. Please note the following points:
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography>
                                            Follow the payment procedure very carefully
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            After a successful payment, an online fees payment receipt will be generated
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            You can print and save the Online application form after the payment
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography>
                                            Proceed the <b>"PROCEED TO PAY"</b> Button to continue
                                        </Typography>
                                    </li>
                                </ul>
                            </AlertDiv>

                           

                        {/* {this.state.isLoaded ? (<form method="post" action={this.state.link}>

                           


                            <input hidden name="msg" value={this.state.msg} />
                            <Button
                                variant="contained"
                                size="sm"
                                color="primary"
                                type="submit"
                            >
                                <CallToAction /> &nbsp; Proceed To Pay &#8377; {this.state.amount}
                        </Button>
                        </form>) : (
                                <Loading />
                        )} */}
                       

                        {this.state.isLoaded ? (<form method="post" action={this.state.action}>
                            <input type="hidden" name="encRequest" value={this.state.encRequest} />
                            <input type="hidden" name="access_code" value={this.state.access_code} />
                        <Button
                            variant="contained"
                            size="sm"
                            color="primary"
                            type="submit"
                        >
                            <CallToAction /> &nbsp; Proceed To Pay &#8377; {this.state.amount}
                        </Button>
                        </form>) : (
                            <Loading />
                        )}



                    </CardContent> 


                    <CardContent>

                        {/* <AlertDiv
                            back={orange[100]}
                            border={orange[400]}
                        >
                            <Typography
                                variant="body2"
                            >
                                You need to pay a registration fee of <b>Rs. 300</b>. Follow these steps to continue. Pay amount of Rs. 300 to the following account.
                                </Typography>
                            <br />

                            <strong>
                                A/C Holder: Pandu College Self Financing Course
                                    <br />
                                <br />
                                    A/C No. 2687101010645, IFSC: CNRB0002687
                                </strong>
                        </AlertDiv> 


                        <br />

                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={12} lg={3} md={3}>
                                {this.state.errors['payment_receipt'] !== '' ? (

                                    <div>
                                        <Typography
                                            variant="caption"
                                            style={{ color: 'red' }}
                                        >
                                            {this.state.errors['payment_receipt']}
                                        </Typography>
                                    </div>

                                ) : null}

                                <FileUpload
                                    title={
                                        <Typography
                                            variant="subtitle2"

                                        >
                                            Upload Payment Receipt after the payment of Rs. 300 (size below 500kb) <span style={{color: 'red'}}>*</span>
                                            </Typography>
                                    }
                                    type={`ADMISSION_PAYMENT_RECEIPT`}
                                    apikey={this.props.user.api_key}
                                    onChange={this.onChangePhoto.bind(this, 'payment_receipt')}
                                    data={this.state.payment_receipt}
                                    limit={500}
                                />


                            </Grid>


                            <Grid item xs={12} sm={12} lg={12} md={12} style={{ textAlign: 'right' }}>

                                <br />
                                <Button onClick={this.onSubmit} color="primary" variant="contained">Final Submit</Button>
                            </Grid>

                        </Grid>
                        */}

                    </CardContent>


                </Card>}
                </React.Fragment>}
            </div>
        )
    }
}

export default withStyles(styles)(NewPaymentPageRe)