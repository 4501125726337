import React, { Component } from 'react'
import { Typography, Grid, Button, FormControlLabel, Checkbox, CardContent, Card, CircularProgress } from '@material-ui/core'
import AlertDiv from '../Forms/AlertDiv'
import { green, blue } from '@material-ui/core/colors'
import { GetData, PostData } from '../../../api/service'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import FeesStructure from './FeesStructure'

export default class AdmissionLanding extends Component {

    state = {
        request_id: '',
        accept: '',
        reason: '',
        error: [],
        checked: false,
        selected_hostel: '',
        admission_type: '',
        isLoaded: false,
        hasRequest: '',
        requestsList: [],
        alreadySubmitted: false,
        submittedRequest: {},
        fs: [],
        course: '',
        stream: '',
        course_type: '',
        semester: '',
        receipt_no: ''
    }

    checkAdmissionRequest = () => {
        GetData(`/${this.props.user.apikey}/getmyhosteladmissionrequest`)
            .then((resp) => {


                // for no request
                if (resp.status === 'norequest') {
                    this.setState({
                        hasRequest: false,
                        isLoaded: true,
                        alreadySubmitted: false
                    })
                }

                // for no request
                if (resp.status === 'unpaidreceipt') {
                    // redirect to payment page
                    this.props.history.push(`/hostel-final-payment/${resp.receipt_no}`)
                }

                // for paid request
                if (resp.status === 'paidreceipt') {
                    // redirect to payment page
                    this.setState({
                        hasRequest: true,
                        isLoaded: true,
                        alreadySubmitted: true,
                        receipt_no: resp.receipt_no,
                        submittedRequest: resp.status_data,
                        selected_hostel: resp.status_data.selected_hostel,
                        admission_type: resp.status_data.admission_type,
                    })
                }

                // for active request
                if (resp.status === true) {
                    this.setState({
                        hasRequest: true,
                        isLoaded: true,
                        requestsList: resp.status_data,
                        selected_hostel: resp.status_data[0].selected_hostel,
                        admission_type: resp.status_data[0].admission_type,
                        course: resp.status_data[0].course,
                        stream: resp.status_data[0].stream,
                        course_type: resp.status_data[0].course_type,
                        semester: resp.status_data[0].semester,
                        roll_no: resp.status_data[0].roll_no,
                        fs: resp.fees_structure,
                        alreadySubmitted: false
                    })
                }


                // for accepted request
                if (resp.status === 'found') {

                    // show the receipt if not paid

                    this.setState({
                        hasRequest: true,
                        isLoaded: true,
                        submittedRequest: resp.status_data,
                        selected_hostel: resp.status_data.selected_hostel,
                        admission_type: resp.status_data.admission_type,
                        alreadySubmitted: true,

                    })
                }
            })
    }

    componentDidMount() {
        this.checkAdmissionRequest()
    }

    fetchData = () => {
        // 

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    submitRejection = () => {
        if (this.handleRejectValidation()) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.finalRejection()
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    }

    finalRejection = () => {
        PostData(`/${this.props.user.apikey}/submitmyrejectionhostel`, {
            request_id: this.state.request_id,
            accept: this.state.accept,
            reason: this.state.reason
        })
            .then((resp) => {
                if (resp == true) {
                    confirmAlert({
                        title: 'Successfully Rejected',
                        message: 'Your request has been successfully rejected.',
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => this.checkAdmissionRequest()
                            }
                        ]
                    });
                } else {
                    confirmAlert({
                        title: 'Error',
                        message: 'Something Went Wrong. Try again later',
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => this.checkAdmissionRequest()
                            }
                        ]
                    });
                }
            })
    }

    handleRejectValidation = () => {
        let isValid = true;
        let error = [];

        let { request_id, accept, reason } = this.state;

        if (request_id === '' || request_id === null) {
            isValid = false;
            error['request_id'] = 'This is compulsory field';
        } else {
            error['request_id'] = '';
        }


        if (accept === '' || accept === null) {
            isValid = false;
            error['accept'] = 'This is compulsory field';
        } else {
            error['accept'] = '';
        }


        if (reason === '' || reason === null) {
            isValid = false;
            error['reason'] = 'This is compulsory field';
        } else {
            error['reason'] = '';
        }


        this.setState({
            error
        })

        return isValid;

    }


    handleAcceptValidation = () => {
        let isValid = true;
        let error = [];

        let { request_id, accept, hostel, checked, unique_id } = this.state;

        if (request_id === '' || request_id === null) {
            isValid = false;
            error['request_id'] = 'This is compulsory field';
        } else {
            error['request_id'] = '';
        }


        if (accept === '' || accept === null) {
            isValid = false;
            error['accept'] = 'This is compulsory field';
        } else {
            error['accept'] = '';
        }

        if (unique_id === '' || unique_id === null) {
            isValid = false;
            error['unique_id'] = 'This is compulsory field';
        } else {
            error['unique_id'] = '';
        }


        if (hostel === '' || hostel === null) {
            isValid = false;
            error['hostel'] = 'This is compulsory field';
        } else {
            error['hostel'] = '';
        }


        if (checked === false || checked === null) {
            isValid = false;
            error['checked'] = 'You must agree to this undertaking';
        } else {
            error['checked'] = '';
        }

        console.log(error)


        this.setState({
            error
        })

        return isValid;

    }


    submitAcceptance = () => {
        if (this.handleAcceptValidation()) {
            confirmAlert({
                title: 'Confirm to submit',
                message: 'Are you sure to do this.',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => this.finalAcceptSubmit()
                    },
                    {
                        label: 'No'
                    }
                ]
            });
        }
    }


    finalAcceptSubmit = () => {
        PostData(`/${this.props.user.apikey}/submitmyhostelacceptance`, {
            request_id: this.state.request_id,
            accept: this.state.accept,
        })
            .then((resp) => {
                if (resp != false) {


                    if (resp['status'] == true) {
                        confirmAlert({
                            title: 'Successfully Accepted !! Proceed to payment',
                            message: 'You have successfully accepted the request. Now you must proceed for payment',
                            buttons: [
                                {
                                    label: 'Proceed to payment',
                                    onClick: () => this.props.history.push(`/hostel-final-payment/${resp['receipt_no']}`)
                                }
                            ]
                        });
                    } else {
                        confirmAlert({
                            title: 'Error',
                            message: resp['status_text'],
                            buttons: [
                                {
                                    label: 'Proceed to payment',
                                    onClick: () => this.props.history.push(`/hostel-final-payment/${resp['receipt_no']}`)
                                }
                            ]
                        });
                    }
                } else {
                    confirmAlert({
                        title: 'Error',
                        message: `Something went wrong`,
                        buttons: [
                            {
                                label: 'Okay',
                                onClick: () => window.location.reload()
                            }
                        ]
                    });
                }
            })
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    submitUniqueId = () => {
        if (this.state.new_unique_id !== '') {
            // submit now
            PostData(`/${this.props.user.apikey}/submituniqueid`, {
                unique_id: this.state.new_unique_id
            }).then((resp) => {
                if (resp != false) {
                    this.setState({
                        unique_id: resp
                    })
                }
            })

        }
    }

    render() {
        return (
            <div>



                {!this.state.isLoaded && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '200px'
                    }}
                >
                    <CircularProgress />
                    <br />
                    <Typography variant="caption">
                        Checking for Admission Requests, Please wait . . .
                    </Typography>
                </div>}



                {this.state.hasRequest === false && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '200px'
                    }}
                >
                    <Typography variant="h6">
                        No Request Found Yet !!
                    </Typography>
                    <Typography variant="caption">
                        Sorry !! There is no admission request for you now. Visit the site later to check again or "Refresh" now
                    </Typography>
                </div>}





                {this.state.isLoaded && this.state.hasRequest === true && this.state.alreadySubmitted == false && <div>
                    <Typography
                        variant="h5"
                        gutterBottom
                    >
                        Hostel Admission Panel
                    </Typography>


                    <AlertDiv
                        border={green[300]}
                        back={green[50]}
                    >
                        <b>Congratulations</b>, you got selected for <b>{this.state.admission_type.toUpperCase()}</b> admission in <b>{this.state.selected_hostel}</b> hostel. Now, you need to choose whether or not you want to accept this offer.
                        Follow the steps below in order to accept/reject the offer. For re-admission also students have to pay the admission amount thorugh this portal.
                        <ul>
                            <li>Select the offer you want to choose</li>
                            <li>Select whether you want to accept it or not</li>
                            <li>If you want to reject the offer select "No", you have to provide the reason for rejecting the offer and submit it.</li>
                            <li>If you accept the offer, you have to fillup or verify the informations shown. Correct the informations if required.</li>
                            <li>Click on the "Final Submit" button to send confirmation from your side to the college</li>
                            <li>Now you have to pay the admission fees amount for the hostels. Click on the "Pay Now" button to redirect to the payment gateway site.</li>
                            <li>College will verify all the information along with the payment status and provide you with an hostel admission receipt.</li>

                        </ul>





                        <Typography style={{ color: 'red' }}>College/Hostel Warden will have all the rights for accepting or rejecting your application.</Typography>
                    </AlertDiv>


                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: 700 }}
                            >
                                List of request received <span className="red-alert">*</span>
                            </Typography>
                            <div className="red-alert">{this.state.error['request_id']}</div>
                            <select
                                className="form-control"
                                type="text"
                                name="request_id"
                                required
                                value={this.state.request_id}
                                onChange={this.onChange}
                            >
                                <option value="">Select</option>
                                {Array.isArray(this.state.requestsList) && this.state.requestsList.map((el, index) =>
                                    <option key={index} value={el.request_id}>{el.request_type}</option>
                                )}

                            </select>
                        </Grid>

                        <Grid item xs={12} sm={12} md={3} lg={3}>
                            <Typography
                                variant="subtitle2"
                                style={{ fontWeight: 700 }}

                            >
                                Do you want to accept it ? <span className="red-alert">*</span>
                            </Typography>
                            <div className="red-alert">{this.state.error['accept']}</div>
                            <select
                                className="form-control"
                                type="text"
                                name="accept"
                                required
                                value={this.state.accept}
                                onChange={this.onChange}

                            >
                                <option value="">Select your answer</option>
                                <option value="YES">YES</option>
                                <option value="NO">NO</option>

                            </select>
                        </Grid>

                        {this.state.accept === 'NO' && <React.Fragment>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <Typography
                                    variant="subtitle2"
                                    style={{ fontWeight: 700 }}

                                >
                                    Reason for rejection <span className="red-alert">*</span>
                                </Typography>
                                <div className="red-alert">{this.state.error['reason']}</div>
                                <select
                                    className="form-control"
                                    type="text"
                                    name="reason"
                                    required
                                    value={this.state.reason}
                                    onChange={this.onChange}
                                >
                                    <option value="">Select your reason</option>
                                    <option value="I am not interested">I am not interested</option>
                                    <option value="I have already admitted in other college of my choice">I have already admitted in other college of my choice</option>
                                    <option value="Other">Other</option>
                                </select>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={this.submitRejection}
                                >
                                    Submit My Answer
                                </Button>
                            </Grid>
                        </React.Fragment>}





                    </Grid>


                    {this.state.accept === 'YES' && <React.Fragment>




                        <br />



                        <Grid container>
                            <Grid item xs={12} sm={12} lg={12} md={12}>
                                <FeesStructure
                                    data={this.state.fs}
                                />
                            </Grid>




                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <div className="red-alert">{this.state.error['checked']}</div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.checked}
                                            onChange={this.handleChange}
                                            name="checked"
                                            color="primary"
                                        />
                                    }
                                    label={<React.Fragment>
                                        <Typography variant="body2">
                                            <b>Declaration & Undertaking</b>: <br />
                    1. I declare that I have read all the Rules & Regulations of the Hostel and I shall abide by them and make payment as prescribed in the hostel prospectus.<br />
                    2. I further declare that, on admission to the hostel, I shall submit myself to the disciplinary jurisdiction of the in charge and other authorities who may be deputed by the in charge to exercise discipline under rules, procedure of the hostel.<br />
                    3. I shall not claim any refund of hostel fee paid deposited with the hostel, if I leave before the completion of the duration due to any reason whatsoever.<br />
                    4. I indemnify the hostel authorities against any casualties or risk to me.<br />
                    5. I agree that the Management’s decision in all matters concerning accommodation, discipline and conduct will be final and binding on me.<br />
                    6. I accept that if deemed fit as per rules the hostel authorities have the right to ask me to vacate the accommodation without assigning any reason.<br />
                    7. Before leaving the hostel I shall clear my dues in full and handover to the in charge the furniture and other items like mirror fittings intact.<br />
                    8. I shall not associate myself in any activity considered undesirable by the authorities. If I do so, my allotment of accommodation may be cancelled.
                </Typography>
                                    </React.Fragment>}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{ textAlign: 'right' }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={this.submitAcceptance}
                                >
                                    Final Submit
                                </Button>
                            </Grid>
                        </Grid>


                    </React.Fragment>}







                </div>}


                {/* Already Submitted */}

                {this.state.isLoaded && this.state.hasRequest === true && this.state.alreadySubmitted == true && <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        height: '300px'
                    }}
                >

                    <CheckCircleIcon
                        style={{
                            color: green[700],
                            fontSize: 60
                        }}
                    />
                    <Typography variant="h6">
                        Request Successfully Submitted !!
                    </Typography>

                    <Typography variant="caption" style={{ textAlign: 'center' }}>
                        {this.state.submittedRequest.is_admitted === 0 && `Waiting for verification`}
                        {this.state.submittedRequest.is_admitted === 1 && `Your Application has been accepted. Click the button below to download the Acknowledgement Receipt`}
                    </Typography>

                    <React.Fragment>
                        {this.state.submittedRequest.is_admitted === 1 && <Button
                            onClick={() => this.props.history.push(`/hostel-admission-ack`)}
                            variant="contained" style={{ backgroundColor: green[700], color: 'white' }}>
                            Download Acknowledgement Receipt
                                </Button>}

                                &nbsp;

                        {this.state.receipt_no != '' && <Button
                            onClick={() => this.props.history.push(`/hostel-final-payment/${this.state.receipt_no}`)}
                            variant="contained" style={{ backgroundColor: green[700], color: 'white' }}>
                            Download Payment Receipt
                                </Button>}
                    </React.Fragment>



                    <br />


                </div>}
            </div>
        )
    }
}

