import React, { Component } from 'react'
import { withStyles, Button, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'
import AlertDiv from './AlertDiv';
import { orange } from '@material-ui/core/colors';

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class LastMarks extends Component {
    state = {
        last_marks:[],
        over_all: 0,
        obtained: 0,
        percentage: 0,
    }

    __putNewData = (last_marks) => {

       if(Array.isArray(last_marks) && last_marks.length>0)
        this.setState({
            last_marks:last_marks,
           
        },()=>{

        this.calculate();

        })


    }

    componentDidMount() {
        this.__putNewData(this.props.last_marks);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.last_marks !== this.props.last_marks) {
            this.__putNewData(this.props.last_marks)
        }

    }



    onChange = (index, name, e) => {
        let { last_marks } = this.state;
        //console.log(index)


        if (name === 'subject') {
            last_marks[index][name] = e.target.value;
        }
        if (name === 'total_marks') {
            last_marks[index][name] = e.target.value;
        }

        if (name === 'marks_secured') {
            last_marks[index][name] = e.target.value;
        }

        this.setState({
            last_marks
        },()=>{
            this.calculate();
        })


    }

    onSubmit = () => {
        let d = {
            subject: this.state.subject,
            total_marks: this.state.total_marks,
            marks_secured: this.state.marks_secured,
        }

        let best_subjects = this.state.best_subjects;
        best_subjects.push(d);

        //calculate over all

        this.calculate(best_subjects);

        this.setState({
            best_subjects
        }, () => {
            this.props.onNewChange(best_subjects);
            this.props.sentTotalData({
                over_all: this.state.over_all,
                obtained: this.state.obtained,
                percentage: this.state.percentage
            })

            this.setState({
                subject: '',
                total_marks: '',
                marks_secured: '',
            })
        })
    }

    calculate = () => {

        let over_all = 0;
        let obtained = 0;
        let percentage = 0;

        this.state.last_marks.map((el, index) => {
            let tot = parseInt(el.total_marks) || 0;
            let obt = parseInt(el.marks_secured) || 0;
            if (tot !== 0 && obt !== 0) {
                over_all = over_all + tot;
                obtained = obtained + obt;
            }

        })

        //console.log(this.state.last_marks)

        //calculate percentage

        if (obtained !== 0) {
            percentage = obtained / over_all * 100;
        }

        let last_marks =  [...this.state.last_marks];

        let bs = last_marks.sort((a,b) => (parseInt(a.marks_secured) <= parseInt(b.marks_secured)) ? 1 : -1);
        //console.log(bs)
        let best_subjects = [];

        if(Array.isArray(bs) && bs.length > 0){

        for(let k=0;k<=3;k++){
            let index = bs.findIndex((el,idx)=>idx == k);

            if(index !=-1)
            best_subjects.push(bs[index]);
        }
    }

    //console.log(best_subjects)

        this.setState({
            
            over_all: over_all,
            obtained: obtained,
            percentage: percentage.toFixed(2)
        }, () => {
           
                this.props.sentTotalData({
                    last_marks:this.state.last_marks,
                    best_subjects,
                    over_all: this.state.over_all,
                    obtained: this.state.obtained,
                    percentage: this.state.percentage
                })
            
        })
    }

    onDelete = (index) => {
        let best_subjects = this.state.best_subjects;
        best_subjects.splice(index, 1);

        this.calculate(best_subjects);

        this.setState({
            best_subjects
        })
    }
    render() {
        let { classes } = this.props;
        

        let trs = [];

        for (let j = 0; j <= this.state.last_marks.length-1; j++) {
            trs.push(
                <tr key={j}>
                    <td>
                        <input
                            className="form-control"
                            type="text"
                            readOnly={this.state.last_marks[j]['readOnly']}
                            value={this.state.last_marks[j]['subject']}
                            onChange={this.onChange.bind(this,j,'subject')}
                            placeholder={`Subject ${j+1}`}
                        />

                    </td>
                    <td>
                        <input
                            className="form-control"
                            type="text"
                            
                            value={this.state.last_marks[j]['total_marks']}
                            onChange={this.onChange.bind(this,j,'total_marks')}
                            
                        />
                    </td>
                    <td>
                        <input
                            className="form-control"
                            type="text"
                           
                            value={this.state.last_marks[j]['marks_secured']}
                            onChange={this.onChange.bind(this,j,'marks_secured')}
                            
                        />
                    </td>

                </tr>
            )
        }
        return (
            <div className={classes.root}>
                 <AlertDiv back={orange[100]} border={orange[400]}>
                 

                  <ul>
                    <li>
                      <Typography>
                        You must exclude marks of any vocational including physical education.
                      </Typography>
                    </li>
                   
                  </ul>
                </AlertDiv>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td>Full Name of the Subject</td>
                            <td>Total Marks</td>
                            <td>Marks Secured</td>

                        </tr>
                    </thead>
                    <tbody>
                        {trs}
                    </tbody>
                   
                    <tbody>
                        <tr>
                            <td align="right"><strong>Total</strong></td>
                            <td><strong>{parseInt(this.state.over_all)}</strong></td>
                            <td><strong>{parseInt(this.state.obtained)}</strong></td>

                        </tr>
                        <tr>
                            <td colSpan="2" align="right"><strong>Percentage</strong></td>
                            <td><strong>{this.state.percentage} %</strong></td>
                        </tr>
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(LastMarks)