import React, { Component } from "react";
import {
  withStyles,
  MenuItem,
  FormControl,
  Select,
  OutlinedInput,
  InputLabel,
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Grid,
  Typography,
  Divider,
  CardActions,
  Button,
  Paper,
} from "@material-ui/core";
import PreviousExamination from "./PreviousExamination";
import BestOfThree from "./BestOfThree";
import SubjectsCombo from "./SubjectsCombo";
import { blue, lightGreen, orange, green } from "@material-ui/core/colors";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import AlertDiv from "./AlertDiv";
import { GetData } from "../../../api/service";
import Grade from "@material-ui/icons/Grade";
import Sel from "react-select";
import MultiSubject from "./MultiSubject";
import LastMarks from "./LastMarks";
import { Modal } from 'antd';

const styles = (theme) => ({
  buttonSubmit: {
    color: "white",
    backgroundColor: blue[400],
    "&:hover": {
      color: blue[400],
      backgroundColor: "white",
      border: `solid thin ${blue[400]}`,
    },
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  redStrip: {
    width: "100%",
    height: 8,
    backgroundColor: "#1715DA",
  },
});

class EducationalFormPG extends Component {
  state = {
    course: "PG",
    dept_code: "",
    semester: 1,
    email: this.props.user.email,
    exam_passed: [],
    total_marks: "",
    marks_obtained: "",
    arn_no: "",
    percentage: "",
    ranking_score: '',
    ranking_serial: '',
    errors: [],
    major_1: null,

    major_list: [],

    isLoaded: false,
    isDisabled: false,

    has_gap: "",
    gap_admission: "",
    gap_course: "",
    gap_institute: "",
    gap_reason: "",
    gap_roll_no: "",
    unique_id: "",
    subjects_choosen: [],

  };

  componentDidMount() {
    let data = this.props.data;

    if (Array.isArray(data) && data.length > 0) {
      this.setState(
        {
          ...data[0],

        },
        () => {

          let subjects_choosen = [];
          let major_1 = null;


          if (
            Array.isArray(data[0].subjects_choosen) &&
            data[0].subjects_choosen.length > 0
          ) {
            subjects_choosen = data[0].subjects_choosen;

            major_1 = subjects_choosen.find((el) => el.type === 'major_1')?.subject || null;

          }
          this.setState(
            {

              subjects_choosen,
              major_1
            },
            () => {
              this.__getData();

            }
          );

        }
      );
    }
  }
  __getData = () => {


    GetData(`/${this.state.course}/getadmissionsubject`).then((resp) => {


      this.setState(
        {
          major_list: resp,
          isLoaded: true,
        })



    });
  };


  onNewChange = (name, value) => {
    this.setState({
      [name]: value,
    });
  };


  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

  };



  onSubChange = (name, e) => {
    this.setState(
      {
        [name]: e,
      })
  };

  handleValidation = () => {
    let {
      dept_code,
      exam_passed,
      ranking_score,
      ranking_serial,
      arn_no,
      has_gap,
      gap_admission,
      gap_course,
      gap_institute,
      gap_reason,
      gap_roll_no,
      errors,
    } = this.state;
    let isValid = true;
    let subjects_choosen = [];

    let major_1 = this.state.major_list.find((el) => el.dept_code === dept_code);

    if (major_1 !== null && major_1 !== undefined) {
      if (Object.entries(major_1).length === 0) {
        isValid = false;

        errors["major_1"] = "Please Select Compulsory 1";
      } else {
        errors["major_1"] = "";

        subjects_choosen.push({
          type: 'major_1',
          subject: major_1
        });
      }
    } else {

      isValid = false;
      errors["major_1"] = "Please Select Compulsory 1";
    }

    if (Array.isArray(exam_passed) && exam_passed.length === 0) {
      isValid = false;
      errors["exam_passed"] = "Please fill exam passed.";
    } else {
      errors["exam_passed"] = "";
    }


    if (Array.isArray(subjects_choosen) && subjects_choosen.length === 0) {
      isValid = false;
      errors["subjects_choosen"] = "Please select subjects.";
    } else {
      errors["subjects_choosen"] = "";
    }

    if (dept_code === null || dept_code === '') {
      isValid = false;
      errors["dept_code"] = "Please select this field";
    } else {
      errors["dept_code"] = "";
    }

    if (ranking_score === null || ranking_score === '') {
      isValid = false;
      errors["ranking_score"] = "Please select this field";
    } else {
      errors["ranking_score"] = "";
    }


    if (arn_no === null || arn_no === '') {
      isValid = false;
      errors["arn_no"] = "Please select this field";
    } else {
      errors["arn_no"] = "";
    }

    if (ranking_serial === null || ranking_serial === '') {
      isValid = false;
      errors["ranking_serial"] = "Please select this field";
    } else {
      errors["ranking_serial"] = "";
    }

    if (has_gap === null || has_gap === '') {
      isValid = false;
      errors["has_gap"] = "Please select this field";
    } else {
      errors["has_gap"] = "";
    }

    if (has_gap === "YES") {
      if (gap_admission === null || gap_admission === '') {
        isValid = false;
        errors["gap_admission"] = "Please fill gap admission";
      } else {
        errors["gap_admission"] = "";
      }

      if (gap_admission === "YES") {

        if (gap_course === null || gap_course === '') {
          isValid = false;
          errors["gap_course"] = "Please fill gap course";
        } else {
          errors["gap_course"] = "";
        }

        if (gap_institute === null || gap_institute === '') {
          isValid = false;
          errors["gap_institute"] = "Please fill gap institute";
        } else {
          errors["gap_institute"] = "";
        }

        if (gap_reason === null || gap_reason === '') {
          isValid = false;
          errors["gap_reason"] = "Please fill gap reason";
        } else {
          errors["gap_reason"] = "";
        }

        if (gap_roll_no === null || gap_roll_no === '') {
          isValid = false;
          errors["gap_roll_no"] = "Please fill gap roll no";
        } else {
          errors["gap_roll_no"] = "";
        }

      }
      else {
        errors["gap_course"] = "";
        errors["gap_institute"] = "";
        errors["gap_reason"] = "";
        errors["gap_roll_no"] = "";
      }

    }
    else {
      errors["gap_admission"] = "";
      errors["gap_course"] = "";
      errors["gap_institute"] = "";
      errors["gap_reason"] = "";
      errors["gap_roll_no"] = "";
    }


    //console.log(errors);
    if (!isValid) {
      Modal.error({
        title: 'Error Message',
        content: <ul>
          {Object.values(errors)?.map((el, idx) => el !== '' && <li key={idx}>{el}</li>)}
        </ul>
      })
    }

    this.setState({
      errors,
      subjects_choosen
    });

    return { isValid, subjects_choosen };
  };

  onSubmit = (e) => {
    e.preventDefault();
    //console.log(this.state)
    let data = this.handleValidation();
    //console.log("🚀 ~ file: EducationalFormBA.js:625 ~ EducationalFormBA ~ data:", data)
    if (data.isValid) {
      let d = {
        email: this.state.email,
        course: this.state.course,
        dept_code: this.state.dept_code,
        ranking_score: this.state.ranking_score,
        ranking_serial: this.state.ranking_serial,
        arn_no: this.state.arn_no,
        subjects_choosen: data.subjects_choosen,
        total_marks: this.state.total_marks,
        marks_obtained: this.state.marks_obtained,
        percentage: this.state.percentage,
        exam_passed: this.state.exam_passed,
        has_gap: this.state.has_gap,
        gap_admission: this.state.gap_admission,
        gap_course: this.state.gap_course,
        gap_institute: this.state.gap_institute,
        gap_reason: this.state.gap_reason,
        gap_roll_no: this.state.gap_roll_no,
        step: 2
      };
      //console.log(d)

      this.props.setData(d);
    }
  };

  render() {
    let { classes } = this.props;


    return (
      <div>
        <form onSubmit={this.onSubmit}>
          <Typography variant="h5">Educational Details</Typography>
          <Typography>
            All <span className="red-alert">*</span> marked fields are mandatory
          </Typography>

          <br />

          {/* <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={6} md={6}>
                <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Unique ID (Generate from DHE Portal.Click the below link.){" "}
                    <a
                      style={{ color: "blue" }}
                      target="__blank"
                      href={`https://dheonlineadmission.amtron.in/sp/`}
                    >
                      https://dheonlineadmission.amtron.in/sp/
                    </a>{" "}
                    <span className="red-alert">*</span>
                  </Typography>

                  <input
                    className="form-control"
                    type="text"
                    required
                    name="unique_id"
                    value={this.state.unique_id}
                    onChange={this.onChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          <br /> */}

          <Card raised style={{ borderLeft: "solid 5px #1715DA" }}>
            <CardContent>

              <Grid container spacing={2}>

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Select Program <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="dept_code"
                    required
                    value={this.state.dept_code}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="MTM">MASTER OF TOURISM AND MANAGEMENT(M.T.M.)</option>
                    <option value="ASM">MASTER OF ARTS (M.A.) IN ASSAMESE</option>
                    <option value="ECO">MASTER OF ARTS/ SCIENCE (M.A./ M.SC.) IN ECONOMICS</option>
                    <option value="ZOO">MASTER OF SCIENCE (M.SC.) IN ZOOLOGY</option>
                    <option value="GEO">MASTER OF SCIENCE (M.SC.) IN GEOLOGY</option>
                    <option value="GGY">MASTER OF ARTS/ SCIENCE (M.A./ M.SC.) IN GEOGRAPHY</option>
                    <option value="EDN">MASTER OF ARTS (M.A.) IN EDUCATION</option>
                  </select>
                </Grid>

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography
                    variant="subtitle2"

                  >
                    Ranking Serial <span className="red-alert">*</span>
                  </Typography>
                  <input
                    step={0.01}
                    min={0}

                    className="form-control"
                    type="number"
                    required
                    name="ranking_serial"
                    value={this.state.ranking_serial}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography
                    variant="subtitle2"

                  >
                    Ranking Score <span className="red-alert">*</span>
                  </Typography>
                  <input
                    step={0.01}
                    className="form-control"
                    type="number"
                    required
                    name="ranking_score"
                    value={this.state.ranking_score}
                    onChange={this.onChange}
                    min={0}
                  />
                </Grid>


                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography
                    variant="subtitle2"

                  >
                    Application No. (GUPGET)  <span className="red-alert">*</span>
                  </Typography>
                  <input
                    step={0.01}
                    className="form-control"
                    type="text"
                    required
                    name="arn_no"
                    value={this.state.arn_no}
                    onChange={this.onChange}

                  />
                </Grid>


                <Grid item xs={12} sm={12} lg={12} md={12}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    U.G Course Details{" "}
                    <span className="red-alert">*</span>
                  </Typography>

                  {this.state.errors["exam_passed"] !== "" ? (
                    <div>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {this.state.errors["exam_passed"]}
                      </Typography>
                    </div>
                  ) : null}

                  <PreviousExamination
                    onNewChange={this.onNewChange.bind(this, "exam_passed")}
                    data={this.state.exam_passed}
                  />
                </Grid>


                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography
                    variant="subtitle2"

                  >
                    Total Marks (if any)
                  </Typography>
                  <input
                    className="form-control"
                    type="text"

                    name="total_marks"
                    value={this.state.total_marks}
                    onChange={this.onChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography
                    variant="subtitle2"

                  >
                    Obtained Marks (if any)
                  </Typography>
                  <input
                    className="form-control"
                    type="text"

                    name="marks_obtained"
                    value={this.state.marks_obtained}
                    onChange={this.onChange}
                  />
                </Grid>

                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography
                    variant="subtitle2"

                  >
                    Percentage (if any)
                  </Typography>
                  <input
                    className="form-control"
                    type="text"

                    name="percentage"
                    value={this.state.percentage}
                    onChange={this.onChange}
                  />
                </Grid>



                <Grid item xs={12} sm={12} lg={3} md={3}>
                  <Typography variant="subtitle2" style={{ fontWeight: 700 }}>
                    Do you have any gap ? <span className="red-alert">*</span>
                  </Typography>
                  <select
                    className="form-control"
                    type="text"
                    name="has_gap"
                    required
                    value={this.state.has_gap}
                    onChange={this.onChange}
                  >
                    <option value="">Select</option>
                    <option value="YES">YES</option>
                    <option value="NO">NO</option>
                  </select>
                </Grid>

                {this.state.has_gap === "YES" && (
                  <React.Fragment>
                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                      >
                        Have you taken admission in any institute/college ?{" "}
                        <span className="red-alert">*</span>
                      </Typography>
                      <select
                        className="form-control"
                        type="text"
                        name="gap_admission"
                        required
                        value={this.state.gap_admission}
                        onChange={this.onChange}
                      >
                        <option value="">Select</option>
                        <option value="YES">YES</option>
                        <option value="NO">NO</option>
                      </select>
                    </Grid>

                    {this.state.gap_admission === "YES" && (
                      <React.Fragment>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Name of the course{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_course"
                            value={this.state.gap_course}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Name of the Institute{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_institute"
                            value={this.state.gap_institute}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} lg={3} md={3}>
                          <Typography
                            variant="subtitle2"
                            style={{ fontWeight: 700 }}
                          >
                            Enrollment No/Registration No{" "}
                            <span className="red-alert">*</span>
                          </Typography>
                          <input
                            className="form-control"
                            type="text"
                            name="gap_roll_no"
                            value={this.state.gap_roll_no}
                            onChange={this.onChange}
                            required
                          />
                        </Grid>
                      </React.Fragment>
                    )}

                    <Grid item xs={12} sm={12} lg={3} md={3}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                      >
                        Reason of Gap <span className="red-alert">*</span>
                      </Typography>
                      <textarea
                        className="form-control"
                        type="text"
                        name="gap_reason"
                        value={this.state.gap_reason}
                        onChange={this.onChange}
                        required
                      ></textarea>
                    </Grid>

                    <Grid item xs={12} sm={12} lg={12} md={12}>
                      <AlertDiv back={orange[100]} border={orange[400]}>
                        <strong>Warning !!</strong>

                        <Typography>
                          In case, if any false information detected about the
                          gap period, the application/admission will be
                          rejected.
                        </Typography>
                      </AlertDiv>
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>




            </CardContent>

            <CardActions className={classes.cardActions}>
              <Button variant="contained" type="button" onClick={this.props.back}>
                Previous
              </Button>&nbsp;
              <Button
                variant="contained"
                className={classes.buttonSubmit}
                type="submit"
              >
                Save &amp; Proceed to next step
              </Button>
            </CardActions>
          </Card>
        </form>
      </div>
    );
  }
}

export default withStyles(styles)(EducationalFormPG);
