import React, { Component } from 'react'
import { withStyles, Grid, Typography } from '@material-ui/core';
import Sel from 'react-select'

const styles = theme => ({

})

class MultiSubject extends Component {
    state = {
        subjects: [],
        selected: []
    }

    componentDidMount() {
        this.setState({
            subjects: this.props.subjects
        }, () => {
            if (this.props.edit) {
                this.setState({
                    selected: this.props.data
                }, () => {
                    this.reloadSubjects(this.state.selected);
                })
            }
        })
    }

    componentDidUpdate(prevProps) {
        if (prevProps.subjects !== this.props.subjects) {
            this.setState({
                subjects: this.props.subjects
            }, () => {
                if (this.props.edit) {
                    this.setState({
                        selected: this.props.data
                    }, () => {
                        this.reloadSubjects(this.state.selected)
                    })
                }
            })
        }
    }

    onChange = (index, e) => {
        if (e !== null) {
            let selected = this.state.selected;
            selected[index] = e;

            // filter the choice now
            let r = [];


            let subjects = this.props.subjects;

            // subjects.map(el => {
            //     if (e !== null) {
            //         let r1 = selected.filter(el1 => el1.value === el.value);
            //         if (r1.length == 0) {
            //             r.push(el);
            //         }
            //     }else{
            //         r.push(this.state.selected[index])
            //     }

            // })
            //console.log("MultiSubject -> onChange -> r", r)

            // r = subjects.filter(el => {
            //     if (e !== null) {
            //         if (el.value !== e.value) {
            //             return el;
            //         }
            //     } else {
            //         return el;
            //     }

            // }
            // );

            this.setState({
                selected,
                //subjects: r
            }, () => {
                //console.log(this.state.selected)
                this.props.setData(this.state.selected)
                this.reloadSubjects(this.state.selected);
            })
        }else{
            // remove this entry
            let selected = this.state.selected;
            selected.splice(index,1);
            this.setState({
                selected,
                //subjects: r
            }, () => {
                //console.log(this.state.selected)
                this.props.setData(this.state.selected)
                this.reloadSubjects(this.state.selected);
            })
        }

    }

    reloadSubjects = (selected) => {
        let allSubjects = this.props.subjects;
        let r = [];

        // substract selected subjects from the list
        allSubjects.map(el => {
            if (selected !== null) {
                let r1 = selected.filter(el1 => el1.value === el.value);
                if (r1.length == 0) {
                    r.push(el);
                }
            }
        })

        this.setState({
            subjects: r
        })
    }


    render() {
        let { classes } = this.props;
        let r = [];
        for (let i = 0; i < this.props.nos; i++) {
            r.push(
                <Grid item xs={12} sm={12} lg={3} md={3} key={i}>
                    <Typography
                        variant="subtitle2"
                        style={{ fontWeight: 700 }}
                    >
                        Elective {i + 1} <span className="red-alert">*</span>
                    </Typography>
                    <Sel
                        options={this.state.subjects}
                        value={this.state.selected[i]}
                        isClearable={true}
                        isSearchable={true}
                        onChange={this.onChange.bind(this, i)}

                    />
                </Grid>
            )
        }
        return (
            <div>
                <Grid container spacing={16}>
                    {r}
                </Grid>
            </div>
        )
    }
}

export default withStyles(styles)(MultiSubject)