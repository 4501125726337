import React, { Component } from 'react'
import { withStyles, Button } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class PreviousExamination extends Component {
    state = {
        examination_passed: '',
        board: '',
        roll: '',
        year: '',
        institution: '',
        board_name: '',
        last_exam: []
    }

    componentDidMount() {
        this.setState({
            ...this.props.data[0]
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props !== prevProps) {
            this.setState({
                ...this.props.data[0]
            })
        }
    }

    onChange = (e) => {
        if (e.target.name === 'board') {
            if (e.target.value !== 'OTHERS') {
                this.setState({
                    [e.target.name]: e.target.value,
                    board_name: e.target.value
                })
            } else {
                this.setState({
                    board_name: ''
                })
            }
        }
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            let d = {
                examination_passed: this.state.examination_passed,
                board: this.state.board,
                board_name: this.state.board_name,
                roll: this.state.roll,
                year: this.state.year,
                institution: this.state.institution
            }

            this.props.onNewChange([d])

        })
    }

    onSubmit = () => {
        let d = {
            examination_passed: this.state.examination_passed,
            board: this.state.board,
            roll: this.state.roll,
            year: this.state.year,
            institution: this.state.institution
        }

        let last_exam = this.state.last_exam;
        last_exam.push(d);
        this.setState({
            last_exam
        }, () => {
            this.props.onNewChange(last_exam);
            this.setState({
                examination_passed: '',
                board: '',
                roll: '',
                year: '',
                institution: '',
            })
        })
    }

    onDelete = (index) => {
        let last_exam = this.state.last_exam;
        last_exam.splice(index, 1);
        this.setState({
            last_exam
        })
    }
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <table width="100%" className="table">

                    <thead>
                        <tr>
                            <td>Examination Passed</td>
                            <td>Board / Council / University</td>
                            <td>Roll &amp; No.</td>
                            <td>Year</td>
                            <td>Institution</td>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="examination_passed"
                                    value={this.state.examination_passed}
                                    onChange={this.onChange}
                                    placeholder="Examination Passed"
                                />
                            </td>
                            <td>
                                <select className="form-control"
                                    type="text"
                                    name="board"
                                    value={this.state.board}
                                    onChange={this.onChange}>
                                    <option value="">Select board</option>
                                    <option value="AHSEC">AHSEC</option>
                                    <option value="SEBA">SEBA</option>
                                    <option value="CBSE">CBSE</option>
                                    <option value="ICSE">ICSE</option>
                                    <option value="OTHERS">OTHERS</option>
                                    <option value="GAUHATI UNVERSITY">GAUHATI UNVERSITY</option>
                                    <option value="DIBRUGARH UNVERSITY">DIBRUGARH UNVERSITY</option>
                                    <option value="TEZPUR UNVERSITY">TEZPUR UNVERSITY</option>
                                </select>
                                {this.state.board === 'OTHERS' && <input
                                    className="form-control"
                                    type="text"
                                    name="board_name"
                                    value={this.state.board_name}
                                    onChange={this.onChange}
                                    placeholder="Name of the Board / University"
                                />}
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="roll"
                                    value={this.state.roll}
                                    onChange={this.onChange}
                                    placeholder="Roll & No"
                                />
                            </td>
                            <td>
                                <select className="form-control"
                                    type="text"
                                    name="year"
                                    value={this.state.year}
                                    onChange={this.onChange}>
                                    <option value="">Select Year of passing</option>
                                    {['2019', '2020', '2021', '2022', '2023','2024'].map((el, index) =>
                                        <option key={index} value={el}>{el}</option>
                                    )}


                                </select>
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="institution"
                                    value={this.state.institution}
                                    onChange={this.onChange}
                                    placeholder="Institution"
                                />
                            </td>
                            {/* <td>

                                <Button
                                    variant="outlined"
                                    onClick={this.onSubmit}
                                    type="button"
                                >
                                    +
                                </Button>

                            </td> */}
                        </tr>
                    </tbody>
                    <tbody>
                        {this.state.last_exam.map((el, index) =>

                            <tr key={index}>
                                <td>{el.examination_passed}</td>
                                <td>{el.board}</td>
                                <td>{el.roll}</td>
                                <td>{el.year}</td>
                                <td>{el.institution}</td>
                                <td><Delete onClick={this.onDelete.bind(this, index)} /></td>
                            </tr>

                        )}
                    </tbody>

                </table>
            </div>
        )
    }
}

export default withStyles(styles)(PreviousExamination)