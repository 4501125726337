import React, { Component } from 'react'
import { withStyles, Button, Card, CardContent, CardHeader, Avatar, CardActions, Typography, CircularProgress } from '@material-ui/core';
import { blue, orange, grey } from '@material-ui/core/colors';
import PreviewForm from '../View/PreviewForm';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import CallToAction from '@material-ui/icons/CallToAction'
import { GetData } from '../../../api/service';
import AlertDiv from './AlertDiv';
import Loading from './Loading';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '60vh',
        alignItems: 'center',
        justifyContent: 'center'
    },
    redStrip: {
        width: '100%',
        height: 8,
        backgroundColor: blue[400]
    }
})

class ProspectusFees extends Component {

    state = {
        msg: '',
        link: '',
        amount: '',
        isLoaded: false,
        isPaymentLoaded: false,
        errors: [],
        payment_receipt: [],
        data:[],
        fees_group_id: '',
        is_free_admission: '',
        amount_paid: '',
        error: ''
    }

    componentDidMount() {
        // get the payment data now

        
                GetData(`/${this.props.form_id}/getemaildata`)
                    .then((resp) => {
                        console.log(resp);
                        if (resp.hasOwnProperty('action') && resp.hasOwnProperty('encRequest') && resp.hasOwnProperty('access_code')) {
                            this.setState({
                                // msg: resp.msg,
                                // link: resp.link,
                                // amount: resp.amount,
                                error: "",
                                isLoaded: true,
                                action: resp.action,
                                encRequest: resp.encRequest,
                                access_code: resp.access_code,
                                amount: resp.amount,
                                amount_paid: resp.amount
                            })
                        }

                    }).catch((err) => {
                        console.log(err)
                    })
           
        

        
    }


    render() {
        let { classes } = this.props;

        return (
            <div >

                <Card>
                    <CardHeader

                        title={`Registration Fees`}
                        subheader={`Click "PROCEED TO PAY" Button to continue`}
                    />

                    <CardContent>

                        {this.state.isLoaded ? (<form method="post" action={this.state.action}>

                            <AlertDiv
                                back={orange[100]}
                                border={orange[400]}
                            >
                                <Typography
                                    variant="body2"
                                >
                                    You will now redirected to the payment page. Please note the following points:
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography>
                                            Follow the payment procedure very carefully
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            After a successful payment, an online fees payment receipt will be generated
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography>
                                            You can print and save the Online application form after the payment
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography>
                                            Choose "Debit Card" for 0 (zero) transaction charges.
                                        </Typography>
                                    </li>

                                    <li>
                                        <Typography>
                                            Proceed the <b>"PROCEED TO PAY"</b> Button to continue
                                        </Typography>
                                    </li>
                                </ul>
                            </AlertDiv>


                            <input type="hidden" name="encRequest" value={this.state.encRequest} />
                            <input type="hidden" name="access_code" value={this.state.access_code} />
                            <Button
                                variant="contained"
                                size="sm"
                                color="primary"
                                type="submit"
                            >
                                <CallToAction /> &nbsp; Proceed To Pay &#8377; {this.state.amount}
                        </Button>
                        </form>) : (
                                <Loading />
                        )}



                    </CardContent>


                </Card>
            </div>
        )
    }
}

export default withStyles(styles)(ProspectusFees)