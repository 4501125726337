import React, { Component } from 'react'
import { withStyles, Button, Typography } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete'

const styles = theme => ({
    root: {
        width: '100%',
        overflowX: 'scroll'
    }
})

class SubjectsCombo extends Component {
    state = {
        subject: '',
        total_marks: '',
        marks_secured: '',
        best_subjects: [],
        subjects: [],
        isSubjectLoaded: false
    }

    onChange = (e) => {

        let value = e.target.value;
        if (e.target.name === 'subject') {
            value = value.toUpperCase();
        }
        this.setState({
            [e.target.name]: value
        })
    }

    onSubmit = () => {
        let d = {
            subject: this.state.subject,
            total_marks: this.state.total_marks,
            marks_secured: this.state.marks_secured,
        }

        let best_subjects = this.state.best_subjects;
        best_subjects.push(d);
        this.setState({
            best_subjects
        }, () => {
            this.props.onNewChange(best_subjects);
            this.setState({
                subject: '',
                total_marks: '',
                marks_secured: '',
            })
        })
    }

    onDelete = (index) => {
        let best_subjects = this.state.best_subjects;
        best_subjects.splice(index, 1);
        this.setState({
            best_subjects
        })
    }
    render() {
        let { classes } = this.props;
        let i = [];
        if (this.state.isSubjectLoaded) {
            let subjects = this.state.subjects;
            if (Array.isArray(subjects) && subjects.length > 0) {
                i = subjects.map((el, index) =>

                    <option key={index} value={el}>{el}</option>

                )
            }
        }
        return (
            <div className={classes.root}>
                <table width="100%" className="table">


                    <tbody>
                        <tr>
                            <td>
                                <Typography>
                                    Major Subjects
                                </Typography>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="subject"
                                    value={this.state.subject}
                                    onChange={this.onChange}
                                    placeholder="Subject"
                                />
                            </td>
                            <td>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="total_marks"
                                    value={this.state.total_marks}
                                    onChange={this.onChange}
                                    placeholder="Total Marks"
                                />
                            </td>
                        </tr>
                    </tbody>


                </table>
            </div>
        )
    }
}

export default withStyles(styles)(SubjectsCombo)